import React from 'react'
import { styled, alpha } from '@mui/material/styles'

import SearchIcon from 'src/assets/icons/sort.png'

import SimpleDropdown from '../SimpleDropdown'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: '100%',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  right: 0,
  background: 'rgba(206, 206, 206, 1)',
  borderRadius: '0px 24px 24px 0px',
  zIndex: 999,
}))

const StyledInputBase = styled(SimpleDropdown)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    paddingRight: '50px',
  },
}))

type SortDropdownProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: any
  options: {
    value: any
    label: string
  }[]
}
const SortDropdown = ({ value, onChange, options }: SortDropdownProps) => {
  return (
    <Search>
      <SearchIconWrapper>
        <img src={SearchIcon} height={16} style={{ zIndex: 1 }} />
      </SearchIconWrapper>
      <StyledInputBase
        id={'sortBy'}
        name={'sortBy'}
        value={value}
        onChange={onChange}
        options={options}
        placeholder='Sort'
        hidePlaceholder
      />
    </Search>
  )
}

export default SortDropdown
