import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, Chip, Button, Divider } from '@mui/material'
import PdfPreview from 'src/Component/PdfPreview'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import calendarIcon from 'src/assets/icons/calendar.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFetchPostAttachmentSectionsQuery, useGetPostByIdQuery } from 'src/api/admin/postApi'
import RenderContent from 'src/Component/RichTextEditor/RenderContent'
import styles from './index.module.scss'
import dailyIcon from 'src/assets/icons/daily.png'
import manualIcon from 'src/assets/icons/manual.png'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import EditIcon from 'src/assets/icons/edit-icon.png'
import { GoogleAnalyticsData, routeUrls } from 'src/utils/constants'
import ImagePreview from 'src/Component/ImagePreview'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import SimpleModal from 'src/Component/SimpleModal'

const EditorViewPost = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()
  const [category, setCategory] = useState<string>('')
  const [showNews, setShowNews] = useState(false)
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()

  const { postId } = useParams<{ postId: string }>()
  const postIdNumber = postId ? parseInt(postId, 10) : 0

  const { data: post, isFetching } = useGetPostByIdQuery(postIdNumber, {
    refetchOnMountOrArgChange: true,
  })

  const { data: attachmentSectionResponse } = useFetchPostAttachmentSectionsQuery(postIdNumber, {
    refetchOnMountOrArgChange: true,
  })

  const isPublished = !!post?.post_to_landing_page
  const isDraft = !post?.post_to_landing_page

  const handleEditClick = () => {
    navigate(routeUrls.editor.editPost.replace(':postId', postIdNumber.toString()))
  }
  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('e.target.value', e.target.value)
    setCategory(e.target.value)
    console.log('cate', category)
  }
  const handleFileOpen = (url: string) => {
    window.open(url, '_blank')
  }

  const handleNews = () => {
    window.open(`/admin/news-page/${postId}`,'_blank')
    // setShowNews(!showNews)
    // GoogleAnalyticsData('visit news', 'Read News')
  }
  const handleClose = () => {
    setShowNews(false)
  }

  useEffect(() => {
    if (isFetching) dispatch(startPageLoader())
    else dispatch(stopPageLoader())
  }, [isFetching])

  if (!post) return null

  return (
    <Box>
      <Box className={styles.headerWrapper}>
        <Typography variant='h1' color='white'>
          Specific Post
        </Typography>
        <Grid container sx={{
          width: {
            xs: '100%',
            md: '60%'
          },
        }}>
          <Grid xs={12} sm={12} md={6} sx={{
            marginLeft :{
              xs: 1,
              md:3
            },
            marginTop:{
              xs: 1,
              md:0
            },
            marginBottom:{
              xs: 1,
              md:0
            },
          }}>
            <SimpleDropdown
              id={'category'}
              name={''}
              value={category || location?.state?.categorySelected}
              onChange={handleCategoryChange}
              options={METAL_CATEGORIES.map(({ id, category }) => ({
                label: category,
                value: id,
              }))}
              placeholder='Select Metal Category'
            />
          </Grid>
          <Grid xs={12} sm={12} md={5} pl={1}>
            <Button
              fullWidth
              type='button'
              variant='outlined'
              color='primary'
              onClick={() => {
                navigate(routeUrls.editor.home, {
                  state: {
                    categoryId: category,
                  },
                })
              }}
            >
              Apply filter
            </Button>
          </Grid>
        </Grid>
        <Button
          onClick={handleEditClick}
          variant='outlined'
          sx={{
            width: {
              xs:'100%',
              md: '200px'
            },
            marginLeft :{
              xs: 1,
              md:0
            },
            marginTop:{
              xs: 1,
              md:0
            },
            marginBottom:{
              xs: 1,
              md:0
            }
          }}
        >
          <img src={EditIcon} />
          Edit Post
        </Button>
      </Box>
      <Box className={styles.postWrapper} mt={3}>
        <Box display='flex' justifyContent='end' mb={2}>
          {isPublished && <Chip label='Published' color='primary' />}
          {isDraft && <Chip label='Draft' />}
          {isDraft && post && post.post_status && (
            <Chip
              label={(() => {
                if (post.post_status === 'pending') return 'Pending'
                else if (post.post_status === 'in_progress') return 'In Progress'
                else if (post.post_status === 'completed') return 'Completed'
              })()}
              color='warning'
              sx={{ marginLeft: 1 }}
            />
          )}
        </Box>
        <Grid
          container
          justifyContent='space-between'
          alignItems='baseline'
          className={styles.titleContainer}
        >
          <Grid item xs={9} className={styles.titleCard}>
            <Typography variant='h2' className={styles.titleFont}>{post?.title}</Typography>
            {!post?.is_manual ? (
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent='end'
                marginLeft={'35%'}
                marginRight={-33}
                marginTop={-5}
                marginBottom={5}
                className={styles.sourceContainer}
              >
                <Button onClick={handleNews}>View News From Source</Button>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            item
            xs={3}
            display='flex'
            alignItems='center'
            justifyContent='end'
            className={styles.dateContainer}
          >
            <img src={calendarIcon} /> &nbsp;
            <span> {dayjs(post?.created_at).format('MMM DD, YYYY')} </span>
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={1}>
          {post && post?.is_manual && (
            <Grid item>
              <Chip
                label={
                  <Grid display='flex' alignItems='center' justifyContent='center'>
                    <img src={manualIcon} /> <span> &nbsp; Thematic</span>
                  </Grid>
                }
                color='warning'
              />
            </Grid>
          )}

          {post && !post?.is_manual && (
            <Grid item>
              <Chip
                label={
                  <Grid display='flex' alignItems='center' justifyContent='center'>
                    <img src={dailyIcon} /> <span> &nbsp; Daily</span>
                  </Grid>
                }
                color='success'
              />
            </Grid>
          )}

          {METAL_CATEGORIES.filter((item) => post?.category?.includes(item.id))?.map((item) => (
            <Grid p={1} key={item.id}>
              <Chip label={item.category} />
            </Grid>
          ))}
        </Grid>

        <Box pt={5} pb={3}>
          <Typography variant='h5' color='rgba(62, 62, 62, 1)'>
            {post?.short_description}
          </Typography>
        </Box>
        <Box>{post?.description && <RenderContent content={post.description} />}</Box>

        <Box>
          {!!attachmentSectionResponse?.length && (
            <>
              <Divider />
              <Typography variant='h3' mt={2}>
                Attachments
              </Typography>
            </>
          )}
          {attachmentSectionResponse?.map((section, i) => {
            return (
              <Box key={section.section} mt={2}>
                <Typography variant='h4'>
                  {' '}
                  {i + 1}
                  {')'} {section.title}{' '}
                </Typography>
                <Typography variant='h6' color='rgba(62, 62, 62, 1)'>
                  {section.description}
                </Typography>
                <Grid container justifyContent='center'>
                  {section.pdf.map((file) => {
                    return (
                      <PdfPreview
                        key={file.id}
                        url={file.file_url}
                        onOpenPdf={() => handleFileOpen(file.file_url)}
                      />
                    )
                  })}
                </Grid>

                <Grid container justifyContent='center'>
                  {section.img.map((file) => {
                    return (
                      <ImagePreview
                        key={file.id}
                        url={file.file_url}
                        onOpenImage={() => handleFileOpen(file.file_url)}
                      />
                    )
                  })}
                </Grid>
              </Box>
            )
          })}
        </Box>
      </Box>
      {showNews ? (
        <SimpleModal
          open={showNews}
          onClose={handleClose}
          title={'Aylien News'}
          leftButton={{
            label: '',
            onClick: handleClose,
          }}
          rightButton={{
            label: 'Close',
            onClick: handleClose,
          }}
        >
          <Typography variant='h5'>
            {/* {post.original_news?.length
              ? post.original_news
              : 'We are waiting for the latest news!'} */}

            {post.original_news?.length ? (
              <div
                className='external-html'
                dangerouslySetInnerHTML={{ __html: post.original_news }}
              />
            ) : (
              'We are waiting for the latest news!'
            )}
          </Typography>
        </SimpleModal>
      ) : null}
    </Box>
  )
}

export default EditorViewPost
