import React, { useState } from 'react'
import { Grid, Box, Typography, Button } from '@mui/material'
import logo from 'src/assets/images/logo.png'
import InputField from 'src/Component/InputField'
import styles from '../index.module.scss'
import { Link } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'

import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorToast } from 'src/utils/toastService'
import { loginUser } from 'src/store/features/auth/authActions'
import UserEmailVerification from 'src/Component/UserEmailVerification'
import TrialInfo from './TrialInfo'

const formSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
})

const Login = () => {
  const [showOtpInput, setShowOtpInput] = useState(false)
  const [emailForOtp, setEmailForOtp] = useState('')
  const dispatch = useDispatch<AppDispatch>()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  })

  type IFormInput = {
    email: string
    password: string
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      loginUser({
        email: data.email,
        password: data.password,
      }),
    ).then((res: any) => {
      console.log(res)
      if (res.error) {
        errorToast(res.payload.error)
        if (res.payload.is_email_verified === false) {
          console.log('Success')
          setEmailForOtp(data.email)
          setShowOtpInput(true)
        }
      }
    })
  }

  return (

    <Grid container direction='row' justifyContent='center'  minHeight='100vh' className={styles.loginRegDirection}>
    <Grid item xs={12} sm={12} md={6}>
    <Grid container justifyContent='center' alignItems='center' height='100%'>
      <Grid item p={1} className={styles.formContainer}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: !showOtpInput ? 'initial' : 'none',
          }}
        >
          <Box textAlign='center'>
            <img src={logo} alt='Logo' height={192} width={192} />
            <Typography variant='h2' pt={2}>
            Sign in
            </Typography>
            <Typography variant='subtitle1'>
              Don&apos;t have an account? <Link to={routeUrls.register} className='styleLink'>Sign Up</Link>
            </Typography>
          </Box>

          <Box mt={3} width='400px' className={styles.form}>
            <Box mt={2}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      label='Email address'
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type='text'
                      placeholder='Example@gmail.com'
                      error={!!errors.email}
                      required
                    />
                    {!!errors.email && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.email.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.password}
                      label='Password'
                      type='password'
                      placeholder='*************'
                      required
                    />
                    {!!errors.password && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.password.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Typography mt={1} variant='subtitle2' className={styles.gratUnderline}>
              <Link to={routeUrls.forgotPassword} className={styles.forgotPassword}>
                {' '}
                Forgot password?
              </Link>
            </Typography>

            <Box mt={2}>
              <Button type='submit' variant='contained' color='primary' fullWidth>
                Sign in
              </Button>
            </Box>
          </Box>
        </form>

        <UserEmailVerification
          email={emailForOtp}
          show={showOtpInput}
          sendVerificationCodeOnLoad={true}
        />
      </Grid>
    </Grid>
    </Grid>
    <Grid item xs={12} sm={12} md={6} className={styles.loginBackgroundImage}>
      <TrialInfo/>
    </Grid>
  </Grid>



  )
}

export default Login
