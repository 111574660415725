import { createSlice } from '@reduxjs/toolkit'

type InitialStateType = {
  mobileOpen: boolean
  isPageLoading: boolean
}

const initialState: InitialStateType = {
  mobileOpen: false,
  isPageLoading: false,
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    handleDrawerToggle(state) {
      state.mobileOpen = !state.mobileOpen
    },
    startPageLoader(state) {
      state.isPageLoading = true
    },
    stopPageLoader(state) {
      state.isPageLoading = false
    },
  },
})

export const { handleDrawerToggle, startPageLoader, stopPageLoader } = commonSlice.actions

export default commonSlice.reducer
