/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { PageResponse, PaginationPayload } from 'src/api/types'
import { API_BASE_PATH } from 'src/utils/constants'
import {
  PostI,
  PostFile,
  DeletePostFilePayload,
  UpdatePostPayload,
  FetchPostFilesResponse,
  AttachmentSectionI,
  UpdatePostAttachmentSectionPayload,
} from './types'

const postApi = createApi({
  reducerPath: 'postApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['postApi'],
  endpoints: (builder) => ({
    fetchPost: builder.query<PageResponse<PostI[]>, PaginationPayload>({
      query: ({
        pageNumber,
        pageSize = 10,
        searchBy = '',
        category = '',
        sortBy = '',
        sortDir = '',
        date = '',
        postStatus = '',
        fromDate = '',
        toDate = '',
      }) => ({
        url: `/api/v1/post/?page_number=${pageNumber}&post_count=${pageSize}&searchBy=${searchBy}&category=${category}&post_status=${postStatus}&sortBy=${sortBy}&sortDir=${sortDir}${
          date ? '&date=' + date : ''
        }${fromDate ? '&fromDate=' + fromDate : ''}${toDate ? '&toDate=' + toDate : ''}`,
        method: 'GET',
      }),
    }),
    getPostById: builder.query<PostI, number>({
      query: (postId) => ({
        url: `/api/v1/post/${postId}/`,
        method: 'GET',
      }),
      transformResponse: (response: PostI[]) => {
        return response[0]
      },
    }),
    fetchPostRelatedFiles: builder.query<FetchPostFilesResponse, number>({
      query: (postId) => ({
        url: `/api/v1/post-files/${postId}/`,
        method: 'GET',
      }),
    }),
    fetchPostAttachmentSections: builder.query<AttachmentSectionI[], number>({
      query: (postId) => ({
        url: `/api/v1/attachmentSectionHandler/${postId}/`,
        method: 'GET',
      }),
    }),
    updatePostById: builder.mutation<FormData, UpdatePostPayload>({
      query: ({ postId, data }) => ({
        url: `/api/v1/post/${postId}/`,
        method: 'PUT',
        data: data,
      }),
    }),
    updatePostAttachmentSection: builder.mutation<void, UpdatePostAttachmentSectionPayload>({
      query: ({sectionId, data}) => ({
        url: `/api/v1/attachmentSectionHandler/${sectionId}/`,
        method: 'PUT',
        data: data,
        headers: { 'Content-type': 'multipart/form-data' },
      }),
    }),
    createPost: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: '/api/v1/post/',
        method: 'POST',
        data: payload,
        headers: { 'Content-type': 'multipart/form-data' },
      }),
    }),
    createPostAttachmentSection: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: '/api/v1/attachmentSectionHandler/',
        method: 'POST',
        data: payload,
        headers: { 'Content-type': 'multipart/form-data' },
      }),
    }),
    deletePostById: builder.mutation<void, number>({
      query: (postId) => ({
        url: `/api/v1/post/${postId}/`,
        method: 'DELETE',
      }),
    }),
    deletePostFileById: builder.mutation<void, DeletePostFilePayload>({
      query: (payload) => ({
        url: '/api/v1/deletePostFile/',
        method: 'POST',
        data: payload,
      }),
    }),
    deletePostSectionById: builder.mutation<void, string>({
      query: (sectionId) => ({
        url: `/api/v1/attachmentSectionHandler/${sectionId}/`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useCreatePostMutation,
  useLazyFetchPostQuery,
  useGetPostByIdQuery,
  useFetchPostRelatedFilesQuery,
  useUpdatePostByIdMutation,
  useDeletePostFileByIdMutation,
  useDeletePostByIdMutation,
  useCreatePostAttachmentSectionMutation,
  useFetchPostAttachmentSectionsQuery,
  useUpdatePostAttachmentSectionMutation,
  useDeletePostSectionByIdMutation,
} = postApi
export default postApi
