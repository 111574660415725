import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import SearchInput from 'src/Component/SearchInput'
import PaginatedTable, { TableHeadType } from 'src/Component/PaginatedTable'
import DataRow from './DataRow'
import styles from './index.module.scss'
import { useFetchEditorUsersQuery, useFetchRegularUsersQuery } from 'src/api/admin/userManagement'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import AddUser from './AddUser'
import DeleteEditorUser from './DeleteEditorUser'
import EmptyTableRow from 'src/Component/EmptyTableRow'

const tableHead: TableHeadType[] = [
  {
    key: 'firstname',
    label: 'First Name',
  },
  {
    key: 'lastname',
    label: 'Last Name',
  },
  {
    key: 'email',
    label: 'Email Id',
  },
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'phonenumber',
    label: 'Phone Number',
  },
  // {
  //   key: 'metalcategory',
  //   label: 'Metal Category',
  // },
  {
    key: 'action',
    label: 'Action',
  },
]

const EditorUser = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')

  const handleOpenDeleteModal = (email: string) => {
    setOpenDeleteModal(true)
    setEmail(email)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handlePageChange = (page: number) => {
    setPage(page + 1)
  }

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    setRowsPerPage(perPage)
    setPage(1)
  }

  const {
    data: EDITOR_USERS_DATA_RESPONSE,
    isFetching,
    // isError,
    refetch,
  } = useFetchEditorUsersQuery(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      searchBy: search,
    },
    { refetchOnMountOrArgChange: true },
  )

  console.log({ EDITOR_USERS_DATA_RESPONSE })
  useEffect(() => {
    if (isFetching) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isFetching])

  const handleAddUser = () => {
    handleOpenCreateModal()
  }

  return (
    <Box>
      <Box className={`cardWrapper cardWrapperContainer  ${styles.headCard}`}>
        <Typography variant='h1'> Users (Editor)</Typography>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={12} md={4} mt={1}>
            <SearchInput onChange={setSearch} hasDebounce />
          </Grid>
          <Grid item className={styles.usrBtnWrapper}>
            <Button
              onClick={handleAddUser}
              className={styles.addUserBtn}
              type='button'
              variant='contained'
              color='primary'
            >
              Add Editor
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5} className="table-container">
        {EDITOR_USERS_DATA_RESPONSE &&
        EDITOR_USERS_DATA_RESPONSE.data &&
        EDITOR_USERS_DATA_RESPONSE.page ? (
          <PaginatedTable
            currentPage={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            tableHead={tableHead}
            totalPages={EDITOR_USERS_DATA_RESPONSE.page.total_page}
            totalDataCount={EDITOR_USERS_DATA_RESPONSE.page.total_data_count}
          >
            <>
              {EDITOR_USERS_DATA_RESPONSE?.data?.map((row, i) => {
                return <DataRow key={i} data={row} onDelete={handleOpenDeleteModal} />
              })}

              {EDITOR_USERS_DATA_RESPONSE.data.length === 0 && (
                <EmptyTableRow cellCount={tableHead.length} />
              )}
            </>
          </PaginatedTable>
        ) : null}
      </Box>
      <AddUser
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        refetchEditorList={refetch}
      />
      <DeleteEditorUser
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        email={email}
        refetchEditorList={refetch}
      />
    </Box>
  )
}

export default EditorUser
