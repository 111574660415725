/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Box, Typography, Grid, Button } from '@mui/material'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import {
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
} from 'src/api/admin/manageSubscription'
import { SubscriptionTypeI } from 'src/api/admin/manageSubscription/types'
import { errorToast, successToast } from 'src/utils/toastService'

export type EditFormInput = {
  subscriptionId: number
  type: string
  price: number
  description: string
} | null

type CreateSubscriptionProps = {
  open: boolean
  onClose: () => void
  refetchSubscriptionList: () => void
  editForm: EditFormInput
}

type IFormInput = {
  type: string
  price: number
  description: string
}

const formSchema = Yup.object().shape({
  type: Yup.string().required('Subscription type is required'),
  price: Yup.number().required('Price is required').min(1).max(999999),
  description: Yup.string().required('Description is required'),
})

const SUBSCRIPTION_TYPES = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
]

const CreateSubscription = ({
  open,
  onClose,
  refetchSubscriptionList,
  editForm,
}: CreateSubscriptionProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      type: '',
      price: 0,
      description: '',
    },
  })

  const [createSubscriptionApi, { isLoading }] = useCreateSubscriptionMutation()
  const [updateSubscriptionApi, { isLoading: isUpdating }] = useUpdateSubscriptionMutation()

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (data.price == 0) {
      setError('price', { type: 'required', message: 'Price is required' })
      return
    }
    if (editForm) {
      // edit subscription
      updateSubscriptionApi({
        id: editForm.subscriptionId,
        payload: {
          subscription_type: data.type as SubscriptionTypeI,
          price: data.price,
          subscription_body: data.description,
        },
      })
        .unwrap()
        .then(() => {
          successToast('Subscription updated successfully.')
          refetchSubscriptionList()
          handleClose()
        })
        .catch((err) => {
          errorToast(err?.data?.error || 'Oops, something went wrong.')
        })
    } else {
      // create new  subscription
      createSubscriptionApi({
        subscription_type: data.type as SubscriptionTypeI,
        price: data.price,
        subscription_body: data.description,
      })
        .unwrap()
        .then(() => {
          successToast('Subscription created successfully.')
          refetchSubscriptionList()
          handleClose()
        })
        .catch((err) => {
          errorToast(err?.data?.error || 'Oops, something went wrong.')
        })
    }
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  useEffect(() => {
    if (editForm) {
      setValue('type', editForm.type)
      setValue('price', editForm.price)
      setValue('description', editForm.description)
    }
  }, [editForm])
  return (
    <SimpleModal
      open={open}
      onClose={handleClose}
      title={editForm ? 'Edit Subsciption' : 'New Subscription'}
      leftButton={{
        label: 'Cancel',
        onClick: handleClose,
      }}
      rightButton={{
        label: editForm ? 'Save' : 'Create',
        onClick: handleSubmit(onSubmit),
        loading: editForm ? isUpdating : isLoading,
      }}
    >
      <Box>
        <Box>
          <Box mt={2}>
            <Controller
              name='type'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <SimpleDropdown
                    label={'Subscription type'}
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    options={SUBSCRIPTION_TYPES.map((type) => ({
                      label: type.label,
                      value: type.value,
                    }))}
                    placeholder='Type'
                    error={!!errors.type}
                    required
                  />

                  {!!errors.type && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.type.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

          <Box mt={2}>
            <Controller
              name='price'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    label='Price'
                    name={field.name}
                    value={field.value || ''}
                    onChange={field.onChange}
                    type='text'
                    regex={/[^0-9]*$/}
                    placeholder='Price ( in $ )'
                    error={!!errors.price}
                    required
                    inputMode='numeric'
                  />
                  {!!errors.price && (
                    <Typography variant='subtitle2' color='red' className='capitalizeFirstWord'>
                      {errors.price.type === 'typeError'
                        ? 'Price should be number'
                        : errors.price.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>
          <Box mt={2}>
            <Controller
              name='description'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    required
                    label='Description'
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    type='text'
                    placeholder='Description'
                    error={!!errors.description}
                    multiline
                    rows={4}
                  />
                  {!!errors.description && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.description.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  )
}

export default CreateSubscription
