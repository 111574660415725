/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { contactUsPayload } from './types'

const contactUsAPI = createApi({
  reducerPath: 'contactUsAPI',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['contactUsAPI'],
  endpoints: (builder) => ({
    submitContact: builder.mutation<void, contactUsPayload>({
      query: (payload) => ({
        url: '/modules/contact-us/contact_us/',
        method: 'POST',
        data: payload,
      }),
    }),
  }),
})

export const {
  useSubmitContactMutation,
} = contactUsAPI
export default contactUsAPI
