import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import SearchInput from 'src/Component/SearchInput'
import PaginatedTable, { TableHeadType } from 'src/Component/PaginatedTable'
import DataRow from './DataRow'
import styles from './index.module.scss'
import { useFetchRegularUsersQuery, useFlagUserMutation } from 'src/api/admin/userManagement'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import AddUser from './AddUser'
import DeleteRegularUser from './DeleteRegularUser'
import EmptyTableRow from 'src/Component/EmptyTableRow'

const tableHead: TableHeadType[] = [
  {
    key: 'firstname',
    label: 'First Name',
  },
  {
    key: 'lastname',
    label: 'Last Name',
  },
  {
    key: 'email',
    label: 'Email Id',
  },
  {
    key: 'username',
    label: 'User Name',
  },
  {
    key: 'organization',
    label: 'Organization',
  },

  {
    key: 'status',
    label: 'Status',
  },

  {
    key: 'inactive',
    label: 'Inactive',
  },
  {
    key: 'action',
    label: 'Action',
  },
]

const UserManagement = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [flagUserApi] = useFlagUserMutation()

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, email: string) => {
    console.log(e.target)
    dispatch(startPageLoader())
    flagUserApi({ userEmail: email, active: !e.target.checked }).finally(() => {
      dispatch(stopPageLoader())
    })
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleDeleteUser = (email: string) => {
    setOpenDeleteModal(true)
    setEmail(email)
  }
  const handlePageChange = (page: number) => {
    setPage(page + 1)
  }

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    setRowsPerPage(perPage)
    setPage(1)
  }

  const {
    data: USERS_DATA_RESPONSE,
    isFetching,
    // isError,
    refetch,
  } = useFetchRegularUsersQuery(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      searchBy: search,
    },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (isFetching) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isFetching])

  const handleAddUser = () => {
    handleOpenCreateModal()
  }

  const handleSearch = (value: string) => {
    setSearch(value)
    setPage(1)
  }

  return (
    <Box>
      <Box className={`cardWrapper cardWrapperContainer  ${styles.headCard}`}>
        <Typography variant='h1'> Users (Regular) </Typography>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={12} md={4} mt={1}>
            <SearchInput onChange={handleSearch} hasDebounce />
          </Grid>
          <Grid item  className={styles.usrBtnWrapper}>
            <Button
              onClick={handleAddUser}
              className={styles.addUserBtn}
              type='button'
              variant='contained'
              color='primary'
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5} className="table-container">
        {USERS_DATA_RESPONSE && USERS_DATA_RESPONSE.data && USERS_DATA_RESPONSE.page ? (
          <PaginatedTable
            currentPage={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            tableHead={tableHead}
            totalPages={USERS_DATA_RESPONSE.page.total_page}
            totalDataCount={USERS_DATA_RESPONSE.page.total_data_count}
          >
            <>
              {USERS_DATA_RESPONSE?.data?.map((row, i) => {
                return (
                  <DataRow
                    key={row.id}
                    data={row}
                    onDelete={handleDeleteUser}
                    handleSwitchChange={(e) => handleSwitchChange(e, row.email)}
                  />
                )
              })}

              {USERS_DATA_RESPONSE.data.length === 0 && (
                <EmptyTableRow cellCount={tableHead.length} />
              )}
            </>
          </PaginatedTable>
        ) : null}
      </Box>
      <AddUser
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        refetchRegularUserList={refetch}
      />
      <DeleteRegularUser
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        email={email}
        refetchRegularUserList={refetch}
      />
    </Box>
  )
}

export default UserManagement
