/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import PlanRow from './PlanRow'
import { useFetchSubscriptionPlanQuery } from 'src/api/user/subscriptionApi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { setSelectedPlanOnSignUp } from 'src/store/features/auth/authSlice'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { TRIAL_SUBSCRIPTION_PLAN } from 'src/utils/constants'
import styles from '../index.module.scss'

const SubscriptionPlan = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { data: subscriptionPlans = [], isLoading } = useFetchSubscriptionPlanQuery()
  const { selectedPlanOnSignUp } = useSelector((state: RootState) => state.auth)

  const handleSelectPlan = (id: number, price: number, type: string) => {
    dispatch(
      setSelectedPlanOnSignUp({
        id,
        price,
        type,
      }),
    )
  }

  useEffect(() => {
    if (isLoading) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isLoading])
  return (
    <Grid container alignItems='center' height='100%' className={styles.trialSubPlanContainer}>
      <Grid xs={12} sm={12} md={2}></Grid>
      <Grid xs={12} sm={12} md={8}>
        <Box
          sx={{
            background: 'white',
            padding: '30px',
          }}
        >
          <Typography variant='h1'>Sign up and get 10 days free trial.</Typography>
          <Typography variant='body2' mt={1}>
            Take a free trial before you subscribe.
          </Typography>

          <Box mt={2}>
            {[TRIAL_SUBSCRIPTION_PLAN, ...subscriptionPlans].map((plan) => (
              <PlanRow
                isActive={plan.id === selectedPlanOnSignUp.id}
                key={plan.id}
                id={plan.id}
                type={plan.subscription_type}
                description={plan.subscription_body}
                price={plan.price}
                onClick={handleSelectPlan}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default SubscriptionPlan
