/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button, Avatar, Input, Badge } from '@mui/material'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import {
  useFetchMyProfileInfoQuery,
  useUpdateMyProfileInfoMutation,
} from 'src/api/editor/settingsApi'
import { useDispatch } from 'react-redux'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { AppDispatch } from 'src/store'
import { errorToast, successToast } from 'src/utils/toastService'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import editIcon from 'src/assets/icons/edit-photo.png'
import SimpleModal from 'src/Component/SimpleModal'

const formSchema = Yup.object().shape({
  category: Yup.string().required('Category is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.number(),
})

type IFormInput = {
  category: string
  firstName: string
  lastName: string
  email: string
  phone?: number
}

const AccountInformation = () => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      category: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: 0,
    },
  })
  const [imageFile, setImageFile] = useState<File | null | string>('')

  const [isEditable, setIsEditable] = useState(false)
  const {
    data,
    isFetching: isFetchingProfile,
    refetch: refetchMyProfileInfo,
  } = useFetchMyProfileInfoQuery()
  const dispatch = useDispatch<AppDispatch>()
  const [updateProfileApi, { isLoading: isUpdatingProfile }] = useUpdateMyProfileInfoMutation()
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()

  const enableEditMode = () => setIsEditable(true)
  const disableEditMode = () => {
    if (data) {
      setValue('category', data.metal_category || '')
      setValue('firstName', data.firstname || '')
      setValue('lastName', data.lastname || '')
      setValue('email', data.email || '')
      setValue('phone', Number(data.ph_number) || 0)
      setImageFile(data.profile_url || '')
      clearErrors()
    }
    setIsEditable(false)
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageFile(e.target.files?.length ? e.target.files[0] : null)
  }

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false)
    disableEditMode()
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const formData = new FormData()
    formData.append('metal_category', data.category)
    formData.append('email', data.email)
    formData.append('firstname', data.firstName)

    formData.append('lastname', data.lastName)
    formData.append('ph_number', data.phone ? data.phone.toString() : '')

    formData.append('organization', 'test')

    if (imageFile && typeof imageFile !== 'string') {
      formData.append('file', imageFile)
    }

    updateProfileApi(formData)
      .unwrap()
      .then((res) => {
        setConfirmationOpen(false)
        successToast('Account information saved successfully.')
        disableEditMode()
        refetchMyProfileInfo()
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Ops, Something went wrong.')
      })
  }

  useEffect(() => {
    if (isFetchingProfile || isUpdatingProfile) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isFetchingProfile, isUpdatingProfile])

  useEffect(() => {
    console.log(data)
    if (data) {
      setValue('category', data.metal_category || 'Gold')
      setValue('firstName', data.firstname || '')
      setValue('lastName', data.lastname || '')
      setValue('email', data.email || '')
      setValue('phone', Number(data.ph_number) || 0)
      setImageFile(data.profile_url || '')
    }
  }, [data, METAL_CATEGORIES])
  return (
    <Box>
      <Grid container justifyContent='space-between' alignItems='center' pb={2}>
        <Grid item>
          <Typography variant='h3'>Account</Typography>
        </Grid>

        <Grid item>
          {isEditable === false ? (
            <Button
              variant='outlined'
              sx={{
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
              onClick={enableEditMode}
            >
              Edit
            </Button>
          ) : (
            <Box>
              <Button
                variant='outlined'
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginRight: '20px',
                }}
                onClick={disableEditMode}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                sx={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setConfirmationOpen(true)}
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box>
        <form>
          <Box>
            <Box>
              <Badge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  isEditable ? (
                    <label htmlFor='userImage' style={{ cursor: 'pointer' }}>
                      <img src={editIcon} height={32} width={32} />
                      <Input
                        type='file'
                        onChange={handleImageChange}
                        inputProps={{ accept: 'image/*' }}
                        sx={{ display: 'none' }}
                        name='userImage'
                        id='userImage'
                      />
                    </label>
                  ) : null
                }
              >
                <Avatar
                  src={(() => {
                    if (imageFile) {
                      if (typeof imageFile === 'string') return imageFile
                      else return URL.createObjectURL(imageFile)
                    }
                    return 'broken-image.png'
                  })()}
                  variant='circular'
                  sx={{
                    width: 144,
                    height: 144,
                    border: '5px solid rgba(189, 189, 189, 1)',
                  }}
                />
              </Badge>
            </Box>
            {/* <Box mt={2}>
              <Controller
                name='category'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <SimpleDropdown
                      disabled={true}
                      label={'Category'}
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      options={METAL_CATEGORIES.map((category) => ({
                        label: category,
                        value: category,
                      }))}
                      placeholder='Select category'
                      error={!!errors.category}
                    />

                    {!!errors.category && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.category.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box> */}

            <Box mt={2}>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.firstName}
                      label='First Name'
                      type='text'
                      placeholder='Enter first name'
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.firstName && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.firstName.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                name='lastName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.lastName}
                      label='Last Name'
                      type='text'
                      placeholder='Enter last name'
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.lastName && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.lastName.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      label='Email address'
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type='text'
                      placeholder='Enter your email'
                      error={!!errors.email}
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.email && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.email.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='phone'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value || ''}
                      onChange={(e: any) => {
                        if (!e.target.value) {
                          field.onChange({
                            ...e,
                            target: {
                              ...e.target,
                              value: 0,
                            },
                          })
                        } else {
                          field.onChange(e)
                        }
                      }}
                      error={!!errors.phone}
                      label='Phone number'
                      type='text'
                      regex={/[^0-9]*$/}
                      placeholder='Enter phone number'
                      disabled={!isEditable}
                    />

                    {!!errors.phone && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.phone.type === 'typeError'
                          ? 'Phone shoule be number'
                          : errors.phone.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
          </Box>
        </form>
      </Box>

      <Box>
        <SimpleModal
          open={confirmationOpen}
          onClose={handleConfirmationCancel}
          title='Profile'
          leftButton={{
            label: 'Cancel',
            onClick: handleConfirmationCancel,
          }}
          rightButton={{
            label: 'Yes, Change',
            onClick: handleSubmit(onSubmit),
          }}
        >
          <Typography variant='h5' textAlign='center'>
            Are you sure you want to update profile?{' '}
          </Typography>
        </SimpleModal>
      </Box>
    </Box>
  )
}

export default AccountInformation
