import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
const PageLoader = () => {
  const { isPageLoading } = useSelector((state: RootState) => state.common)
  const { loading: authLoading } = useSelector((state: RootState) => state.auth)
  const { loading: staticPageContentLoading } = useSelector(
    (state: RootState) => state.staticPageContent,
  )

  const { loading: forgotPasswordLoading } = useSelector((state: RootState) => state.forgotPassword)
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => 99999999 }}
      open={isPageLoading || authLoading || staticPageContentLoading || forgotPasswordLoading}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default PageLoader
