import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { myColors } from 'src/utils/setup/theme'

const SwitchButton = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    color: '#9E9E9E',

    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: myColors.primary,
      '& + .MuiSwitch-track': {
        backgroundColor: '#D9D9D9',
        opacity: 1,
        border: '1px solid ' + myColors.primary,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: '1px solid #9E9E9E',

    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

type CustomizedSwitchesProps = {
  name: string
  defaultChecked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, active: boolean) => void
}

export default function CustomizedSwitches({
  defaultChecked,
  onChange,
  name,
}: CustomizedSwitchesProps) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <SwitchButton
            sx={{ m: 1 }}
            defaultChecked={defaultChecked}
            onChange={onChange}
            name={name}
          />
        }
        label=''
      />
    </FormGroup>
  )
}
