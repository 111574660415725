/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { ChangePasswordPayload, MyProfileInfoI } from './types'

const settingsApi = createApi({
  reducerPath: 'settingsApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['settingsApi'],
  endpoints: (builder) => ({
    fetchMyProfileInfo: builder.query<MyProfileInfoI, void>({
      query: () => ({
        url: '/api/v1/myProfile/',
        method: 'GET',
      }),
      transformResponse: (response: MyProfileInfoI[]) => {
        return response[0]
      },
    }),
    updateMyProfileInfo: builder.mutation<void, MyProfileInfoI>({
      query: (payload) => ({
        url: '/api/v1/myProfile/0/',
        method: 'PUT',
        data: payload,
      }),
    }),
    changePassword: builder.mutation<void, ChangePasswordPayload>({
      query: (payload) => ({
        url: '/api/v1/changePassword/',
        method: 'POST',
        data: payload,
      }),
    }),

    deleteAccount: builder.mutation<void, void>({
      query: () => ({
        url: '/api/v1/deleteMyAccount/',
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useFetchMyProfileInfoQuery,
  useUpdateMyProfileInfoMutation,
  useChangePasswordMutation,
  useDeleteAccountMutation,
} = settingsApi
export default settingsApi
