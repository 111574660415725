import React, { useRef } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography, IconButton } from '@mui/material'
import downloadIcon from 'src/assets/icons/download.png'
import { useDownloadSingleInvoiceMutation } from 'src/api/user/subscriptionApi'
import { errorToast, successToast } from 'src/utils/toastService'
import NewTabLoader from 'src/Component/NewTabLoader'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import dayjs from 'dayjs'

type HistoryTableRowProps = {
  Invoice: string
  PayerName: string
  Date: string
  Amount: number
}

const HistoryTableRow = ({ Invoice, PayerName, Date, Amount }: HistoryTableRowProps) => {
  const [DownloadSingleInvoiceApi, { isLoading }] = useDownloadSingleInvoiceMutation()
  const dispatch = useDispatch<AppDispatch>()

  const handleSingleInvoiceDownload = () => {
    dispatch(startPageLoader())
    // eslint-disable-next-line camelcase
    DownloadSingleInvoiceApi({ invoice_id: Invoice })
      .unwrap()
      .then((res) => {
        successToast('Invoice downloaded successfully.')
        const link = document.createElement('a')
        link.href = res.pdf
        link.download = `${Invoice}_payment_invoice.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((err) => {
        errorToast(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component='th' scope='row'>
          {Invoice}
        </TableCell>
        <TableCell align='left'>
          <Typography variant='h5'>{PayerName}</Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='h5'>{dayjs(Date).format('MMM DD, YYYY hh:mm A')}</Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='h5'>${Amount}</Typography>
        </TableCell>
        <TableCell align='left'>
          <IconButton onClick={handleSingleInvoiceDownload}>
            <img src={downloadIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default HistoryTableRow
