import React from 'react'
import { Dialog, DialogTitle, Button, Grid, Typography, IconButton, Box } from '@mui/material'
import closeIcon from 'src/assets/icons/close-icon.png'
import infoIcon from 'src/assets/icons/info.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { setShowTrialStartPopUp } from 'src/store/features/auth/authSlice'
import { TRIAL_PERIOD_DAYS, userRoles } from 'src/utils/constants'

const FreeTrialStartPopUp = () => {
  const dispatch = useDispatch<AppDispatch>()
  const {
    showTrialStartPopUp = false,
    selectedPlanOnSignUp,
    role,
  } = useSelector((state: RootState) => state.auth)

  const onClose = () => {
    dispatch(setShowTrialStartPopUp(false))
  }

  return (
    <Dialog
      maxWidth='sm'
      fullWidth
      open={showTrialStartPopUp && selectedPlanOnSignUp.id === 0 && role === userRoles.user}
      onClose={onClose}
    >
      <DialogTitle>
        <Grid container justifyContent='space-evenly'>
          <Grid item>
            <img src={infoIcon} />
          </Grid>
          <Grid item>
            <Typography variant='h3' fontWeight='bold' pl={1}>
              Your {TRIAL_PERIOD_DAYS}-day trial period starts from today!
            </Typography>

            <Typography variant='subtitle1' fontWeight='bold' pl={1}>
              Enjoy Your {TRIAL_PERIOD_DAYS}-day trial period.
            </Typography>

            <Grid container mt={2}>
              <Grid p={2} flexGrow={1} pt={0}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onClose}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <IconButton onClick={onClose}>
              <img src={closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    </Dialog>
  )
}

export default FreeTrialStartPopUp
