/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BASE_PATH, localStorageKeys } from 'src/utils/constants'

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password }: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/login/`,
        { username: email, password },
        config,
      )
      return response.data
    } catch (error: any) {
      console.log(error)
      // return custom error message from backend if present
      if (!error?.response?.data) return rejectWithValue('Opps something went wrong.')

      if (error.response.data.error) {
        return rejectWithValue(error.response.data)
      }

      if (error.response.data.error_message) {
        console.log(error.response.data.error_message)

        return rejectWithValue(error.response.data.error_message)
      }

      return rejectWithValue('Opps something went wrong.')
    }
  },
)

type RegisterUserPayload = {
  firstname: string
  lastname: string
  organization: string
  username: string
  email: string
  password: string
}
export const registerUser = createAsyncThunk(
  'auth/register/user',
  async (payload: RegisterUserPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/signup/`,
        {
          ...payload,
        },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (!error?.response?.data) return rejectWithValue('Opps something went wrong.')

      if (error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      }

      if (error.response.data.error_message) {
        console.log(error.response.data.error_message)

        return rejectWithValue(error.response.data.error_message)
      }

      return rejectWithValue('Opps something went wrong.')
    }
  },
)

type VerifyEmailOtpPayload = {
  email: string
  otp: string
}
export const verifyEmailOtp = createAsyncThunk(
  'auth/register/verify-otp',
  async (payload: VerifyEmailOtpPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/signup/verify_email_otp/`,
        {
          ...payload,
        },
        config,
      )

      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (!error?.response?.data) return rejectWithValue('Opps something went wrong.')

      if (error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      }

      if (error.response.data.error_message) {
        console.log(error.response.data.error_message)

        return rejectWithValue(error.response.data.error_message)
      }

      return rejectWithValue('Opps something went wrong.')
    }
  },
)

export const resendEmailOtp = createAsyncThunk(
  'auth/register/resend-otp',
  async ({ email }: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/signup/resend_email_verification_mail/`,
        { email },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      if (!error?.response?.data) return rejectWithValue('Opps something went wrong.')

      if (error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      }

      if (error.response.data.error_message) {
        console.log(error.response.data.error_message)

        return rejectWithValue(error.response.data.error_message)
      }

      return rejectWithValue('Opps something went wrong.')
    }
  },
)

export const logoutUser = createAsyncThunk('auth/logout', async (payload, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + localStorage.getItem(localStorageKeys.accessToken),
      },
    }
    const response = await axios.get(`${API_BASE_PATH}/api/v1/logout/`, config)
    return response.data
  } catch (error: any) {
    // return custom error message from backend if present
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
