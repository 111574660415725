/* eslint-disable camelcase */
import React from 'react'
import { Grid, Typography, Box, Button, CircularProgress } from '@mui/material'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import { useChangePasswordMutation } from 'src/api/user/settingsApi'
import { errorToast, successToast } from 'src/utils/toastService'

const formSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current password*e is required'),
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
})

const ChangePassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },

    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  const [changePasswordApi, { isLoading }] = useChangePasswordMutation()

  type IFormInput = {
    currentPassword: string
    newPassword: string
    confirmPassword: string
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data)
    changePasswordApi({
      old_password: data.currentPassword,
      new_password1: data.newPassword,
      new_password2: data.confirmPassword,
    })
      .unwrap()
      .then((res) => {
        console.log(res)
        reset()
        successToast('Password changed successfully.')
      })
      .catch((err) => {
        console.log(err)
        errorToast(err?.data?.error || 'Opps, something went wrong.')
      })
  }

  return (
    <Box>
      <Box mb={2}>
        <Typography mb={2} variant='h3'>
          Change Password
        </Typography>
        <Typography variant='subtitle1'>Reset password in quick steps</Typography>
      </Box>

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box>
              <Controller
                name='currentPassword'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.currentPassword}
                      label='Current Password'
                      type='password'
                      placeholder='*************'
                      required
                      disablePaste={true}
                    />
                    {!!errors.currentPassword && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.currentPassword.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='newPassword'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.newPassword}
                      label='Enter new Password'
                      type='password'
                      placeholder='*************'
                      required
                      disablePaste={true}
                    />
                    {!!errors.newPassword && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.newPassword.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='confirmPassword'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.confirmPassword}
                      label='Verify new Password'
                      type='password'
                      placeholder='*************'
                      required
                      disablePaste={true}
                    />
                    {!!errors.confirmPassword && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.confirmPassword.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
          </Box>
          <Grid container>
            <Grid
              xs={12}
              sm={12}
              md={6}
              mt={2}
              sx={{
                paddingRight: {
                  sm: 0,
                  xs: 0,
                  md: 1,
                },
              }}
            >
              <Button type='button' variant='outlined' fullWidth onClick={() => reset()}>
                Cancel
              </Button>
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={6}
              mt={2}
              sx={{
                paddingLeft: {
                  sm: 0,
                  xs: 0,
                  md: 1,
                },
              }}
            >
              <Button type='submit' variant='contained' fullWidth disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} color='secondary' /> : 'Change Password'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  )
}

export default ChangePassword
