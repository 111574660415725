import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, Button, Grid, Typography, IconButton, Box } from '@mui/material'
import closeIcon from 'src/assets/icons/close-icon.png'
import infoIcon from 'src/assets/icons/info.png'
import errorIcon from 'src/assets/icons/error.png'

import { TRIAL_PERIOD_DAYS, localStorageKeys, routeUrls, userRoles } from 'src/utils/constants'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

const TrialAndSubscriptionEndPopUp = () => {
  const navigate = useNavigate()
  const [message, setMessage] = useState<any>({
    open: false,
    icon: null,
    title: '',
    description: '',
  })

  const onClose = () => {
    setMessage({
      open: false,
      icon: null,
      title: '',
      description: '',
    })
  }

  const navigateToSubscriptionPage = () => {
    onClose()
    navigate(routeUrls.user.settings + `?tab=${routeUrls.user.settingTabs.subscription}`)
  }

  useEffect(() => {
    let user: any = localStorage.getItem(localStorageKeys.user) || null

    const isFreeUser: boolean = (() => {
      const isFreeUser = localStorage.getItem(localStorageKeys.isFreeUser)
      if (isFreeUser) {
        return !!JSON.parse(isFreeUser)
      }
      return false
    })()

    if (user && !isFreeUser) {
      try {
        user = JSON.parse(user)

        if (user.user_type === userRoles.user) {
          // trail will expire
          if (
            user.subscription &&
            user.subscription.trial_completed === false &&
            user.subscription.subscription_taken === false &&
            user.subscription.trial_started_at &&
            user.subscription.trail_end_date
          ) {
            const trialEndOn = dayjs(user.subscription.trail_end_date)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
            const today = dayjs()
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)

            const dayLeftInTrial = trialEndOn.diff(today, 'day')

            if (dayLeftInTrial == 1) {
              // trial expire in 2 days
              setMessage({
                icon: <img src={infoIcon} />,
                title: ` Your ${TRIAL_PERIOD_DAYS}-day trial period will expire soon.`,
                description: 'Choose an option to maintain access.',
                open: true,
              })
            } else if (dayLeftInTrial === 0) {
              setMessage({
                icon: <img src={infoIcon} />,
                title: ` Your ${TRIAL_PERIOD_DAYS}-day trial period will expire today.`,
                description: 'Choose an option to maintain access.',
                open: true,
              })
            }
          }
          // trial expired
          else if (
            user.subscription &&
            user.subscription.trial_completed === true &&
            user.subscription.subscription_taken === false &&
            !user.subscription.start_date &&
            !user.subscription.end_date
          ) {
            setMessage({
              icon: <img src={errorIcon} />,
              title: `Your ${TRIAL_PERIOD_DAYS}-day trial period has expired.`,
              description:
                'To continue using and access more features, please purchase the full version.',
              open: true,
            })
          }
          // subscription will expire
          else if (
            user.subscription &&
            user.subscription.subscription_taken === true &&
            user.subscription.subscription_id &&
            user.subscription.start_date &&
            user.subscription.end_date
          ) {
            const subscriptionEndOn = dayjs(user.subscription.end_date)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
            const today = dayjs()
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)

            const dayLeft = subscriptionEndOn.diff(today, 'day')

            if (dayLeft == 13) {
              // plan expire in 2 weeks
              setMessage({
                icon: <img src={infoIcon} />,
                title: 'Your subscription will auto-renew in 2 weeks',
                description: 'Manage your settings to see other options',
                open: true,
              })
            } else if (dayLeft === 0) {
              setMessage({
                icon: <img src={infoIcon} />,
                title: 'Your subscription will auto-renew tommorrow.',
                description: 'Manage your settings to see other options',
                open: true,
              })
            }
          }
          // subscription expired
          else if (
            user.subscription &&
            user.subscription.subscription_taken === false &&
            user.subscription.start_date &&
            user.subscription.end_date
          ) {
            const subscriptionEndOn = dayjs(user.subscription.end_date)
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)
            const today = dayjs()
              .set('hour', 0)
              .set('minute', 0)
              .set('second', 0)
              .set('millisecond', 0)

            if (today.diff(subscriptionEndOn, 'day') > 0) {
              // trial period expired
              setMessage({
                icon: <img src={errorIcon} />,
                title: 'Your subscription has expired.',
                description:
                  'To continue using and access more features, please purchase the full version.',
                open: true,
              })
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [])

  return (
    <Dialog maxWidth='sm' fullWidth open={message.open} onClose={onClose}>
      <DialogTitle>
        <Grid container justifyContent='space-evenly'>
          <Grid item xs={1} textAlign='left'>
            {message.icon}
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h3' fontWeight='bold' pl={1}>
              {message.title}
            </Typography>

            <Typography variant='subtitle1' fontWeight='bold' pl={1}>
              {message.description}
            </Typography>

            <Grid container mt={2}>
              <Grid p={2} xs={12} sm={6} md={6} pt={0}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onClose}
                >
                  Ok
                </Button>
              </Grid>
              <Grid p={2} xs={12} sm={6} md={6} pt={0}>
                <Button
                  fullWidth
                  type='button'
                  variant='contained'
                  color='primary'
                  onClick={navigateToSubscriptionPage}
                >
                  Subscribe
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} textAlign='right'>
            <IconButton onClick={onClose}>
              <img src={closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    </Dialog>
  )
}

export default TrialAndSubscriptionEndPopUp
