import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import styles from './index.module.scss'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

type SubscriptionPlanProps = {
  id: number
  type: string
  isActivePlan: boolean
  price: number
  description: string
  onSelect?: () => void
  onCancelPlan?: () => void
  hideCancel?: boolean
}
const SubscriptionPlan = ({
  type,
  price,
  description,
  onSelect,
  isActivePlan,
  onCancelPlan,
  hideCancel = false,
  id,
}: SubscriptionPlanProps) => {
  return (
    <Box className={styles.wrapper}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h3' className='capitalize' display='flex' alignItems='center'>
          {isActivePlan ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
          &nbsp;{type}
        </Typography>
        <Typography variant='h1'> ${price} </Typography>
      </Grid>

      <Typography variant='body2' mb={2} mt={2} className={styles.description}>
        {description}
      </Typography>

      {isActivePlan ? (
        <Grid container>
          {hideCancel ? (
            <Grid xs={12} p={1} pb={0}>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                disabled
                className='disabledButton'
              >
                Current Plan
              </Button>
            </Grid>
          ) : (
            <>
              <Grid xs={12} sm={12} md={6} p={1} pb={0}>
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={onCancelPlan}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={12} sm={12} md={6} p={1} pb={0}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  disabled
                  className='disabledButton'
                >
                  Current Plan
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Box mt={1}>
          <Button type='submit' variant='contained' color='primary' fullWidth onClick={onSelect}>
            Choose Plan
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SubscriptionPlan
