import React, { useState, useRef } from 'react'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

const IconButton = styled(Button)(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  color: black;
  border-radius:10px
  `,
)

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

interface InputFieldtProps {
  name: string
  type: 'text' | 'email' | 'password' | 'number' | 'date'
  label: string
  id?: string
  value?: any
  placeholder?: string
  onChange?: any
  multiline?: boolean
  rows?: number
  required?: boolean
  autoComplete?: string
  regex?: RegExp
  error?: boolean
  disablePaste?: boolean
  disabled?: boolean
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
}

const InputField = (props: InputFieldtProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const inputElm = useRef<HTMLInputElement>(null)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    // event.preventDefault()
  }

  const handleClickShowDatePicker = () => {
    inputElm?.current?.showPicker()
  }

  const handleMouseDownDatePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleChange = (e: any) => {
    if (props.regex) {
      e.target.value = e.target.value.replace(props.regex, '')
    }
    if (typeof props.onChange === 'function') {
      props.onChange(e)
    }
  }

  let onPaste = undefined
  if (props.disablePaste) {
    onPaste = (e: any) => {
      e.preventDefault()
    }
  }
  return (
    <FormControl variant='standard' fullWidth>
      <Typography variant='h4' component='p' mb={1}>
        <label htmlFor={props.id}>
          {props.label}
          {props.required && (
            <Typography variant='subtitle1' color='red' component='span'>
              &nbsp;*
            </Typography>
          )}
        </label>
      </Typography>
      <InputBase
     
        error={!!props.error}
        name={props.name}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={handleChange}
        type={showPassword ? 'text' : props.type}
        multiline={!!props.multiline}
        rows={props.rows || 0}
        // required={!!props.required}
        autoComplete={props.autoComplete}
        onPaste={onPaste}
        disabled={!!props.disabled}
        inputProps={{
          inputMode: props.inputMode,
        }}
        endAdornment={
          props.type === 'password' ? (
            <InputAdornment
              sx={{
                position: 'absolute',
                right: 0,
                background: 'none',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : props.type === 'date' ? (
            <InputAdornment
              sx={{
                position: 'absolute',
                right: 0,
                background: 'white',
                borderRadius: '10px',
              }}
            >
              <IconButton
                aria-label='open date picker'
                onClick={handleClickShowDatePicker}
                onMouseDown={handleMouseDownDatePicker}
              >
                <KeyboardArrowDownIcon color='success' />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        slotProps={{
          input: {
            style: {
              paddingRight: props.type === 'password' ? '75px' : '21px',
              background: props.disabled?'lightgray':'white'
            },
          },
        }}
      />
    </FormControl>
  )
}

export default InputField
