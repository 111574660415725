/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { SubscriptionI, SubscriptionPayload, UpdateSubscriptionPayload } from './types'

const manageSubscription = createApi({
  reducerPath: 'manageSubscription',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['manageSubscription'],
  endpoints: (builder) => ({
    fetchSubscriptions: builder.query<SubscriptionI[], void>({
      query: () => ({
        url: '/api/v1/subscription/',
        method: 'GET',
      }),
    }),
    createSubscription: builder.mutation<void, SubscriptionPayload>({
      query: (payload) => ({
        url: '/api/v1/subscription/',
        method: 'POST',
        data: payload,
      }),
    }),

    updateSubscription: builder.mutation<void, UpdateSubscriptionPayload>({
      query: ({ id, payload }) => ({
        url: `/api/v1/subscription/${id}/`,
        method: 'PUT',
        data: payload,
      }),
    }),

    deleteSubscription: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/v1/subscription/${id}/`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useFetchSubscriptionsQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = manageSubscription
export default manageSubscription
