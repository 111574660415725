import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

import SearchIcon from 'src/assets/icons/search.png'

import { debounce } from 'lodash'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  background: 'none',
  marginRight: theme.spacing(2),
  //   marginLeft: 0,
  width: '100%',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  right: 0,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    paddingRight: '50px',
  },
}))

type SearchInputProps = {
  onChange: (value: string) => void
  hasDebounce?: boolean
}
const SearchInput = ({ onChange, hasDebounce = false }: SearchInputProps) => {
  const handleSearch = hasDebounce
    ? debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
      }, 700)
    : (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
      }

  return (
    <Search>
      <SearchIconWrapper>
        <img src={SearchIcon} height={16} style={{ zIndex: 1 }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder='Search field'
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
      />
    </Search>
  )
}

export default SearchInput
