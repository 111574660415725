import { Box, Divider, Skeleton, Typography } from '@mui/material';
import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useGetPostByIdQuery } from 'src/api/admin/postApi';
import styles from './index.module.scss';


const News:React.FC = () => {
    const { postId } = useParams<{ postId: string }>();
    const postIdNumber = postId ? Number(postId) :0;
    const { data: post, isFetching } = useGetPostByIdQuery(postIdNumber, {
        refetchOnMountOrArgChange: true,
      })
      console.log('post', post);

      useEffect(() => {
        const externalLinks = document.querySelectorAll('.external-html p a') as NodeListOf<HTMLElement>;
        console.log('externalLinks', externalLinks);
      
        externalLinks.forEach(anchor => {
          anchor.setAttribute('target', '_blank');
          anchor.setAttribute('rel', 'noopener noreferrer');
          anchor.style.color = 'rgba(0, 83, 157, 1)';
          anchor.style.fontWeight='600'
        });
      }, [post]);
      

    return (
      
            <Box className={`cardWrapper ${styles.container}`}>
                <Box className={styles.headingContainer}>
                    <Typography color={'white'} variant='h2' textAlign='center'>
                    {'Aylien News'}
                    </Typography>
                </Box>
                <Divider className={styles.divider} />
                {
                    !isFetching && (
                        post?.original_news.length ? (
                            <Typography variant='h5'>
                                <div className='external-html' dangerouslySetInnerHTML={{ __html: post?.original_news }} />
                            </Typography>
                        ) :(
                            <Typography variant='h5' textAlign={'center'}>
                                {'No news found!'}
                            </Typography>
                        )
                    )
                }

                {
                    isFetching && (
                        <Box>
                        <Skeleton animation="wave" width={100}  height={50}  />
                        <Skeleton animation="wave"  height={50}  />
                        <Skeleton animation="wave"  height={100}  />
                        <Skeleton animation="wave"  height={100}  />
                        <Skeleton animation="wave"  height={50}  />
                        <Skeleton animation="wave"  height={100}  />
                        </Box>
                    )
                }
            </Box>
     
        
    );
        }
        export default News;