import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, Button, IconButton } from '@mui/material'
import PdfPreview from 'src/Component/PdfPreview'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'

import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetPostByIdQuery,
  useDeletePostFileByIdMutation,
  useUpdatePostByIdMutation,
  useDeletePostSectionByIdMutation,
  useUpdatePostAttachmentSectionMutation,
  useCreatePostAttachmentSectionMutation,
  useFetchPostAttachmentSectionsQuery,
} from 'src/api/admin/postApi'

import styles from './index.module.scss'

import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { POST_STATUS } from 'src/utils/constants'
import InputField from 'src/Component/InputField'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import RichTextEditor from 'src/Component/RichTextEditor'
import PdfUpload from 'src/Component/PdfUpload'
import * as Yup from 'yup'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { errorToast, successToast } from 'src/utils/toastService'
import { yupResolver } from '@hookform/resolvers/yup'
import ImagePreview from 'src/Component/ImagePreview'
import ImageUpload from 'src/Component/ImageUpload'
import { PostFile, PostStatusI } from 'src/api/admin/postApi/types'
import GroupButton from 'src/Component/GroupButton'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import MultiSelectDropdown from 'src/Component/MultiSelectDropdown'
import { useTheme } from '@mui/material/styles';
import { ADDRESSING_CONTENT, ADDRESSING_CONTENT_DEAFULT_TEXT, ADDRESSING_CONTENT_MESSAGE } from 'src/Page/Private/const'

type IFormInput = {
  title: string
  category: any[]
  description: string
  content: string
  welcomeMessage: string
}

type IAttachmentSection = {
  sectionId?: string
  title: string
  description: string
  pdfs: File[]
  images: File[]
  existingPdfs: PostFile[]
  existingImages: PostFile[]
}

const formSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  category: Yup.array().min(1, 'Category is required').required('Category is required'),
  description: Yup.string().required('Description is required'),
  content: Yup.string().required('Content is required'),
  welcomeMessage: Yup.string().required(ADDRESSING_CONTENT_MESSAGE),
})

const EditorEditPost = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const publishRef = useRef<boolean>(false)
  const previewRef = useRef<boolean>(false)
  const [disablePublishButton, setDisablePublishButton] = useState<boolean>(true)
  const [activePostStatusIndex, setActivePostStatusIndex] = useState<number>(0)
  const postStatusRef = useRef<PostStatusI>('pending')
  const [filestoBeDeletedFromBackend, setFilestoBeDeletedFromBackend] = React.useState<number[]>([])
  const [sectionsToBeDeletedFromBackend, setSectionsToBeDeletedFromBackend] = React.useState<
    string[]
  >([])

  const [attachmentSections, setAttachmentSections] = useState<IAttachmentSection[]>([
    {
      title: '',
      description: '',
      pdfs: [],
      images: [],
      existingPdfs: [],
      existingImages: [],
    },
  ])

  const [updatePostById] = useUpdatePostByIdMutation()
  const [deletePostFile] = useDeletePostFileByIdMutation()
  const [deleteSectionFromBackend] = useDeletePostSectionByIdMutation()
  const [updatePostAttachmentSection] = useUpdatePostAttachmentSectionMutation()
  const [createPostAttachmentSection] = useCreatePostAttachmentSectionMutation()

  const { postId } = useParams<{ postId: string }>()
  const postIdNumber = postId ? parseInt(postId, 10) : 0

  const { data: post, isLoading } = useGetPostByIdQuery(postIdNumber)
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  const { data: attachmentSectionResponse } = useFetchPostAttachmentSectionsQuery(postIdNumber)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      title: '',
      category: [],
      description: '',
      content: '',
      welcomeMessage: ADDRESSING_CONTENT_DEAFULT_TEXT,
    },
  })

  const theme = useTheme();
  
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const formData = new FormData()
    formData.append('category', JSON.stringify(data.category))
    formData.append('title', data.title)
    formData.append('short_description', data.description)
    formData.append('description', data.content)
    formData.append('post_to_landing_page', JSON.stringify(publishRef.current))
    formData.append('preview', JSON.stringify(previewRef.current))
    formData.append('welcome_message', data.welcomeMessage)

    if (publishRef.current) {
      // publish
      formData.append('post_status', POST_STATUS[2].value) // completed
    } else {
      // draft
      formData.append('post_status', postStatusRef.current)
    }

    dispatch(startPageLoader())

    updatePostById({ postId: postIdNumber, data: formData })
      .unwrap()
      .then(() => {
        const promises: Promise<any>[] = []
        attachmentSections.forEach((section) => {
          promises.push(
            new Promise((resolve, reject) => {
              const data = new FormData()
              data.append('postId', postIdNumber.toString())
              data.append('title', section.title)
              data.append('description', section.description)
              section.pdfs.forEach((file) => {
                data.append('files', file)
              })
              section.images.forEach((file) => {
                data.append('files', file)
              })
              if (section.sectionId) {
                updatePostAttachmentSection({
                  sectionId: section.sectionId || '',
                  data,
                })
                  .unwrap()
                  .then((res) => resolve(res))
                  .catch((err) => reject(err))
              } else {
                createPostAttachmentSection(data)
                  .unwrap()
                  .then((res) => resolve(res))
                  .catch((err) => reject(err))
              }
            }),
          )
        })
        Promise.all(promises)
          .catch((err) => {
            errorToast(err?.data?.error || 'Something went wrong')
          })
          .finally(async () => {
            filestoBeDeletedFromBackend.forEach(async (fileId) => {
              const payload = {
                postId: postIdNumber,
                fileId: fileId,
              }

              await deletePostFile(payload)
            })

            sectionsToBeDeletedFromBackend.forEach(async (id) => {
              await deleteSectionFromBackend(id)
            })

            let msg = 'Post updated successfully.'
            if (previewRef.current === true) {
              // preview
              msg = 'Email preview sent successfully.'
            } else if (publishRef.current) {
              // published
              msg = 'Post published successfully.'
            } else {
              // save post
              msg = 'Post saved successfully.'
            }
            successToast(msg)
            dispatch(stopPageLoader())
            if (previewRef.current === true) {
              setDisablePublishButton(false)
            } else {
              navigate(-1)
            }
          })
      })
      .catch((err: any) => {
        errorToast(err?.data?.error || 'Something went wrong')
        dispatch(stopPageLoader())
      })
    // .finally(() => {
    //   dispatch(stopPageLoader())
    // })
  }

  const handleAddSection = () => {
    setAttachmentSections((prevState) => [
      ...prevState,
      {
        title: '',
        description: '',
        pdfs: [],
        images: [],
        existingPdfs: [],
        existingImages: [],
      },
    ])
  }

  const handleRemoveSection = (sectionIndex: number) => {
    const sectionId = attachmentSections?.[sectionIndex]?.sectionId || ''
    if (sectionId) {
      setSectionsToBeDeletedFromBackend((toBeDeleted) => [...toBeDeleted, sectionId])
    }
    setAttachmentSections((prevState) => prevState.filter((section, i) => i !== sectionIndex))
  }

  const handleAttachmentSectionInput = (
    event: any,
    sectionIndex: number,
    type: 'title' | 'description',
  ) => {
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = event?.target?.value
    setAttachmentSections(newState)
  }

  const handleAddFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    sectionIndex: number,
    type: 'pdfs' | 'images',
  ) => {
    console.log('setAttachmentSections')

    if (event?.target?.files?.[0]) {
      const newState = [...attachmentSections]
      newState[sectionIndex][type] = [...newState[sectionIndex][type], event.target.files[0]]
      console.log(JSON.stringify(newState))
      setAttachmentSections(newState)
    }
  }

  const handleRemoveFile = (sectionIndex: number, index: number, type: 'pdfs' | 'images') => {
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = newState[sectionIndex][type].filter((file, i) => i !== index)
    setAttachmentSections(newState)
  }

  const handleFileDeleteFromBackend = (
    sectionIndex: number,
    id: number,
    type: 'existingPdfs' | 'existingImages',
  ) => {
    setFilestoBeDeletedFromBackend((toBeDeletedPdfFromBackend) => [
      ...toBeDeletedPdfFromBackend,
      id,
    ])
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = newState[sectionIndex][type].filter((file) => file.id !== id)
    setAttachmentSections(newState)
  }

  const handlePreview = () => {
    publishRef.current = false
    previewRef.current = true
    formRef.current?.requestSubmit()
  }

  const handleSave = (postStatus: string) => {
    publishRef.current = false
    previewRef.current = false
    postStatusRef.current = postStatus as PostStatusI
    formRef.current?.requestSubmit()
  }

  const handlePublish = () => {
    publishRef.current = true
    previewRef.current = false
    formRef.current?.requestSubmit()
  }

  useEffect(() => {
    if (post) {
      setValue('title', post.title)
      if (post && post.category) {
        setValue('category', post.category)
      }
      setValue('description', post.short_description)
      setValue('content', post.description)
      if(post.welcome_message){
        setValue('welcomeMessage', post.welcome_message)
      }
      setActivePostStatusIndex(() => {
        for (let i = 0; i < POST_STATUS.length; i++) {
          if (POST_STATUS[i].value === post.post_status) {
            postStatusRef.current = post.post_status
            return i
          }
        }
        return 0
      })
    }
  }, [post, setValue])

  useEffect(() => {
    if (attachmentSectionResponse?.length) {
      const data: IAttachmentSection[] = attachmentSectionResponse.map((section) => {
        return {
          title: section.title,
          description: section.description,
          existingPdfs: section.pdf,
          existingImages: section.img,
          pdfs: [],
          images: [],
          sectionId: section.section,
        }
      })
      console.log('data', data)

      setAttachmentSections(data)
    }
  }, [attachmentSectionResponse])

  useEffect(() => {
    if (isLoading) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isLoading])

  if (!post) return null

  return (
    <Box className={styles.pageWrapper}>
      <Box className={styles.headerWrapper}>
        <Typography variant='h1' color='white'>
          Specific Post
        </Typography>
        <Box className={styles.actionBtnWrapper}>
          <Button
            onClick={handlePublish}
            variant='contained'
            // sx={{
            //   width: '238px',
            //   marginRight: '10px',
            // }}
            sx={{
              width: '238px', // default width
              marginRight: '10px', // default marginRight
              [theme.breakpoints.down('sm')]: {
                width: '100%', // width for screens smaller than 'sm' breakpoint
                marginRight: 0, // remove marginRight for smaller screens if necessary
              },
            }}
            color='error'
            disabled={disablePublishButton}
          >
            Publish
          </Button>

          <Button
            onClick={handlePreview}
            variant='outlined'
            // sx={{
            //   width: '238px',
            //   marginRight: '10px',
            // }}
            sx={{
              width: '238px', // default width
              marginRight: '10px', // default marginRight
              [theme.breakpoints.down('sm')]: {
                width: '100%', // width for screens smaller than 'sm' breakpoint
                marginRight: 0, // remove marginRight for smaller screens if necessary
              },
            }}
          >
            Email Preview
          </Button>

          <GroupButton
            onClick={(selected) => handleSave(selected.value)}
            options={POST_STATUS}
            label='Select Draft Status'
            activeIndex={activePostStatusIndex}
            variant='outlined'
          />
        </Box>
      </Box>
      <Box className={styles.postWrapper} mt={3}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Box>
            <Box mt={2}>
              <Controller
                name='title'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      label='Title'
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type='text'
                      error={!!errors.title}
                    />

                    {!!errors.title && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.title.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                name='category'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <MultiSelectDropdown
                      label={'Category'}
                      id={field.name}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      options={METAL_CATEGORIES.map(({ id, category }) => ({
                        label: category,
                        value: id,
                      }))}
                      // placeholder='Select category'
                      error={!!errors.category}
                    />

                    {!!errors.category && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.category.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
            <Controller
              name='welcomeMessage'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    label={ADDRESSING_CONTENT}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    type='text'
                    error={!!errors.title}
                  />
                  {!!errors.welcomeMessage && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.welcomeMessage.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

            <Box mt={2}>
              <Controller
                name='description'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      label='Description'
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type='text'
                      error={!!errors.description}
                      multiline
                      rows={4}
                    />
                    {!!errors.description && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.description.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
          </Box>

          <Box mt={2}>
            <Controller
              name='content'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <RichTextEditor
                    data={field.value}
                    onChange={(data: string) => {
                      setValue('content', data)
                    }}
                    hideTitle={true}
                  />

                  {!!errors.content && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.content.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

          <Box mt={2}>
            <Typography variant='h3'>Attachments</Typography>
            <Box mt={2}>
              {attachmentSections.map((section, sectionIndex) => (
                <Box
                  key={'attachmentSections' + sectionIndex}
                  mt={2}
                  className={styles.attachmentSectionWrapper}
                >
                  {sectionIndex > 0 && (
                    <Box className={styles.closeIconWrapper}>
                      <IconButton color='error' onClick={() => handleRemoveSection(sectionIndex)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                  <Box>
                    <InputField
                      label='Title'
                      name={`attachmentSection-${sectionIndex}-title`}
                      value={section.title}
                      onChange={(e: any) => handleAttachmentSectionInput(e, sectionIndex, 'title')}
                      type='text'
                      placeholder={`Section ${sectionIndex + 1} Title`}
                    />
                  </Box>
                  <Box mt={2}>
                    <InputField
                      label='Description'
                      name={`attachmentSection-${sectionIndex}-description`}
                      value={section.description}
                      onChange={(e: any) =>
                        handleAttachmentSectionInput(e, sectionIndex, 'description')
                      }
                      type='text'
                      placeholder={`Section ${sectionIndex + 1} Description`}
                      multiline
                      rows={3}
                    />
                  </Box>
                  <Box>
                    <Box mt={2}>
                      <Box>
                        <>
                          <PdfUpload
                            value={section.pdfs}
                            label='Add PDFs'
                            name={'files'}
                            onChange={(e) => handleAddFile(e, sectionIndex, 'pdfs')}
                          />
                        </>
                      </Box>
                      <Grid container justifyContent='center'>
                        {section.pdfs &&
                          section.pdfs.map((file, index) => {
                            return (
                              <PdfPreview
                                key={`PDF_new_${sectionIndex}_${index}`}
                                file={file}
                                canDelete={true}
                                onDelete={() => handleRemoveFile(sectionIndex, index, 'pdfs')}
                              />
                            )
                          })}

                        {section.existingPdfs &&
                          section.existingPdfs.map((file, index) => {
                            if (!file.file_url) return null
                            return (
                              <PdfPreview
                                key={`PDF_existing_${sectionIndex}_${index}`}
                                url={file.file_url}
                                canDelete={true}
                                onDelete={() =>
                                  handleFileDeleteFromBackend(sectionIndex, file.id, 'existingPdfs')
                                }
                              />
                            )
                          })}
                      </Grid>
                    </Box>

                    <Box mt={2}>
                      <Box>
                        <>
                          <ImageUpload
                            value={section.images}
                            label='Add Images'
                            name={'images'}
                            onChange={(e) => handleAddFile(e, sectionIndex, 'images')}
                          />
                        </>
                      </Box>
                      <Grid container justifyContent='center'>
                        {section.images &&
                          section.images.map((file, index) => {
                            return (
                              <ImagePreview
                                key={`IMAGE_new_${sectionIndex}_${index}`}
                                file={file}
                                canDelete={true}
                                onDelete={() => handleRemoveFile(sectionIndex, index, 'images')}
                              />
                            )
                          })}

                        {section.existingImages &&
                          section.existingImages.map((file, index) => {
                            if (!file.file_url) return null

                            return (
                              <ImagePreview
                                key={`IMAGE_existing_${sectionIndex}_${index}`}
                                url={file.file_url}
                                canDelete={true}
                                onDelete={() =>
                                  handleFileDeleteFromBackend(
                                    sectionIndex,
                                    file.id,
                                    'existingImages',
                                  )
                                }
                              />
                            )
                          })}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box mt={2}>
              <Grid container justifyContent='end'>
                <Button variant='outlined' onClick={handleAddSection}>
                  <AddIcon /> Add Section
                </Button>
              </Grid>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default EditorEditPost
