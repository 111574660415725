/* eslint-disable camelcase */
import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styles from '../index.module.scss'

const TrialInfo = () => {
  return (
    <Grid container alignItems='center' height='100%' className={styles.trialSubPlanContainer}>
      <Grid xs={12} sm={12} md={2}></Grid>
      <Grid xs={12} sm={12} md={8}>
        <Box
          sx={{
            background: 'white',
            padding: '30px',
            textAlign: 'center',
          }}
        >
          <Typography variant='h1'>Sign up for a free trial. </Typography>
          <Typography variant='body2' mt={1}>
            Start receiving daily coverage right away.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TrialInfo
