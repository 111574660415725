/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { PageResponse, PaginationPayload } from 'src/api/types'
import { API_BASE_PATH } from 'src/utils/constants'
import { PostI, PostFile, BookmarkPayload, HistoryPostI, FetchPostFilesResponse } from './types'

const userPostApi = createApi({
  reducerPath: 'userPostApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['userPostApi'],
  endpoints: (builder) => ({
    fetchUserPost: builder.query<PageResponse<PostI[]>, PaginationPayload>({
      query: ({
        pageNumber,
        pageSize = 10,
        searchBy = '',
        category = '',
        sortBy = '',
        sortDir = '',
        date = '',
      }) => ({
        url: `/api/v1/post-data/?page_number=${pageNumber}&post_count=${pageSize}&searchBy=${searchBy}&category=${category}&sortBy=${sortBy}&sortDir=${sortDir}${
          date ? '&date=' + date : ''
        }`,
        method: 'GET',
      }),
    }),
    getPostById: builder.query<PostI, number>({
      query: (postId) => ({
        url: `/api/v1/post-data/${postId}/`,
        method: 'GET',
      }),
      transformResponse: (response: PostI[]) => {
        return response[0]
      },
    }),
    fetchPostRelatedFiles: builder.query<FetchPostFilesResponse, number>({
      query: (postId) => ({
        url: `/api/v1/post-related-files/${postId}/`,
        method: 'GET',
      }),
    }),
    setBookmark: builder.mutation<void, BookmarkPayload>({
      query: (payload) => ({
        url: '/api/v1/post-history/',
        method: 'POST',
        data: payload,
      }),
    }),

    fetchUserBookmarkedPost: builder.query<PageResponse<HistoryPostI[]>, PaginationPayload>({
      query: ({
        pageNumber,
        pageSize = 10,
        searchBy = '',
        category = '',
        sortBy = '',
        sortDir = '',
        date = '',
      }) => ({
        url: `/api/v1/post-history/?page_number=${pageNumber}&post_count=${pageSize}&searchBy=${searchBy}&category=${category}&sortBy=${sortBy}&sortDir=${sortDir}${
          date ? '&date=' + date : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLazyFetchUserPostQuery,
  useGetPostByIdQuery,
  useFetchPostRelatedFilesQuery,
  useSetBookmarkMutation,
  useLazyFetchUserBookmarkedPostQuery,
} = userPostApi
export default userPostApi
