import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { Box, CssBaseline } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { routeUrls } from 'src/utils/constants'
import PageLoader from '../PageLoader'
import Header from './Header'
import styles from './index.module.scss'
import FreeTrialStartPopUp from 'src/Component/FreeTrialStartPopUp'
import TrialAndSubscriptionEndPopUp from 'src/Component/TrialAndSubscriptionEndPopUp'
const UserHomeLayout = () => {
  const { isAuthenticated, userInfo, token, role } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  if (isAuthenticated === false || !userInfo || !token) {
    return <Navigate to={routeUrls.login} state={{ from: location }} />
  }

  if (role === 'admin') {
    return <Navigate to={routeUrls.admin.home} state={{ from: location }} />
  }

  if (role === 'editor') {
    return <Navigate to={routeUrls.editor.home} state={{ from: location }} />
  }


  return (
    <Box>
      <Header />
      <CssBaseline />

      <Box className={styles.wrapperPadding} pt={0}>
        <Outlet />
      </Box>

      <PageLoader />

      <FreeTrialStartPopUp />
      <TrialAndSubscriptionEndPopUp/>
    </Box>
  )
}

export default UserHomeLayout
