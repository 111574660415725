import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_BASE_PATH } from 'src/utils/constants'

type sendVerificationLinkPayload = {
  email: string
}

export const sendVerificationLink = createAsyncThunk(
  'forgotpassword/send-verification-link',
  async (payload: sendVerificationLinkPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(`${API_BASE_PATH}/api/v1/reset-password/`, payload, config)
      return response.data
    } catch (error: any) {
      console.log('error', error)

      // return custom error message from backend if present
      if (!error?.response?.data) return rejectWithValue('Opps something went wrong.')

      if (error.response.data?.email?.[0]) {
        return rejectWithValue(error.response.data.email.join(','))
      }

      if (error.response.data.error) {
        return rejectWithValue(error.response.data.error)
      }

      if (error.response.data.error_message) {
        return rejectWithValue(error.response.data.error_message)
      }

      if (error.response.data?.message) {
        return rejectWithValue(error.response.data.message)
      }

      return rejectWithValue('Opps something went wrong.')
    }
  },
)


type resetPasswordPayload = {
  password: string
  token: string
}

export const resetPasswordpApi = createAsyncThunk(
  'forgotpassword/reset-password',
  async (payload: resetPasswordPayload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(
        `${API_BASE_PATH}/api/v1/reset-password/confirm/`,
        {
          token: payload.token,
          password: payload.password,
        },
        config,
      )
      return response.data
    } catch (error: any) {
      // return custom error message from backend if present
      return rejectWithValue('Link has expired or Invalid.')

    }
  },
)
