/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { MyProfileInfoI, MyReportI, MyReportPayload } from './types'
import { PageResponse, PaginationPayload } from 'src/api/types'

const editorSettingsApi = createApi({
  reducerPath: 'editorSettingsApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['editorSettingsApi'],
  endpoints: (builder) => ({
    fetchMyProfileInfo: builder.query<MyProfileInfoI, void>({
      query: () => ({
        url: '/api/v1/editorProfile/',
        method: 'GET',
      }),
      transformResponse: (response: MyProfileInfoI[]) => {
        return response[0]
      },
    }),
    updateMyProfileInfo: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: '/api/v1/editorProfile/0/',
        method: 'PUT',
        data: payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),

    fetchMyReport: builder.query<PageResponse<MyReportI>, MyReportPayload & PaginationPayload>({
      query: ({
        draft,
        pageNumber,
        pageSize = 10,
        searchBy = '',
        category = '',
        sortBy = '',
        sortDir = '',
        date = '',
      }) => ({
        url: `/api/v1/myReport/?draft=${draft}&page_number=${pageNumber}&post_count=${pageSize}&searchBy=${searchBy}&category=${category}&sortBy=${sortBy}&sortDir=${sortDir}${
          date ? '&date=' + date : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useFetchMyProfileInfoQuery,
  useUpdateMyProfileInfoMutation,
  useLazyFetchMyReportQuery,
} = editorSettingsApi
export default editorSettingsApi
