import React from 'react'
import './render.scss'
type RenderContentProps = {
  content: string
}
const RenderContent = ({ content }: RenderContentProps) => {
  return (
    <div className='editor-content'>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>
  )
}

export default RenderContent
