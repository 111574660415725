/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { PageResponse, PaginationPayload } from 'src/api/types'
import { API_BASE_PATH } from 'src/utils/constants'
import {
  SubscribedUsersData,
  UsersData,
  EditorUsersData,
  UserCreationPayload,
  DeleteUserPayload,
  FlagUserPayload,
} from './types'

const userManagementApi = createApi({
  reducerPath: 'userManagementApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['userManagementApi'],
  endpoints: (builder) => ({
    fetchRegularUsers: builder.query<PageResponse<UsersData[]>, PaginationPayload>({
      query: ({ pageNumber, pageSize = 10, searchBy }) => ({
        url: `/api/v1/regularUsersList/?page_number=${pageNumber}&count=${pageSize}&searchBy=${searchBy}`,
        method: 'GET',
      }),
    }),
    fetchSubscribedUsers: builder.query<PageResponse<SubscribedUsersData[]>, PaginationPayload>({
      query: ({ pageNumber, pageSize = 10, searchBy }) => ({
        url: `/api/v1/subscribedUsersList/?page_number=${pageNumber}&count=${pageSize}&searchBy=${searchBy}`,
        method: 'GET',
      }),
    }),
    fetchEditorUsers: builder.query<PageResponse<EditorUsersData[]>, PaginationPayload>({
      query: ({ pageNumber, pageSize = 10, searchBy }) => ({
        url: `/api/v1/editor-list/?page_number=${pageNumber}&count=${pageSize}&searchBy=${searchBy}`,
        method: 'GET',
      }),
    }),
    createUser: builder.mutation<void, UserCreationPayload>({
      query: (payload) => ({
        url: '/api/v1/createUser/',
        method: 'POST',
        data: payload,
      }),
    }),
    deleteUser: builder.mutation<void, DeleteUserPayload>({
      query: (payload) => ({
        url: '/api/v1/deleteUser/',
        method: 'POST',
        data: payload,
      }),
    }),
    flagUser: builder.mutation<void, FlagUserPayload>({
      query: (payload) => ({
        url: '/api/v1/activateAccount/',
        method: 'POST',
        data: payload,
      }),
    }),
  }),
})

export const {
  useFetchRegularUsersQuery,
  useFetchSubscribedUsersQuery,
  useFetchEditorUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useFlagUserMutation,
} = userManagementApi
export default userManagementApi
