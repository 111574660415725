import { createSlice } from '@reduxjs/toolkit'
import { getTermAndConditionContent, getPrivacyPolicyContent } from './staicPageContentAction'

type InitialStateType = {
  loading: boolean
  termAndConditionData: string
  privacyPolicyData: string
}

const initialState: InitialStateType = {
  loading: false,
  termAndConditionData: '',
  privacyPolicyData: '',
}

const staticPageContentSlice = createSlice({
  name: 'staticPageContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTermAndConditionContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getTermAndConditionContent.fulfilled, (state, action) => {
        state.loading = false
        state.termAndConditionData = action?.payload?.[0]?.body || ''
      })
      .addCase(getTermAndConditionContent.rejected, (state) => {
        state.loading = false
      })

      .addCase(getPrivacyPolicyContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getPrivacyPolicyContent.fulfilled, (state, action) => {
        state.loading = false
        state.privacyPolicyData = action?.payload?.[0]?.body || ''
      })
      .addCase(getPrivacyPolicyContent.rejected, (state) => {
        state.loading = false
      })
  },
})

export default staticPageContentSlice.reducer
