import React, { useState } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDeleteUserMutation } from 'src/api/admin/userManagement'
import { errorToast, successToast } from 'src/utils/toastService'
import DeleteEditorUser from './DeleteEditorUser'
import { EditorUsersData } from 'src/api/admin/userManagement/types'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(248, 249, 252, 1)',
  },
}))
type DataRowProps = {
  align?: 'right'
  data: EditorUsersData
  onDelete: (email: string) => void
}
const DataRow = ({ align, data, onDelete }: DataRowProps) => {
  return (
    <StyledTableRow hover role='checkbox' tabIndex={-1}>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.firstname}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.lastname}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.email}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.username}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.ph_number}
        </Typography>
      </TableCell>
      {/* <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.metal_category}
        </Typography>
      </TableCell> */}

      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Grid onClick={() => onDelete(data.email)}>
          <Typography variant='h6' fontWeight={400} color='#00539D' className='cursor'>
            Delete
          </Typography>
        </Grid>
      </TableCell>
    </StyledTableRow>
  )
}

export default DataRow
