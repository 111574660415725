/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import {
  useFetchMyProfileInfoQuery,
  useUpdateMyProfileInfoMutation,
} from 'src/api/user/settingsApi'
import { useDispatch } from 'react-redux'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { AppDispatch } from 'src/store'
import { errorToast, successToast } from 'src/utils/toastService'
import SimpleModal from 'src/Component/SimpleModal'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  organization: Yup.string().required('Organization is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  username: Yup.string().required('Username is required'),
})

const AccountInformation = () => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  })

  const [isEditable, setIsEditable] = useState(false)
  const {
    data,
    isFetching: isFetchingProfile,
    refetch: refetchMyProfileInfo,
  } = useFetchMyProfileInfoQuery()
  const dispatch = useDispatch<AppDispatch>()
  const [updateProfileApi, { isLoading: isUpdatingProfile }] = useUpdateMyProfileInfoMutation()

  const enableEditMode = () => setIsEditable(true)
  const disableEditMode = () => {
    if (data) {
      setValue('email', data.email || '')
      setValue('username', data.username || '')
      setValue('firstName', data.firstname || '')
      setValue('lastName', data.lastname || '')
      setValue('organization', data.organization || '')
      clearErrors()
    }
    setIsEditable(false)
  }

  type IFormInput = {
    firstName: string
    lastName: string
    organization: string
    email: string
    username: string
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    updateProfileApi({
      username: data.username,
      email: data.email,

      firstname: data.firstName,
      lastname: data.lastName,
      organization: data.organization,
    })
      .unwrap()
      .then((res) => {
        setConfirmationOpen(false)
        successToast('Account information saved successfully.')
        disableEditMode()
        refetchMyProfileInfo()
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Ops, Something went wrong.')
      })
  }

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false)
    disableEditMode()
  }

  useEffect(() => {
    if (isFetchingProfile || isUpdatingProfile) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isFetchingProfile, isUpdatingProfile])

  useEffect(() => {
    console.log(data)
    if (data) {
      setValue('email', data.email || '')
      setValue('username', data.username || '')
      setValue('firstName', data.firstname || '')
      setValue('lastName', data.lastname || '')
      setValue('organization', data.organization || '')
    }
  }, [data])
  return (
    <Box>
      <Grid container justifyContent='space-between' alignItems='center' pb={2}>
        <Grid item>
          <Typography variant='h3'>Account</Typography>
        </Grid>

        <Grid item>
          {isEditable === false ? (
            <Button
              variant='outlined'
              sx={{
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
              onClick={enableEditMode}
            >
              Edit
            </Button>
          ) : (
            <Box>
              <Button
                variant='outlined'
                sx={{
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginRight: '20px',
                }}
                onClick={disableEditMode}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                sx={{
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setConfirmationOpen(true)}
              >
                Save
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box>
        <form>
          <Box>
            <Box>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.firstName}
                      label='First Name'
                      type='text'
                      placeholder='Enter first name'
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.firstName && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.firstName.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                name='lastName'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.lastName}
                      label='Last Name'
                      type='text'
                      placeholder='Enter last name'
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.lastName && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.lastName.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>

            <Box mt={2}>
              <Controller
                name='organization'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.organization}
                      label='Organization'
                      type='text'
                      placeholder='Enter your organization name'
                      disabled={!isEditable}
                      required
                    />
                    {!!errors.organization && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.organization.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='email'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      label='Email address'
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      type='text'
                      placeholder='Enter your email'
                      error={!!errors.email}
                      required
                      disabled={!isEditable}
                    />
                    {!!errors.email && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.email.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
            <Box mt={2}>
              <Controller
                name='username'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.username}
                      label='Username'
                      type='text'
                      placeholder='Enter username'
                      required
                      disabled={!isEditable}
                      regex={/\s/g}
                    />
                    {!!errors.username && (
                      <Typography variant='subtitle2' color='red'>
                        {errors.username.message}
                      </Typography>
                    )}
                  </>
                )}
              />
            </Box>
          </Box>
        </form>
      </Box>

      <Box>
        <SimpleModal
          open={confirmationOpen}
          onClose={handleConfirmationCancel}
          title='Profile'
          leftButton={{
            label: 'Cancel',
            onClick: handleConfirmationCancel,
          }}
          rightButton={{
            label: 'Yes, Change',
            onClick: handleSubmit(onSubmit),
          }}
        >
          <Typography variant='h5' textAlign='center'>
            Are you sure you want to update profile?{' '}
          </Typography>
        </SimpleModal>
      </Box>
    </Box>
  )
}

export default AccountInformation
