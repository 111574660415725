import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useTheme } from '@mui/material/styles';

type OptionI = {
  value: string
  label: string
}

type GroupButtonProps = {
  options: OptionI[]
  label: string
  onClick: (seleted: OptionI) => void
  activeIndex?: number
  variant?: 'outlined' | 'contained'
  marginRight?: string
}

export default function GroupButton({
  options,
  label,
  onClick,
  activeIndex = 0,
  variant = 'contained',
  marginRight = '0px',
}: GroupButtonProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(activeIndex)

  const theme = useTheme();
  
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`)
    onClick(options[selectedIndex])
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  React.useEffect(() => {
    setSelectedIndex(activeIndex)
  }, [activeIndex])
  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef} aria-label='split button' sx={{ width: '100%',[theme.breakpoints.up('sm')]: {width: 250,}, marginRight }}>
        <Button
          title='Select status from dropdown and then click on save'
          type='button'
          variant={variant}
          color='primary'
          fullWidth
          onClick={handleClick}
          sx={{ textAlign: 'center' }}
        >
          {`Save ( ${options[selectedIndex].label} )`}
        </Button>
        <Button
          type='button'
          variant={variant}
          color={variant === 'contained' ? 'secondary' : 'primary'}
          size='small'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label={label}
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          width: 250,
          marginRight,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.value}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}
