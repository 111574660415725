import React, { useState } from 'react'
import { Box, Grid, Typography, Chip, Button, useMediaQuery } from '@mui/material'

import styles from './index.module.scss'

import calendarIcon from 'src/assets/icons/calendar.png'
import dailyIcon from 'src/assets/icons/daily.png'
import manualIcon from 'src/assets/icons/manual.png'
import rightArrowIcon from 'src/assets/icons/rightArrow.png'
import dayjs from 'dayjs'
import { Link, useNavigate } from 'react-router-dom'
import { localStorageKeys, routeUrls } from 'src/utils/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import SimplePopUp from '../SimplePopUp'
import { PostStatusI } from 'src/api/admin/postApi/types'
import SimpleModal from '../SimpleModal'

type PostPreviewProps = {
  postId: number
  title: string
  originalNews: string
  date: string
  isManual: boolean
  isAuto: boolean
  category?: any
  categorySelected?: string
  onClickCategoryChip?: (id: string) => void
  description: string
  showEditButton?: boolean
  showRemoveButton?: boolean
  isPublished?: boolean
  isDraft?: boolean
  postStatus?: PostStatusI
  onDelete?: (postId: number) => void
  hideReadMore?: boolean
  editButtonText?: string
}
const PostPreview = ({
  postId,
  title,
  date,
  isManual,
  isAuto,
  originalNews,
  category,
  categorySelected,
  onClickCategoryChip,
  description,
  showEditButton = false,
  showRemoveButton = false,
  isPublished = false,
  isDraft = false,
  postStatus,
  onDelete,
  hideReadMore = false,
  editButtonText = 'Edit',
}: PostPreviewProps) => {
  console.log('category..', categorySelected)
  const navigate = useNavigate()

  const { role, hasFullAccess } = useSelector((state: RootState) => {
    return state.auth
  })
  const [open, setOpen] = useState(false)
  const [showNews, setShowNews] = useState(false)
  const userType: string | null = localStorage?.getItem(localStorageKeys?.role)
  console.log('userType:', userType)
  const handleNews = () => {
    window.open(`/admin/news-page/${postId}`,'_blank')
  }
  console.log('category', category)
  const handleClose = () => {
    setShowNews(false)
  }

  const navigateToEditPage = () => {
    if (role === 'admin') {
      navigate(routeUrls.admin.editPost.replace(':postId', postId.toString()))
    } else if (role === 'editor') {
      navigate(routeUrls.editor.editPost.replace(':postId', postId.toString()))
    }
  }

  const navigateToViewPostPage = () => {
    if (role === 'admin') {
      navigate(routeUrls.admin.viewPost.replace(':postId', postId.toString()), {
        state: { categorySelected: categorySelected },
      })
    } else if (role === 'editor') {
      navigate(routeUrls.editor.viewpost.replace(':postId', postId.toString()), {
        state: { categorySelected: categorySelected },
      })
    } else if (role === 'regular_user') {
      if (hasFullAccess) {
        navigate(routeUrls.user.viewpost.replace(':postId', postId.toString()), {
          state: { categorySelected: categorySelected },
        })
      } else {
        setOpen(true)
      }
    }
  }

  const handleRemoveClick = () => {
    if (onDelete) onDelete(postId)
  }

  const trimmedDescription =
    description.length < 500 ? description : description.substring(0, 500) + '...'
  return (
    <Box className={`cardWrapper ${styles.container}`}>
      <Grid container justifyContent='end' mb={isPublished || isDraft ? 1 : 0}>
        {isPublished && <Chip label='Published' color='primary' />}
        {isDraft && <Chip label='Draft' />}

        {isDraft && postStatus && (
          <Chip
            label={(() => {
              if (postStatus === 'pending') return 'Pending'
              else if (postStatus === 'in_progress') return 'In Progress'
              else if (postStatus === 'completed') return 'Completed'
            })()}
            color='warning'
            sx={{ marginLeft: 1 }}
          />
        )}
      </Grid>
      <Grid
        container
        justifyContent='space-between'
        alignItems='baseline'
        className={styles.titleContainer}
      >
        <Grid item xs={9} className={styles.titlePadding}>
          <Typography
            variant='h2'
            color='black'
            onClick={navigateToViewPostPage}
            className={`cursor ${styles.titleFont}`}
          >
            {title}
          </Typography>
          {!isManual && userType !== 'regular_user' ? (
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent='end'
              className={styles.news}
              marginRight={-34}
              // marginTop={0}
              marginBottom={8}
            >
              <Button onClick={handleNews}>View News From Source</Button>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          item
          xs={3}
          display='flex'
          alignItems='center'
          justifyContent='end'
          className={styles.dateContainer}
        >
          <img src={calendarIcon} /> &nbsp;
          <span> {dayjs(date).format('MMM DD, YYYY')} </span>
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1}>
        {isManual && (
          <Grid item>
            <Chip
              label={
                <Grid display='flex' alignItems='center' justifyContent='center'>
                  <img src={manualIcon} /> <span> &nbsp; Thematic</span>
                </Grid>
              }
              color='warning'
            />
          </Grid>
        )}

        {isAuto && (
          <Grid item>
            <Chip
              label={
                <Grid display='flex' alignItems='center' justifyContent='center'>
                  <img src={dailyIcon} /> <span> &nbsp; Daily</span>
                </Grid>
              }
              color='success'
            />
          </Grid>
        )}

        {category
          ? category?.length > 0 &&
            category?.map((item: any) => (
              <Grid
                item
                key={item?.id}
                onClick={() => {
                  if (onClickCategoryChip) {
                    onClickCategoryChip(item?.id)
                  }
                }}
              >
                {item?.category ? (
                  <Chip
                    label={item?.category}
                    className={`${item?.id === categorySelected ? styles.chipContainer : ''}`}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  ''
                )}
              </Grid>
            ))
          : null}
      </Grid>

      <Box pt={5} pb={5}>
        <Typography variant='h4' color='rgba(62, 62, 62, 1)'>
          {trimmedDescription}
        </Typography>

        {hideReadMore === false && (
          <Grid container justifyContent='end' alignItems='center' pt={2}>
            <Typography
              onClick={navigateToViewPostPage}
              className='cursor'
              variant='h4'
              color='rgba(56, 59, 122, 1)'
            >
              Read More &nbsp;
            </Typography>

            <img src={rightArrowIcon} />
          </Grid>
        )}
      </Box>
      <Grid container justifyContent='end'>
        {showRemoveButton && (
          <Grid item pr={3}>
            <Button
              className={styles.removeBtn}
              type='button'
              variant='outlined'
              color='primary'
              onClick={handleRemoveClick}
            >
              Remove
            </Button>
          </Grid>
        )}
        {showEditButton && (
          <Grid item>
            <Button
              className={styles.editBtn}
              type='submit'
              variant='contained'
              color='primary'
              onClick={navigateToEditPage}
            >
              {editButtonText}
            </Button>
          </Grid>
        )}
      </Grid>

      <SimplePopUp
        isOpen={open}
        title='Your subscription has expired.'
        description='To continue using and access more features, please purchase the full version.'
      />
      {showNews ? (
        <SimpleModal
          open={showNews}
          onClose={handleClose}
          title={'Aylien News'}
          leftButton={{
            label: '',
            onClick: handleClose,
          }}
          rightButton={{
            label: 'Close',
            onClick: handleClose,
          }}
        >
          <Typography variant='h5'>
            {/* {originalNews?.length ? originalNews : 'We are waiting for the latest news!'} */}
            {originalNews?.length ? (
              <div className='external-html' dangerouslySetInnerHTML={{ __html: originalNews }} />
            ) : (
              'We are waiting for the latest news!'
            )}
          </Typography>
        </SimpleModal>
      ) : null}
    </Box>
  )
}

export default PostPreview
