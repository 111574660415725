import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import SimpleModal from '../SimpleModal'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { logoutUser } from 'src/store/features/auth/authActions'

type LogoutPopUpProps = {
  onClose?: () => void
}

const LogoutPopUp = ({ onClose }: LogoutPopUpProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpne] = useState(true)

  const handleClose = () => {
    setOpne(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  const handleLogout = () => {
    dispatch(logoutUser())
  }
  return (
    <Box>
      <SimpleModal
        open={open}
        onClose={handleClose}
        title={'Sign out'}
        leftButton={{
          label: 'Cancel',
          onClick: handleClose,
        }}
        rightButton={{
          label: 'Sign out',
          onClick: handleLogout,
        }}
      >
        <Typography variant='h5' textAlign='center'>
          Are you sure you want to Sign out?
        </Typography>
      </SimpleModal>
    </Box>
  )
}

export default LogoutPopUp
