import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, Button, Grid, Typography, IconButton, Box } from '@mui/material'
import closeIcon from 'src/assets/icons/close-icon.png'
import errorIcon from 'src/assets/icons/error.png'

import { routeUrls } from 'src/utils/constants'
import { useNavigate } from 'react-router-dom'

type SimplePopUpProps = {
  isOpen: boolean
  title: string
  description: string
}

const SimplePopUp = ({ isOpen, title, description }: SimplePopUpProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(isOpen)

  const onClose = () => {
    setOpen(false)
  }

  const navigateToSubscriptionPage = () => {
    onClose()
    navigate(routeUrls.user.settings + `?tab=${routeUrls.user.settingTabs.subscription}`)
  }

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container justifyContent='space-evenly'>
          <Grid item xs={1} textAlign='left'>
            <img src={errorIcon} />
          </Grid>
          <Grid item xs={10}>
            <Typography variant='h3' fontWeight='bold' pl={1}>
              {title}
            </Typography>

            <Typography variant='subtitle1' fontWeight='bold' pl={1}>
              {description}
            </Typography>

            <Grid container mt={2}>
              <Grid p={2} xs={12} sm={6} md={6} pt={0}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onClose}
                >
                  Ok
                </Button>
              </Grid>
              <Grid p={2} xs={12} sm={6} md={6} pt={0}>
                <Button
                  fullWidth
                  type='button'
                  variant='contained'
                  color='primary'
                  onClick={navigateToSubscriptionPage}
                >
                  Subscribe
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1} textAlign='right'>
            <IconButton onClick={onClose}>
              <img src={closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    </Dialog>
  )
}

export default SimplePopUp
