import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import styles from '../index.module.scss'
import {
  useFetchSubscriptionPlanQuery,
} from 'src/api/user/subscriptionApi'
import SubscriptionPlan from 'src/Component/SubscriptionPlan'
import { UserSubscriptionI } from 'src/api/user/subscriptionApi/types'
import { TRIAL_SUBSCRIPTION_PLAN } from 'src/utils/constants'

import CancelSubscriptionPopUp from './CancelSubscriptionPopUp'

type PlansProps = {
  onChoosePlan: (id: number, price: number, type: string) => void
  userSubscription?: UserSubscriptionI
  refetchUserCurrentPlan: () => void
}
const Plans = ({ onChoosePlan, userSubscription, refetchUserCurrentPlan }: PlansProps) => {
  const {
    data: subscriptionPlans = [],
    isFetching,
    refetch: refetchPlans,
  } = useFetchSubscriptionPlanQuery()
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState<boolean>(false)

  const [isTrialPeriod, setIsTrialPeriod] = useState(false)
  const [isSubscriptionTaken, setIsSubscriptionTaken] = useState(false)
  const onStopSubscriptoinSuccess = () => {
    refetchUserCurrentPlan()
    refetchPlans()
  }

  const renderActivePlan = () => {
    if (!userSubscription) return null

    if (isSubscriptionTaken) {
      return (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          <SubscriptionPlan
            id={userSubscription.subscription.id}
            isActivePlan
            type={userSubscription.subscription.subscription_type}
            price={userSubscription.subscription.price}
            description={userSubscription.subscription.subscription_body}
            onCancelPlan={() => setOpenCancelSubscriptionModal(true)}
          />
        </Grid>
      )
    }

    if (isTrialPeriod) {
      return (
        <Grid item xs={12} sm={12} md={12} lg={6} p={2}>
          <SubscriptionPlan
            id={TRIAL_SUBSCRIPTION_PLAN.id}
            isActivePlan
            type={TRIAL_SUBSCRIPTION_PLAN.subscription_type}
            price={TRIAL_SUBSCRIPTION_PLAN.price}
            description={TRIAL_SUBSCRIPTION_PLAN.subscription_body}
            hideCancel
          />
        </Grid>
      )
    }

    return null
  }

  useEffect(() => {
    setIsTrialPeriod(!!(userSubscription && userSubscription.trial_completed === false))
    setIsSubscriptionTaken(
      !!(
        userSubscription &&
        userSubscription.subscription &&
        userSubscription.subscription_taken === true
      ),
    )
  }, [userSubscription])
  if (isFetching) {
    return (
      <Grid container justifyContent='center' alignItems='center' height='400px'>
        <CircularProgress />
      </Grid>
    )
  }

  console.log(isTrialPeriod, isSubscriptionTaken)

  return (
    <Box>
      <Typography variant='h3'>Subscriptions</Typography>
      <Typography variant='h1' mt={1}>
        Choose a subscription plan.
      </Typography>

      <Typography variant='h5' mt={2} className={styles.gradientText}>
        So how does the 10-Day free trial work?
      </Typography>
      <Typography variant='subtitle1' component={'p'}>
        You can use Transitional Materials free for up to 10 Days, with full access to everything offered on
        the plan you sign up for. If you don&apos;t cancel within 10 Days, we&apos;ll charge you for
        the plan you selected. <br /> You can upgrade, downgrade or cancel at any time with just a
        few clicks.
      </Typography>

      <Grid container justifyContent='center' alignItems='stretch'>
        {renderActivePlan()}
        {subscriptionPlans.map((plan) => {
          if (
            isSubscriptionTaken &&
            userSubscription?.subscription_id &&
            userSubscription.subscription_id === plan.id
          ) {
            // do not render active plan here
            return null
          }

          return (
            <Grid item xs={12} sm={12} md={12} lg={6} p={2} key={plan.id}>
              <SubscriptionPlan
                id={plan.id}
                isActivePlan={false}
                type={plan.subscription_type}
                price={plan.price}
                description={plan.subscription_body}
                onSelect={() => onChoosePlan(plan.id, plan.price, plan.subscription_type)}
              />
            </Grid>
          )
        })}
      </Grid>

      <CancelSubscriptionPopUp
        open={openCancelSubscriptionModal}
        onClose={() => setOpenCancelSubscriptionModal(false)}
        onSuccessCallback={onStopSubscriptoinSuccess}
      />
    </Box>
  )
}

export default Plans
