import { Box, IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'

type ImagePreviewProps = {
  file?: File
  url?: string
  canDelete?: boolean
  onDelete?: () => void
  onOpenImage?: () => void
  handleDeleteImageClientSide?: () => void
  handleDeleteImageFromBackend?: () => void
}

const ImagePreview = ({
  file,
  url = '',
  canDelete,
  onDelete,
  onOpenImage,
  handleDeleteImageClientSide,
  handleDeleteImageFromBackend,
}: ImagePreviewProps) => {
  const [imageUrl, setImageUrl] = useState<string>(url)

  const handleDelete = (e: any) => {
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
  }

  useEffect(() => {
    if (file) {
      setImageUrl(URL.createObjectURL(file))
    }
  }, [file])

  return (
    <Box m={1}>
      <Box className={styles.container}>
        <img src={imageUrl} alt={file?.name} onClick={onOpenImage} />
        {canDelete === true ? (
          <IconButton aria-label='delete' className={styles.overlayButton} onClick={handleDelete}>
            <ClearIcon />
          </IconButton>
        ) : null}
      </Box>
    </Box>
  )
}

export default ImagePreview
