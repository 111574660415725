import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Button, Typography } from '@mui/material'
import styles from './index.module.scss'
import PostPreview from 'src/Component/PostPreview'
import SearchInput from 'src/Component/SearchInput'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import SortDropdown from 'src/Component/SortDropdown'
import BasicDatePicker from 'src/Component/BasicDatePicker'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'

import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Dayjs } from 'dayjs'
import { useLazyFetchMyReportQuery } from 'src/api/editor/settingsApi'
import { MyReportI } from 'src/api/editor/settingsApi/types'
import ReportTypeTab from './ReportTypeTab'

const sortOptions = [
  {
    value: 'edited_at-desc',
    label: 'Latest',
  },
  {
    value: 'edited_at-asc',
    label: 'Oldest',
  },
  {
    value: 'post__title-asc',
    label: 'Title - Asc',
  },
  {
    value: 'post__title-desc',
    label: 'Title - Desc',
  },
  {
    value: 'post__popularity-desc',
    label: 'Popularity',
  },
]

const MyReports = () => {
  const dispatch = useDispatch<AppDispatch>()
  const pageRef = useRef<number>(1)
  const [reportType, setReportType] = useState<'draft' | 'published'>('draft')
  const [paginatedData, setPaginatedData] = useState<MyReportI[]>([])
  const [search, setSearch] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [date, setDate] = useState<Dayjs | null>(null)
  const [sort, setSort] = useState<any>(sortOptions[0].value)
  const [fetchPost, { data: POST_RESPONSE, isFetching }] = useLazyFetchMyReportQuery()
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value)
  }

  const handleFilter = () => {
    fetchData(true)
  }

  const handleSortDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    setSort(e.target.value)
  }

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

  const fetchData = (isFilterResult = false): void => {
    if (isFilterResult) {
      pageRef.current = 1
    }

    const sortArr = sort.split('-')

    dispatch(startPageLoader())
    fetchPost({
      draft: reportType === 'draft',
      pageNumber: pageRef.current,
      searchBy: search,
      category: category,
      sortBy: sortArr[0] || '',
      sortDir: sortArr[1] || '',
      date: (() => {
        if (date) {
          return date.format('YYYY-MM-DD')
        }
        return ''
      })(),
    })
      .unwrap()
      .then((res) => {
        if (isFilterResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.data))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.data)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  useEffect(() => {
    if (sort) {
      fetchData(true)
    }
  }, [sort])

  useEffect(() => {
    fetchData(true)
  }, [reportType])

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Box className={styles.container}>
      <Box
        className={styles.filterWrapper}
        alignItems='center'
        display='flex'
        flexDirection='column'
        p={1}
      >
        <Grid container alignItems='center'>
          <Grid item flexGrow={1} p={2}>
            <SearchInput onChange={setSearch} />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <SimpleDropdown
              id={'category'}
              name={''}
              value={category}
              onChange={handleCategoryChange}
              options={METAL_CATEGORIES.map(({id,category}) => ({
                label: category,
                value: id,
              }))}
              placeholder='Select Metal Category'
            />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <BasicDatePicker value={date} onChange={setDate} placeholder='Date' disableFuture />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <Button
              fullWidth
              type='button'
              variant='outlined'
              color='primary'
              onClick={handleFilter}
              sx={{ height: 49.25 }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container justifyContent='space-between' alignItems='center' mt={2} className={styles.tabSortFilterContainer}>
        <Grid xs={12} sm={12} md={8}>
          <Box className={styles.reportTypeTabContainer}>
            <ReportTypeTab
              label='Current Reports'
              isActive={reportType === 'draft'}
              onClick={() => setReportType('draft')}
            />
            <ReportTypeTab
              label='Published Reports'
              isActive={reportType === 'published'}
              onClick={() => setReportType('published')}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={4} p={2} className={styles.sortFilterContainer}>
          <SortDropdown options={sortOptions} value={sort} onChange={handleSortDropdownChange} />
        </Grid>
      </Grid>

      <Box>
        {POST_RESPONSE && (
          <InfiniteScroll
            dataLength={paginatedData.length}
            next={loadNextPage}
            hasMore={POST_RESPONSE.page.has_next}
            loader={
              <Typography variant='subtitle1' textAlign='center'>
                {' '}
                loading more feeds..{' '}
              </Typography>
            }
          >
            {paginatedData.map((post) => {
              return (
                <Box mb={2} key={`${reportType}_${post.post_id}`}>
                  <PostPreview
                    postId={post.post_id}
                    title={post.post__title}
                    date={post.post__created_at}
                    isManual={post.post__is_manual}
                    originalNews={post.original_news}
                    isAuto={!post.post__is_manual}
                    description={post.post__short_description}
                    category={post.post__category}
                    isPublished={!!post.post__post_to_landing_page}
                    isDraft={!post.post__post_to_landing_page}
                    postStatus={post.post__post_status}
                    hideReadMore={reportType === 'draft'}
                    showEditButton={reportType === 'draft'}
                    editButtonText='Continue'
                  />
                </Box>
              )
            })}
          </InfiniteScroll>
        )}
      </Box>
      {paginatedData.length === 0 && isFetching === false && (
        <Typography variant='subtitle1' textAlign='center'>
          No Result found.
        </Typography>
      )}
    </Box>
  )
}

export default MyReports
