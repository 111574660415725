import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { routeUrls } from 'src/utils/constants'
const ForgotPasswordLayout = () => {
  const { isAuthenticated, userInfo, token, role } = useSelector((state: RootState) => state.auth)
  const location = useLocation()

  if (isAuthenticated === true && userInfo && token) {
    if (role === 'regular_user') {
      return <Navigate to={routeUrls.user.home} state={{ from: location }} />
    }
    if (role === 'editor') {
      return <Navigate to={routeUrls.editor.home} state={{ from: location }} />
    }
    if (role === 'admin') {
      return <Navigate to={routeUrls.admin.home} state={{ from: location }} />
    }
  }

  return (
    <Box height='100vh'>
      <Outlet />
    </Box>
  )
}

export default ForgotPasswordLayout
