import { createTheme } from '@mui/material/styles'

export const myColors = {
  primary: 'rgba(0, 83, 157, 1)',
  secondary: 'rgba(96, 41, 95, 0.99)',
  gray: 'rgba(135, 132, 132, 1)',
  lightGray: 'rgba(68, 68, 68, 1)',
  inputBorder: 'rgba(188, 188, 188, 1)',
  white: 'rgba(255, 255, 255, 1)',
  green: 'green',
}
const theme = createTheme({
  palette: {
    primary: {
      main: myColors.primary,
    },
    secondary: {
      main: myColors.secondary,
    },
    // success: {
    // },
  },
  typography: {
    h1: {
      fontSize: '34px',
      fontWeight: 600,
      fontFamily: 'FreightSans-Medium',
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
      fontFamily: 'FreightSans-Medium',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 500,
      fontFamily: 'FreightSans-Medium',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 600,
      fontFamily: 'FreightSans-Medium',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: 'FreightSans-Medium',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: 'FreightSans-Medium',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: 'FreightSans-Medium',
    },

    body2: {
      fontSize: '14px',
      fontWeight: 400,
      fontFamily: 'FreightSans-Medium',
    },

    subtitle1: {
      fontSize: '15px',
      fontWeight: 400,
      fontFamily: 'FreightSans-Medium',
      color: myColors.gray,
    },
    subtitle2: {
      fontSize: '11px',
      fontWeight: 400,
      fontFamily: 'FreightSans-Medium',
      color: myColors.lightGray,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'black',
          borderRadius: 25,
          position: 'relative',
          backgroundColor: myColors.white,
          border: '1px solid ' + myColors.inputBorder,
          width: '100%',
          padding: '15px 21px 15px 21px',
          fontSize: 12,
          fontWeight: 500,
          fontFamily: 'FreightSans-Medium',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: myColors.lightGray,
          fontFamily: 'FreightSans-Medium',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 25,
          height: 47,
          fontFamily: 'FreightSans-Medium',
        },
        containedPrimary: {
          background: `linear-gradient(to right,${myColors.primary},${myColors.secondary})`,
        },
        outlinedPrimary: {
          color: myColors.primary,
          border: '1px solid ' + myColors.primary,
          ':hover': {
            border: '1px solid ' + myColors.primary,
            background: myColors.white,
          },
          background: myColors.white,
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 14,
          fontFamily: 'FreightSans-Medium',
        },
        colorSuccess: {
          color: 'black',
          background: 'rgba(120, 37, 255, 0.1)',
          border: '1px solid rgba(120, 37, 255, 1)',
          borderRadius: '8px',
        },
        colorWarning: {
          color: 'black',
          background: 'rgba(255, 122, 0, 0.1)',
          border: '1px solid rgba(255, 122, 0, 1)',
          borderRadius: '8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 25,
          backgroundColor: 'white',
          border: '1px solid rgba(188, 188, 188, 1)',
          padding: '12.125px 21px 12.125px 21px',
          fontFamily: 'FreightSans-Medium',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        select: {
          border: 'none !important',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          color: 'white',
        },
      },
    },
  },
})

export default theme
