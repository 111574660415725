import { configureStore } from '@reduxjs/toolkit'
import authReducer from 'src/store/features/auth/authSlice'
import forgotPasswordReducer from 'src/store/features/forgotPassword/forgotPasswordSlice'
import staticPageContentReducer from 'src/store/features/staticPageContent/staicPageContentSlice'
import commonReducer from 'src/store/features/common/commonSlice'

// __________________ rtk _____________________________ //

// common
import masterDataApi from 'src/api/masterDataApi'

// admin
import adminPostApi from 'src/api/admin/postApi'
import adminManageSubscription from 'src/api/admin/manageSubscription'
import adminUserManagementApi from 'src/api/admin/userManagement'

// user
import UserPostApi from 'src/api/user/postApi'
import UserSubscriptionApi from 'src/api/user/subscriptionApi'
import UserSettingsApi from 'src/api/user/settingsApi'

// editor
import EditorSettingsApi from 'src/api/editor/settingsApi'

const store = configureStore({
  reducer: {
    auth: authReducer,
    forgotPassword: forgotPasswordReducer,
    staticPageContent: staticPageContentReducer,
    common: commonReducer,

    [masterDataApi.reducerPath]: masterDataApi.reducer,
    [adminPostApi.reducerPath]: adminPostApi.reducer,
    [adminManageSubscription.reducerPath]: adminManageSubscription.reducer,
    [UserPostApi.reducerPath]: UserPostApi.reducer,
    [UserSubscriptionApi.reducerPath]: UserSubscriptionApi.reducer,
    [adminUserManagementApi.reducerPath]: adminUserManagementApi.reducer,
    [UserSettingsApi.reducerPath]: UserSettingsApi.reducer,
    [EditorSettingsApi.reducerPath]: EditorSettingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(masterDataApi.middleware)
      .concat(adminPostApi.middleware)
      .concat(adminManageSubscription.middleware)
      .concat(UserPostApi.middleware)
      .concat(UserSubscriptionApi.middleware)
      .concat(adminUserManagementApi.middleware)
      .concat(UserSettingsApi.middleware)
      .concat(EditorSettingsApi.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
