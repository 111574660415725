import React, { useState } from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Typography } from '@mui/material'
import { useDeletePostByIdMutation } from 'src/api/admin/postApi'
import { errorToast, successToast } from 'src/utils/toastService'

type DeletePostProps = {
  open: boolean
  onClose: () => void
  postId: number
  refetchPostList: () => void
}

const DeletePost = ({ open, onClose, postId, refetchPostList }: DeletePostProps) => {
  const [deletePostApi, { isLoading }] = useDeletePostByIdMutation()
  console.log(useDeletePostByIdMutation())

  const handleDeletePost = () => {
    console.log('Post Removed')
    if (postId === 0) return

    deletePostApi(postId)
      .unwrap()
      .then(() => {
        successToast('Post deleted successfully.')
        refetchPostList()
        onClose()
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Oops, something went wrong.')
      })
  }

  return (
    <div>
      <SimpleModal
        open={open}
        onClose={onClose}
        title='Delete Post'
        leftButton={{
          label: 'Cancel',
          onClick: onClose,
        }}
        rightButton={{
          label: 'Delete',
          onClick: handleDeletePost,
          loading: isLoading,
        }}
      >
        <Typography variant='h5' textAlign='center'>
          Are you sure you want to delete this Post?
        </Typography>
      </SimpleModal>
    </div>
  )
}

export default DeletePost
