import React from 'react'
import { Grid, Typography } from '@mui/material'

import styles from './index.module.scss'
import activeSidebarItem from 'src/assets/icons/active-sidebar-item.png'
import sidebarItem from 'src/assets/icons/sidebar-item.png'
import { routeUrls } from 'src/utils/constants'
import { useNavigate } from 'react-router-dom'
import { isActiveLink } from 'src/utils/helpers'

type navLink = {
  label: string
  url: string
}
type Sidebarprops = {
  open: boolean
  onDrawerToggle: () => void
}
const navLinks: navLink[] = [
  {
    label: 'Content Management',
    url: routeUrls.admin.home,
  },

  {
    label: 'Users (Regular)',
    url: routeUrls.admin.regularUser,
  },

  {
    label: 'Users (Editor)',
    url: routeUrls.admin.editorUser,
  },
  {
    label: ' Manage Subscription',
    url: routeUrls.admin.manageSubscription,
  },
  {
    label: 'Subscribed users',
    url: routeUrls.admin.subscribedUser,
  },
]

const Sidebar = ({ open, onDrawerToggle }: Sidebarprops) => {
  const navigate = useNavigate()

  const drawerClass = open ? styles.open : styles.close

  return (
    <Grid className={styles.sidebar + ' ' + drawerClass}>
      {navLinks.map((item, i) => {
        const isActive = isActiveLink(item.url)
        return (
          <Grid
            key={i}
            container
            alignItems='center'
            className={isActive ? styles.activeSidebarItem : styles.sidebarItem}
            onClick={() => {
              open && onDrawerToggle();
              navigate(item.url);
            }}
          >
            <img src={isActive ? activeSidebarItem : sidebarItem} />
            <Typography variant='h5' ml={2}>
              {item.label}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Sidebar
