import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Grid, Button } from '@mui/material'
import { routeUrls } from 'src/utils/constants'
import styles from './index.module.scss'
import logo from 'src/assets/images/logo.png'

const TermAndPolicyLayout = () => {
  const navigate = useNavigate()

  return (
    <Box height='100vh'>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={styles.wrapperPadding}
      >
        <Grid item>
          <img src={logo} alt='Logo' height={88} width={88} />
        </Grid>
        <Grid>
          <Button
            sx={{
              margin: '5px',
              paddingLeft: '32px',
              paddingRight: '32px',
            }}
            type='submit'
            variant='outlined'
            color='primary'
            onClick={() => navigate(routeUrls.login)}
          >
            Login
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{
              margin: '5px',
              paddingLeft: '32px',
              paddingRight: '32px',
            }}
            onClick={() => navigate(routeUrls.register)}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
      <Box className={styles.backgroundBox}></Box>
      <Box className={styles.wrapperPadding}>
        <Box className={styles.contentWrapper}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default TermAndPolicyLayout
