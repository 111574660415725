import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { Box, IconButton } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ClearIcon from '@mui/icons-material/Clear'
import styles from './index.module.scss'
type PdfPreviewProps = {
  file?: File
  url?: string
  canDelete?: boolean
  onDelete?: () => void
  onOpenPdf?: () => void
}

const PdfPreview = ({
  file,
  url = '',
  canDelete = false,
  onDelete,
  onOpenPdf,
}: PdfPreviewProps) => {
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setTotalPages(numPages)
    setCurrentPageNumber(1)
  }

  const handlePrev = () => {
    if (currentPageNumber > 1) {
      setCurrentPageNumber((page) => page - 1)
    }
  }

  const handleNext = () => {
    if (currentPageNumber < totalPages) {
      setCurrentPageNumber((page) => page + 1)
    }
  }

  const handleDelete = (e: any) => {
    e.stopPropagation()
    if (onDelete) {
      onDelete()
    }
  }
  return (
    <Box height={450} m={1}>
      <Box className={styles.container} onClick={onOpenPdf}>
        <Document
          file={file || url}
          onLoadSuccess={onDocumentLoadSuccess}
          className={styles.document}
        >
          <Page
            height={400}
            pageNumber={currentPageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        {canDelete === true ? (
          <IconButton aria-label='delete' className={styles.overlayButton} onClick={handleDelete}>
            <ClearIcon />
          </IconButton>
        ) : null}
      </Box>

      <Box textAlign='center' mt={1}>
        <IconButton onClick={handlePrev}>
          <KeyboardArrowLeftIcon />
        </IconButton>

        <span>
          Page {currentPageNumber} of {totalPages}
        </span>

        <IconButton onClick={handleNext}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default PdfPreview
