import React, { useEffect } from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import theme from './utils/setup/theme'
import router from './utils/setup/router'
import store from './store/index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PageLoader from 'src/Layout/PageLoader'
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/index.scss'

import { pdfjs } from 'react-pdf'
import { GoogleAnalyticsData } from './utils/constants'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
// useEffect(() => {
//   GoogleAnalyticsData('Visit', 'url')
// }, [])
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
        <PageLoader />
      </Provider>
    </ThemeProvider>
  )
}

export default App
