import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from '../index.module.scss'
import dayjs from 'dayjs'
import calendarIcon from 'src/assets/icons/calendar.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  useDeletePostByIdMutation,
  useFetchPostAttachmentSectionsQuery,
  useGetPostByIdQuery,
} from 'src/api/admin/postApi'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'
import PdfPreview from 'src/Component/PdfPreview'
import { localStorageKeys, routeUrls } from 'src/utils/constants'
import { errorToast, successToast } from 'src/utils/toastService'
import SimpleModal from 'src/Component/SimpleModal'
import dailyIcon from 'src/assets/icons/daily.png'
import manualIcon from 'src/assets/icons/manual.png'

import RenderContent from 'src/Component/RichTextEditor/RenderContent'
import ImagePreview from 'src/Component/ImagePreview'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import SimpleDropdown from 'src/Component/SimpleDropdown'
const ViewPost = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const [category, setCategory] = useState<string>('')
  const [showNews, setShowNews] = useState(false)
  console.log('location', location?.state)
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  console.log('metal', METAL_CATEGORIES)

  const userRole: any = localStorage.getItem(localStorageKeys.role)
  console.log('user@', userRole)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [categorySelected, setCategorySelected] = useState<string>('')

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const { postId } = useParams<{ postId: string }>()
  const postIdNumber = postId ? parseInt(postId, 10) : 0
  const { data: post, isFetching } = useGetPostByIdQuery(postIdNumber, {
    refetchOnMountOrArgChange: true,
  })
  console.log('postCate', post)
  const { data: attachmentSectionResponse } = useFetchPostAttachmentSectionsQuery(postIdNumber, {
    refetchOnMountOrArgChange: true,
  })

  const [deletePostApi] = useDeletePostByIdMutation()

  const handleDeletePost = () => {
    console.log('Post Removed')
    if (postIdNumber === 0) return

    deletePostApi(postIdNumber)
      .unwrap()
      .then(() => {
        successToast('Post deleted successfully.')
        handleCloseDeleteModal()
        navigate(-1)
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Oops, something went wrong.')
      })
  }

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('e.target.value', e.target.value)
    setCategory(e.target.value)
    console.log('cate', category)
  }
  // useEffect(() => {
  //   setCategory(location?.state?.categorySelected ? location?.state?.categorySelected : '')
  // }, [location?.state?.categorySelected])
  const handleRemoveClick = () => {
    setOpenDeleteModal(true)
    console.log('Removed Button Clicked')
  }
  const navigateToEditPage = () => {
    navigate(routeUrls.admin.editPost.replace(':postId', postIdNumber.toString()))
  }

  const handleNews = () => {
    window.open(`/admin/news-page/${postId}`,'_blank')
  }

  const handleClose = () => {
    setShowNews(false)
  }

  const handleFileOpen = (url: string) => {
    window.open(url, '_blank')
  }

  const isPublished = !!post?.post_to_landing_page
  const isDraft = !post?.post_to_landing_page

  useEffect(() => {
    if (isFetching) dispatch(startPageLoader())
    else dispatch(stopPageLoader())
  }, [isFetching])

  if (!post) return null

  return (
    <Box>
      <Box className={`cardWrapper ${styles.headerWrapper}`}>
        <Typography variant='h1'>Specific Post</Typography>
        <Grid container mt={1} className={styles.container}>
          <Grid container className={styles.filterContainer}>
            <Grid md={8} marginLeft={-4} className={styles.filterBtns}>
              <SimpleDropdown
                id={'category'}
                name={''}
                value={category || location?.state?.categorySelected || ''}
                onChange={handleCategoryChange}
                options={METAL_CATEGORIES.map(({ id, category }) => ({
                  label: category,
                  value: id,
                }))}
                placeholder='Select Metal Category'
              />
            </Grid>
            <Grid md={4} pl={1} className={styles.filterBtns}>
              <Button
                fullWidth
                type='button'
                variant='outlined'
                color='primary'
                onClick={() => {
                  navigate(routeUrls.admin.home, {
                    state: {
                      categoryId: category,
                    },
                  })
                }}
              >
                Apply filter
              </Button>
            </Grid>
          </Grid>
          <Grid item pr={2} className={styles.removeBtnPadding}>
            <Button
              className={styles.removeBtn+' '+styles.removeBtnFull}
              type='button'
              variant='outlined'
              color='primary'
              onClick={handleRemoveClick}
            >
              Remove
            </Button>
          </Grid>

          <Grid item className={styles.editBtnFull}>
            <Button
              className={styles.editBtn+' '+styles.editBtnFull}
              type='submit'
              variant='contained'
              color='primary'
              onClick={navigateToEditPage}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={`cardWrapper ${styles.postWrapper}`} mt={3}>
        <Box display='flex' justifyContent='end' mb={2}>
          {isPublished && <Chip label='Published' color='primary' />}
          {isDraft && <Chip label='Draft' />}
          {isDraft && post && post.post_status && (
            <Chip
              label={(() => {
                if (post.post_status === 'pending') return 'Pending'
                else if (post.post_status === 'in_progress') return 'In Progress'
                else if (post.post_status === 'completed') return 'Completed'
              })()}
              color='warning'
              sx={{ marginLeft: 1 }}
            />
          )}
        </Box>
        <Grid container justifyContent='space-between' alignItems='baseline' className={styles.titleContainer}>
          <Grid item xs={9} className={styles.titleCard}>
            <Typography variant='h2' className={styles.titleFont}>{post?.title}</Typography>
            {!post?.is_manual ? (
              <Grid
                item
                xs={12}
                display={'flex'}
                justifyContent='end'
                marginLeft={'35%'}
                marginRight={-34}
                marginTop={-5}
                marginBottom={5}
                className={styles.sourceContainer}
              >
                <Button onClick={handleNews}>View News From Source</Button>
              </Grid>
            ) : null}
          </Grid>

          <Grid
            item
            display='flex'
            alignItems='center'
            justifyContent='end'
            className={styles.dateContainer}
            // marginBottom={12}
          >
            <img src={calendarIcon} /> &nbsp;
            <span> {dayjs(post?.created_at).format('MMM DD, YYYY')} </span>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          {post?.is_manual && (
            <Grid item>
              <Chip
                label={
                  <Grid display='flex' alignItems='center' justifyContent='center'>
                    <img src={manualIcon} /> <span> &nbsp; Thematic</span>
                  </Grid>
                }
                color='warning'
              />
            </Grid>
          )}

          {!post?.is_manual && (
            <Grid item>
              <Chip
                label={
                  <Grid display='flex' alignItems='center' justifyContent='center'>
                    <img src={dailyIcon} /> <span> &nbsp; Daily</span>
                  </Grid>
                }
                color='success'
              />
            </Grid>
          )}
          {METAL_CATEGORIES.filter((item) => post?.category?.includes(item.id))?.map((item) => (
            <Grid p={1} key={item.id}>
              <Chip label={item.category} />
            </Grid>
          ))}
        </Grid>
        <Box pt={5} pb={3}>
          <Typography variant='h6' color='rgba(62, 62, 62, 1)'>
            {post?.short_description}
          </Typography>
        </Box>
        <Box>{post?.description && <RenderContent content={post.description} />}</Box>

        <Box>
          {!!attachmentSectionResponse?.length && (
            <>
              <Divider />
              <Typography variant='h3' mt={2}>
                Attachments
              </Typography>
            </>
          )}

          {attachmentSectionResponse?.map((section, i) => {
            return (
              <Box key={section.section} mt={2}>
                <Typography variant='h4'>
                  {' '}
                  {i + 1}
                  {')'} {section.title}{' '}
                </Typography>
                <Typography variant='h6' color='rgba(62, 62, 62, 1)'>
                  {section.description}
                </Typography>
                <Grid container justifyContent='center'>
                  {section.pdf.map((file) => {
                    return (
                      <PdfPreview
                        key={file.id}
                        url={file.file_url}
                        onOpenPdf={() => handleFileOpen(file.file_url)}
                      />
                    )
                  })}
                </Grid>
                <Grid container justifyContent='center'>
                  {section.img.map((file) => {
                    return (
                      <ImagePreview
                        key={file.id}
                        url={file.file_url}
                        onOpenImage={() => handleFileOpen(file.file_url)}
                      />
                    )
                  })}
                </Grid>
              </Box>
            )
          })}
        </Box>

        <Box>
          <SimpleModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            title='Delete Post'
            leftButton={{
              label: 'Cancel',
              onClick: handleCloseDeleteModal,
            }}
            rightButton={{
              label: 'Delete',
              onClick: handleDeletePost,
              loading: isFetching,
            }}
          >
            <Typography variant='h5' textAlign='center'>
              Are you sure you want to delete this Post?
            </Typography>
          </SimpleModal>
        </Box>
      </Box>
      {showNews ? (
        <SimpleModal
          open={showNews}
          onClose={handleClose}
          title={'Aylien News'}
          leftButton={{
            label: '',
            onClick: handleClose,
          }}
          rightButton={{
            label: 'Close',
            onClick: handleClose,
          }}
        >
          <Typography variant='h5'>
            {/* {post.original_news?.length
              ? post.original_news
              : 'We are waiting for the latest news!'} */}
            {post.original_news?.length ? (
              <div
                className='external-html'
                dangerouslySetInnerHTML={{ __html: post.original_news }}
              />
            ) : (
              'We are waiting for the latest news!'
            )}
          </Typography>
        </SimpleModal>
      ) : null}
    </Box>
  )
}

export default ViewPost
