import { createBrowserRouter, RouteObject } from 'react-router-dom'

import { routeUrls } from '../constants'
import LoginLayout from 'src/Layout/LoginLayout'
import UserHomeLayout from 'src/Layout/UserHomeLayout'
import Login from 'src/Page/Public/Login'
import LandingPage from 'src/Page/LandingPage'
import UserHome from 'src/Page/Private/User/Home'
import Register from 'src/Page/Public/Register'
import ForgotPasswordLayout from 'src/Layout/ForgotPasswordLayout'
import ForgotPassword from 'src/Page/Public/ForgotPassword'
import ResetPassword from 'src/Page/Public/ResetPassword'
import TermAndPolicyLayout from 'src/Layout/TermAndPolicyLayout'
import TermAndCondition from 'src/Page/Public/TermAndCondition'
import PrivacyPolicy from 'src/Page/Public/PrivacyPolicy'
import AdminHomeLayout from 'src/Layout/AdminHomeLayout'
import AdminHome from 'src/Page/Private/Admin/Home'
import CreatePost from 'src/Page/Private/Admin/Post/CreatePost'
import EditPost from 'src/Page/Private/Admin/Post/EditPost'
import ManageSubscription from 'src/Page/Private/Admin/ManageSubscription'
import UserSettings from 'src/Page/Private/User/Settings'
import ViewPost from 'src/Page/Private/Admin/Post/ViewPost'
import ViewPostUserSide from 'src/Page/Private/User/Post/ViewPost'
import SubscribedUserList from 'src/Page/Private/Admin/SubscribedUserList'
import BookmarkHistory from 'src/Page/Private/User/BookmarkHistory'
import EditorUser from 'src/Page/Private/Admin/UserManagement/EditorUser'
import UserManagement from 'src/Page/Private/Admin/UserManagement/RegularUser'
import EditorHomeLayout from 'src/Layout/EditorHomeLayout'
import EditorHome from 'src/Page/Private/Editor/Home'
import EditorViewPost from 'src/Page/Private/Editor/Post/ViewPost'
import EditorSettings from 'src/Page/Private/Editor/Settings'
import EditorEditPost from 'src/Page/Private/Editor/Post/EditPost'
import AdminSettings from 'src/Page/Private/Admin/Settings'
import MyReports from 'src/Page/Private/Editor/MyReports'
import News from 'src/Page/Private/Admin/News'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: routeUrls.login,
        element: <Login />,
      },
      {
        path: routeUrls.register,
        element: <Register />,
      },
    ],
  },
  {
    path: '/',
    element: <ForgotPasswordLayout />,
    children: [
      {
        path: routeUrls.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: routeUrls.resetPassword,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/',
    element: <TermAndPolicyLayout />,
    children: [
      {
        path: routeUrls.termAndCondition,
        element: <TermAndCondition />,
      },
      {
        path: routeUrls.privacyPolicy,
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: routeUrls.user.home,
    element: <UserHomeLayout />,
    children: [
      {
        index: true,
        element: <UserHome />,
      },
      {
        path: routeUrls.user.bookmarkHistory,
        element: <BookmarkHistory />,
      },
      {
        path: routeUrls.user.settings,
        element: <UserSettings />,
      },
      {
        path: routeUrls.user.viewpost,
        element: <ViewPostUserSide />,
      },
    ],
  },
  {
    path: routeUrls.admin.home,
    element: <AdminHomeLayout />,
    children: [
      {
        index: true,
        element: <AdminHome />,
      },
      {
        path: routeUrls.admin.createPost,
        element: <CreatePost />,
      },
      {
        path: routeUrls.admin.manageSubscription,
        element: <ManageSubscription />,
      },
      {
        path: routeUrls.admin.subscribedUser,
        element: <SubscribedUserList />,
      },
      {
        path: routeUrls.admin.editPost,
        element: <EditPost />,
      },
      {
        path: routeUrls.admin.viewPost,
        element: <ViewPost />,
      },
      {
        path: routeUrls.admin.regularUser,
        element: <UserManagement />,
      },
      {
        path: routeUrls.admin.editorUser,
        element: <EditorUser />,
      },
      {
        path: routeUrls.admin.settings,
        element: <AdminSettings />,
      },
    ],
  },
  {
    path: routeUrls.editor.home,
    element: <EditorHomeLayout />,
    children: [
      {
        index: true,
        element: <EditorHome />,
      },
      {
        path: routeUrls.editor.createPost,
        element: <CreatePost />,
      },
      {
        path: routeUrls.editor.viewpost,
        element: <EditorViewPost />,
      },
      {
        path: routeUrls.editor.settings,
        element: <EditorSettings />,
      },
      {
        path: routeUrls.editor.editPost,
        element: <EditorEditPost />,
      },

      {
        path: routeUrls.editor.myReports,
        element: <MyReports />,
      },
    ],
  },  
  {
    path: routeUrls.admin.newsPage,
    element: <News />,
  },
]

const router = createBrowserRouter(routes)

export default router
