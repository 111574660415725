import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'
import closeIcon from 'src/assets/icons/close-icon.png'
type SimpleModalProps = {
  open: boolean
  onClose: () => void
  title?: string
  leftButton: {
    label: string
    onClick: () => void
    loading?: boolean
  }
  rightButton: {
    label: string
    onClick: () => void
    loading?: boolean
  }
  children?: React.ReactNode
}
const SimpleModal = ({
  open,
  onClose,
  title,
  leftButton,
  rightButton,
  children,
}: SimpleModalProps) => {
  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container justifyContent='end'>
          <IconButton onClick={onClose}>
            <img src={closeIcon} />
          </IconButton>
        </Grid>
        {title && (
          <Typography variant='h2' textAlign='center'>
            {title}
          </Typography>
        )}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions>
        <Grid container>
          {leftButton.label ? (
            <Grid p={2} flexGrow={1}>
              <Button
                fullWidth
                type='button'
                variant='outlined'
                color='primary'
                disabled={!!leftButton.loading}
                onClick={leftButton.onClick}
              >
                {leftButton.loading === true ? (
                  <CircularProgress color='info' size={25} />
                ) : (
                  leftButton.label
                )}
              </Button>
            </Grid>
          ) : null}
          <Grid p={2} flexGrow={1}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              disabled={!!rightButton.loading}
              onClick={rightButton.onClick}
            >
              {rightButton.loading === true ? (
                <CircularProgress color='info' size={25} />
              ) : (
                rightButton.label
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleModal
