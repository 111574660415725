import React from 'react'

type IconProps = {
  color?: string
}
const BookmarkIcon = ({ color = '#00539D' }: IconProps) => {
  return (
    <svg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 16.874V6.87402H16V16.874V16.8741C16 16.9184 15.9883 16.9618 15.966 17C15.9438 17.0382 15.9117 17.0699 15.8733 17.0917C15.8348 17.1135 15.7912 17.1247 15.747 17.1242C15.7028 17.1236 15.6597 17.1114 15.6218 17.0888C15.6218 17.0888 15.6218 17.0888 15.6217 17.0888C15.6217 17.0887 15.6216 17.0887 15.6215 17.0886L12.2573 15.0698L11.9999 14.9153L11.7426 15.0699L8.3785 17.0901C8.37842 17.0902 8.37835 17.0902 8.37827 17.0903C8.34035 17.1129 8.29709 17.1252 8.25291 17.1257C8.20864 17.1262 8.16502 17.1149 8.12652 17.0931C8.08802 17.0712 8.05602 17.0395 8.03379 17.0012C8.01157 16.9629 7.9999 16.9194 8 16.8751V16.874Z'
        stroke={color}
      />
    </svg>
  )
}

export default BookmarkIcon
