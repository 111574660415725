import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomizedSwitches from 'src/Component/SwitchButton'
import { useDeleteUserMutation } from 'src/api/admin/userManagement'
import { errorToast, successToast } from 'src/utils/toastService'
import { UsersData } from 'src/api/admin/userManagement/types'
import { check } from 'prettier'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(248, 249, 252, 1)',
  },
}))
type EmptyTableRowProps = {
  cellCount: number
}
const EmptyTableRow = ({ cellCount }: EmptyTableRowProps) => {
  return (
    <StyledTableRow hover role='checkbox' tabIndex={-1}>
      <TableCell
        align='center'
        sx={{
          border: 'none',
        }}
        colSpan={cellCount}
      >
        <Typography variant='subtitle1' textAlign='center'>
          No Result Found
        </Typography>
      </TableCell>
    </StyledTableRow>
  )
}

export default EmptyTableRow
