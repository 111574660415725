import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH } from 'src/utils/constants'
import { MetalCategoriesResponse} from './types'

const masterDataApi = createApi({
  reducerPath: 'masterDataApi',
  keepUnusedDataFor: 500,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['masterDataApi'],
  endpoints: (builder) => ({
    getMetalCategories: builder.query<any[], void>({
      query: () => ({
        url: '/api/v1/metal-categories/',
        method: 'GET',
      }),
      transformResponse: (response: []) => {
        return response
      },
    }),
  }),
})

export const { useGetMetalCategoriesQuery } = masterDataApi
export default masterDataApi
