import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import Plans from './Plans'
import PaymentForm from './PaymentForm'
import { useFetchUserSubscriptionQuery } from 'src/api/user/subscriptionApi'
import { infoToast } from 'src/utils/toastService'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { setHasFullAccess, setSelectedPlanOnSignUp } from 'src/store/features/auth/authSlice'
import { localStorageKeys } from 'src/utils/constants'
const Subscription = () => {
  const { selectedPlanOnSignUp } = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch<AppDispatch>()
  const [step, setStep] = useState<0 | 1>(0)
  const [selectedPlan, setSelectedPlan] = useState({
    id: 0,
    price: 0,
    type: '',
  })

  const {
    data: userSubscription,
    isFetching,
    refetch: refetchUserCurrentPlan,
  } = useFetchUserSubscriptionQuery()

  const handleChoosePlan = (id: number, price: number, type: string) => {
    if (userSubscription?.subscription_id && userSubscription.subscription_taken == true) {
      infoToast(
        'You already have an active subscription! Cancel your existing plan if you want to start new.',
      )
      return
    }
    setSelectedPlan({
      id,
      price,
      type,
    })
    setStep(1)
  }

  const handleCancel = () => {
    setSelectedPlan({ id: 0, price: 0, type: '' })
    setStep(0)
    refetchUserCurrentPlan()
  }

  useEffect(() => {
    if (selectedPlanOnSignUp && selectedPlanOnSignUp.id !== 0) {
      setSelectedPlan({
        id: selectedPlanOnSignUp.id,
        price: selectedPlanOnSignUp.price,
        type: selectedPlanOnSignUp.type,
      })
      setStep(1)
      dispatch(
        setSelectedPlanOnSignUp({
          id: 0,
          price: 0,
          type: '',
        }),
      )
    }
  }, [selectedPlanOnSignUp])

  useEffect(() => {
    if (userSubscription) {
      const hasFullAccess =
        userSubscription.subscription_taken === true ||
        userSubscription.trial_completed === false ||
        userSubscription.subscription_expire === false
      localStorage.setItem(localStorageKeys.hasFullAccess, JSON.stringify(hasFullAccess))
      setHasFullAccess(hasFullAccess)
    }
  }, [userSubscription])

  if (isFetching) {
    return (
      <Grid container justifyContent='center' alignItems='center' height='400px'>
        <CircularProgress />
      </Grid>
    )
  }
  return (
    <Box>
      {step === 0 && (
        <Plans
          refetchUserCurrentPlan={refetchUserCurrentPlan}
          userSubscription={userSubscription}
          onChoosePlan={handleChoosePlan}
        />
      )}
      {step === 1 && (
        <PaymentForm
          subscriptionId={selectedPlan.id}
          price={selectedPlan.price}
          type={selectedPlan.type}
          onCancel={handleCancel}
        />
      )}
    </Box>
  )
}

export default Subscription
