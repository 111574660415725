import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { loginUser, registerUser, logoutUser, verifyEmailOtp, resendEmailOtp } from './authActions'
import { localStorageKeys, userRoles, userRolesTypes } from 'src/utils/constants'

type InitialStateType = {
  isAuthenticated: boolean
  role: userRolesTypes | null
  loading: boolean
  token: string | null
  userInfo: {
    id: number
    email: string
    name: string | null | undefined
  } | null
  error: string | null
  success: boolean
  primarySpeciality: {
    loading: boolean
    data: {
      id: number
      name: string
    }[]
    error: string | null
  }
  showTrialStartPopUp: boolean
  selectedPlanOnSignUp: {
    id: number
    price: number
    type: string
  }
  hasFullAccess: boolean
  isFreeUser: boolean
}

const initialState: InitialStateType = {
  loading: false,
  token: localStorage.getItem(localStorageKeys.accessToken) || null, // for storing the JWT
  userInfo: (() => {
    const user = localStorage.getItem(localStorageKeys.user) || null
    if (user) {
      try {
        return JSON.parse(user)
      } catch (error) {
        return null
      }
    }
    return null
  })(), // for user object
  error: null,
  success: false, // for monitoring the registration process.
  isAuthenticated: !!(
    localStorage.getItem(localStorageKeys.accessToken) &&
    localStorage.getItem(localStorageKeys.user)
  ),
  primarySpeciality: {
    loading: false,
    error: null,
    data: [],
  },
  role: (() => {
    const role = localStorage.getItem(localStorageKeys.role)
    if (role) {
      if (role === userRoles.user) {
        return 'regular_user'
      }
      if (role === userRoles.editor) {
        return 'editor'
      }
      if (role === userRoles.admin) {
        return 'admin'
      }
    }
    return null
  })(),
  showTrialStartPopUp: (() => {
    const showPopUp = localStorage.getItem(localStorageKeys.showTrialStartPopPop) || null
    if (showPopUp) {
      try {
        return JSON.parse(showPopUp) === true
      } catch (error) {
        return false
      }
    }
    return false
  })(),
  selectedPlanOnSignUp: {
    id: 0,
    price: 0,
    type: '',
  },
  hasFullAccess: (() => {
    const hasFullAccess = localStorage.getItem(localStorageKeys.hasFullAccess)
    if (hasFullAccess) {
      return !!JSON.parse(hasFullAccess)
    }
    return false
  })(),
  isFreeUser: (() => {
    const isFreeUser = localStorage.getItem(localStorageKeys.isFreeUser)
    if (isFreeUser) {
      return !!JSON.parse(isFreeUser)
    }
    return false
  })(),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthErrorReducer(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setShowTrialStartPopUp(state, action: PayloadAction<boolean>) {
      state.showTrialStartPopUp = action.payload
      if (action.payload === true) {
        localStorage.setItem(localStorageKeys.showTrialStartPopPop, JSON.stringify(true))
      } else {
        localStorage.removeItem(localStorageKeys.showTrialStartPopPop)
      }
    },
    setSelectedPlanOnSignUp(
      state,
      action: PayloadAction<InitialStateType['selectedPlanOnSignUp']>,
    ) {
      state.selectedPlanOnSignUp = action.payload
    },
    setHasFullAccess(state, action: PayloadAction<boolean>) {
      state.hasFullAccess = action.payload
    },
  },
  extraReducers: (builder) => {
    builder

      // login
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.selectedPlanOnSignUp = {
          id: 0,
          price: 0,
          type: '',
        }

        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const userObject = action.payload.user
        userObject.subscription = action.payload.subscription

        const isFreeUser = userObject.subscription.is_free === true
        const hasFullAccess =
          userObject.user_type !== userRoles.user ||
          userObject.subscription.subscription_taken === true ||
          userObject.subscription.trial_completed === false ||
          userObject.subscription.subscription_expire === false ||
          isFreeUser

        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.userInfo = userObject
        state.role = action.payload.user.user_type
        state.error = null
        state.isAuthenticated = true

        state.selectedPlanOnSignUp = {
          id: 0,
          price: 0,
          type: '',
        }

        state.hasFullAccess = hasFullAccess
        state.isFreeUser = isFreeUser

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(userObject))
        localStorage.setItem(localStorageKeys.role, action.payload.user.user_type)
        localStorage.setItem(localStorageKeys.hasFullAccess, JSON.stringify(hasFullAccess))
        localStorage.setItem(localStorageKeys.isFreeUser, JSON.stringify(isFreeUser))
      })
      .addCase(loginUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        state.selectedPlanOnSignUp = {
          id: 0,
          price: 0,
          type: '',
        }

        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })

      // register
      .addCase(registerUser.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.error = null
        state.isAuthenticated = false
      })
      .addCase(registerUser.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })

      // verify email otp
      .addCase(verifyEmailOtp.pending, (state) => {
        state.loading = true
        state.error = null
        state.token = null
        state.role = null
        state.userInfo = null
        state.success = false
        state.isAuthenticated = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })
      .addCase(verifyEmailOtp.fulfilled, (state, action) => {
        const userObject = action.payload.user
        userObject.subscription = action.payload.subscription

        const hasFullAccess =
          userObject.user_type !== userRoles.user ||
          userObject.subscription.subscription_taken === true ||
          userObject.subscription.trial_completed === false ||
          userObject.subscription.subscription_expire === false

        state.loading = false
        state.success = true
        state.token = action.payload.token
        state.userInfo = userObject
        state.role = action.payload.user.user_type
        state.error = null
        state.isAuthenticated = true

        state.hasFullAccess = hasFullAccess

        localStorage.setItem(localStorageKeys.accessToken, action.payload.token)
        localStorage.setItem(localStorageKeys.user, JSON.stringify(userObject))
        localStorage.setItem(localStorageKeys.role, action.payload.user.user_type)
        localStorage.setItem(localStorageKeys.hasFullAccess, JSON.stringify(hasFullAccess))
      })
      .addCase(verifyEmailOtp.rejected, (state, action: any) => {
        state.loading = false
        state.error = action.payload
        state.token = null
        state.userInfo = null
        state.role = null
        state.success = false
        state.isAuthenticated = false
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
      })

      // resend email otp
      .addCase(resendEmailOtp.pending, (state) => {
        state.loading = true
      })
      .addCase(resendEmailOtp.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(resendEmailOtp.rejected, (state, action: any) => {
        state.loading = false
      })

      // logout
      .addCase(logoutUser.pending, (state) => {
        state.loading = true
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.showTrialStartPopPop)
        localStorage.removeItem(localStorageKeys.hasFullAccess)
        localStorage.removeItem(localStorageKeys.isFreeUser)
        state.loading = false
        state.success = false
        state.token = null
        state.userInfo = null
        state.error = null
        state.isAuthenticated = false
        state.showTrialStartPopUp = false
      })
      .addCase(logoutUser.rejected, (state, action: any) => {
        localStorage.removeItem(localStorageKeys.accessToken)
        localStorage.removeItem(localStorageKeys.user)
        localStorage.removeItem(localStorageKeys.role)
        localStorage.removeItem(localStorageKeys.showTrialStartPopPop)
        localStorage.removeItem(localStorageKeys.hasFullAccess)
        localStorage.removeItem(localStorageKeys.isFreeUser)
        state.loading = false
        state.success = false
        state.token = null
        state.userInfo = null
        state.error = null
        state.isAuthenticated = false
        state.showTrialStartPopUp = false
      })
  },
})
export const {
  setAuthErrorReducer,
  setShowTrialStartPopUp,
  setSelectedPlanOnSignUp,
  setHasFullAccess,
} = authSlice.actions

export default authSlice.reducer
