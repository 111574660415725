import React, { useState } from 'react'
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import logo from 'src/assets/images/logo.png'
import InputField from 'src/Component/InputField'
import styles from '../index.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'

import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorToast, successToast } from 'src/utils/toastService'
import { sendVerificationLink } from 'src/store/features/forgotPassword/forgotPasswordAction'

const formSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [isMailSent, setIsMailSent] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  })

  type IFormInput = {
    email: string
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      sendVerificationLink({
        email: data.email,
      }),
    ).then((res: any) => {
      if (res.error) {
        errorToast(res.payload)
      } else {
        successToast('Password reset link has been sent to the registered email address.')
        setIsMailSent(true)
      }
    })
  }

  return (
    <Grid container justifyContent='center' alignItems='center' height='100%'>
      <Grid item p={1} className={styles.forgotPasswordPadding}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={styles.forgotPasswordContainer}>
            <Box textAlign='center'>
              <Typography variant='h2' pt={2}>
                Forgot Password?
              </Typography>
              <Typography variant='subtitle1' mt={1}>
                Reset password in quick steps
              </Typography>
            </Box>

            <Box mt={3}>
              <Box mt={2}>
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <InputField
                        label='Enter registered email'
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        type='text'
                        placeholder='Example@gmail.com'
                        error={!!errors.email}
                        required
                      />
                      {!!errors.email && (
                        <Typography variant='subtitle2' color='red'>
                          {errors.email.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Button type='submit' variant='contained' color='primary' fullWidth>
                  Send verification link
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Box>

              {isMailSent && (
                <Typography variant='subtitle1' mt={3} color='red'>
                  Verification link has been sent to your inbox. Please click on verification link
                  to reset password.
                </Typography>
              )}
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
