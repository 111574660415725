import React, { useEffect } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getPrivacyPolicyContent } from 'src/store/features/staticPageContent/staicPageContentAction'
const PrivacyPolicy = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { privacyPolicyData } = useSelector((state: RootState) => state.staticPageContent)

  useEffect(() => {
    dispatch(getPrivacyPolicyContent())
  }, [])

  return (
    <Box>
      <Typography variant='h2' fontWeight={400}>
        PRIVACY POLICY
      </Typography>

      <Typography mt={2} variant='subtitle1'>
        {privacyPolicyData}
      </Typography>
    </Box>
  )
}

export default PrivacyPolicy
