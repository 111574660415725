import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import styles from './index.module.scss'
import { SubscriptionI } from 'src/api/admin/manageSubscription/types'
type ManageSubscriptionRow = {
  subscription: SubscriptionI
  onDelete: (id: number) => void
  onEdit: (data: {
    subscriptionId: number
    type: string
    price: number
    description: string
  }) => void
}
const ManageSubscriptionRow = ({ subscription, onDelete, onEdit }: ManageSubscriptionRow) => {
  return (
    <Box className={styles.wrapper}>
      <Grid container alignItems='center'>
        <Grid xs={12} sm={12} md={2} p={1}>
          <Typography variant='h3' className='capitalize'>
            {subscription.subscription_type}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} md={4} p={1}>
          <Typography variant='body2' className={styles.description} >{subscription.subscription_body}</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={2.5} p={1}>
          <Typography variant='h1'> ${subscription.price}</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={2} p={1}>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            fullWidth
            onClick={() => onDelete(subscription.id)}
          >
            Delete
          </Button>
        </Grid>
        <Grid xs={12} sm={12} md={1.5} p={1}>
          <Button
            type='button'
            variant='outlined'
            color='primary'
            fullWidth
            onClick={() =>
              onEdit({
                subscriptionId: subscription.id,
                type: subscription.subscription_type,
                price: subscription.price,
                description: subscription.subscription_body,
              })
            }
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ManageSubscriptionRow
