/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react'
import { Box, Grid, Typography, Button, IconButton } from '@mui/material'
import styles from '../index.module.scss'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import RichTextEditor from 'src/Component/RichTextEditor'
import PdfUpload from 'src/Component/PdfUpload'
import PdfPreview from 'src/Component/PdfPreview'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import {
  useGetPostByIdQuery,
  useUpdatePostByIdMutation,
  useDeletePostFileByIdMutation,
  useFetchPostAttachmentSectionsQuery,
  useUpdatePostAttachmentSectionMutation,
  useCreatePostAttachmentSectionMutation,
  useDeletePostSectionByIdMutation,
} from 'src/api/admin/postApi'
import { errorToast, successToast } from 'src/utils/toastService'
import { useNavigate, useParams } from 'react-router-dom'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'
import ImagePreview from 'src/Component/ImagePreview'
import ImageUpload from 'src/Component/ImageUpload'
import GroupButton from 'src/Component/GroupButton'
import { POST_STATUS } from 'src/utils/constants'
import { PostFile, PostStatusI } from 'src/api/admin/postApi/types'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import MultiSelectDropdown from 'src/Component/MultiSelectDropdown'
import { ADDRESSING_CONTENT, ADDRESSING_CONTENT_DEAFULT_TEXT, ADDRESSING_CONTENT_MESSAGE } from 'src/Page/Private/const'

type IFormInput = {
  title: string
  category: any[]
  description: string
  content: string
  welcome_message: string
}

type IAttachmentSection = {
  sectionId?: string
  title: string
  description: string
  pdfs: File[]
  images: File[]
  existingPdfs: PostFile[]
  existingImages: PostFile[]
}

const formSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  category: Yup.array().min(1, 'Category is required').required('Category is required'),
  description: Yup.string().required('Description is required'),
  content: Yup.string().required('Content is required'),
  welcome_message: Yup.string().required(ADDRESSING_CONTENT_MESSAGE),
})

type EditPostProps = {
  //
}

const EditPost: React.FC<EditPostProps> = () => {
  const [filestoBeDeletedFromBackend, setFilestoBeDeletedFromBackend] = React.useState<number[]>([])
  const [sectionsToBeDeletedFromBackend, setSectionsToBeDeletedFromBackend] = React.useState<
    string[]
  >([])

  const [deletePostFile] = useDeletePostFileByIdMutation()
  const [deleteSectionFromBackend] = useDeletePostSectionByIdMutation()
  const publishRef = useRef<boolean>(false)
  const previewRef = useRef<boolean>(false)
  const [activePostStatusIndex, setActivePostStatusIndex] = useState<number>(0)
  const postStatusRef = useRef<PostStatusI>('pending')

  const formRef = useRef<HTMLFormElement>(null)

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const [attachmentSections, setAttachmentSections] = useState<IAttachmentSection[]>([
    {
      title: '',
      description: '',
      pdfs: [],
      images: [],
      existingPdfs: [],
      existingImages: [],
    },
  ])

  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  const { postId } = useParams<{ postId: string }>()
  const postIdNumber = postId ? parseInt(postId, 10) : 0
  const [updatePostById] = useUpdatePostByIdMutation()
  const [updatePostAttachmentSection] = useUpdatePostAttachmentSectionMutation()
  const [createPostAttachmentSection] = useCreatePostAttachmentSectionMutation()

  const { data: post, isLoading } = useGetPostByIdQuery(postIdNumber)

  const { data: attachmentSectionResponse } = useFetchPostAttachmentSectionsQuery(postIdNumber)
  const [category, setCategory] = useState<any>([])

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm<IFormInput>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      title: '',
      category: [],
      description: '',
      content: '',
      welcome_message: ADDRESSING_CONTENT_DEAFULT_TEXT,
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    // return
    const formData = new FormData()
    formData.append('category', JSON.stringify(data.category))
    formData.append('title', data.title)
    formData.append('short_description', data.description)
    formData.append('description', data.content)
    formData.append('post_to_landing_page', JSON.stringify(publishRef.current))
    formData.append('preview', JSON.stringify(previewRef.current))
    formData.append('welcome_message', data.welcome_message)

    if (publishRef.current) {
      // publish
      formData.append('post_status', POST_STATUS[2].value) // completed
    } else {
      // draft
      formData.append('post_status', postStatusRef.current)
    }

    dispatch(startPageLoader())

    updatePostById({ postId: postIdNumber, data: formData })
      .unwrap()
      .then((res) => {
        const promises: Promise<any>[] = []
        attachmentSections.forEach((section) => {
          promises.push(
            new Promise((resolve, reject) => {
              const data = new FormData()
              data.append('postId', postIdNumber.toString())
              data.append('title', section.title)
              data.append('description', section.description)
              section.pdfs.forEach((file) => {
                data.append('files', file)
              })
              section.images.forEach((file) => {
                data.append('files', file)
              })
              if (section.sectionId) {
                updatePostAttachmentSection({
                  sectionId: section.sectionId || '',
                  data,
                })
                  .unwrap()
                  .then((res) => resolve(res))
                  .catch((err) => reject(err))
              } else {
                createPostAttachmentSection(data)
                  .unwrap()
                  .then((res) => resolve(res))
                  .catch((err) => reject(err))
              }
            }),
          )
        })
        Promise.all(promises)
          .catch((err) => {
            errorToast(err?.data?.error || 'Something went wrong')
          })
          .finally(async () => {
            filestoBeDeletedFromBackend.forEach(async (fileId) => {
              const payload = {
                postId: postIdNumber,
                fileId: fileId,
              }

              await deletePostFile(payload)
            })

            sectionsToBeDeletedFromBackend.forEach(async (id) => {
              await deleteSectionFromBackend(id)
            })

            let msg = ''
            if (previewRef.current === true) {
              // preview
              msg = 'Email preview sent successfully.'
            } else if (publishRef.current) {
              // published
              msg = 'Post published successfully.'
            } else {
              // save post
              msg = 'Post saved successfully.'
            }
            successToast(msg)
            dispatch(stopPageLoader())
            if (!previewRef.current) {
              navigate(-1)
            }
          })
      })
      .catch((err: any) => {
        errorToast(err?.data?.error || 'Something went wrong')
        dispatch(stopPageLoader())
      })
    // .finally(() => {
    //   dispatch(stopPageLoader())
    // })
  }

  const handleFileOpen = (url: string) => {
    window.open(url, '_blank')
  }

  const navigateToBack = () => {
    navigate(-1)
  }

  const handleAddSection = () => {
    setAttachmentSections((prevState) => [
      ...prevState,
      {
        title: '',
        description: '',
        pdfs: [],
        images: [],
        existingPdfs: [],
        existingImages: [],
      },
    ])
  }

  const handleRemoveSection = (sectionIndex: number) => {
    const sectionId = attachmentSections?.[sectionIndex]?.sectionId || ''
    if (sectionId) {
      setSectionsToBeDeletedFromBackend((toBeDeleted) => [...toBeDeleted, sectionId])
    }
    setAttachmentSections((prevState) => prevState.filter((section, i) => i !== sectionIndex))
  }

  const handleAttachmentSectionInput = (
    event: any,
    sectionIndex: number,
    type: 'title' | 'description',
  ) => {
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = event?.target?.value
    setAttachmentSections(newState)
  }

  const handleAddFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    sectionIndex: number,
    type: 'pdfs' | 'images',
  ) => {
    console.log('setAttachmentSections')

    if (event?.target?.files?.[0]) {
      const newState = [...attachmentSections]
      newState[sectionIndex][type] = [...newState[sectionIndex][type], event.target.files[0]]
      console.log(JSON.stringify(newState))
      setAttachmentSections(newState)
    }
  }

  const handleRemoveFile = (sectionIndex: number, index: number, type: 'pdfs' | 'images') => {
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = newState[sectionIndex][type].filter((file, i) => i !== index)
    setAttachmentSections(newState)
  }

  const handleFileDeleteFromBackend = (
    sectionIndex: number,
    id: number,
    type: 'existingPdfs' | 'existingImages',
  ) => {
    setFilestoBeDeletedFromBackend((toBeDeletedPdfFromBackend) => [
      ...toBeDeletedPdfFromBackend,
      id,
    ])
    const newState = [...attachmentSections]
    newState[sectionIndex][type] = newState[sectionIndex][type].filter((file) => file.id !== id)
    setAttachmentSections(newState)
  }

  const handlePublish = () => {
    previewRef.current = false
    publishRef.current = true
    formRef.current?.requestSubmit()
  }

  const handlePreview = () => {
    publishRef.current = false
    previewRef.current = true
    formRef.current?.requestSubmit()
  }

  const handleSave = (postStatus: string) => {
    publishRef.current = false
    previewRef.current = false
    postStatusRef.current = postStatus as PostStatusI

    formRef.current?.requestSubmit()
  }

  const handleOpenPdf = (url: string) => {
    window.open(url, '_blank')
  }

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value)
  }

  useEffect(() => {
    if (post) {
      setValue('title', post.title)
      if (post && post.category) {
        setValue('category', post.category)
        setCategory(post.category)
      }
      setValue('description', post.short_description)
      setValue('content', post.description)
      if(post.welcome_message){
        setValue('welcome_message', post.welcome_message)
      }
      setActivePostStatusIndex(() => {
        for (let i = 0; i < POST_STATUS.length; i++) {
          if (POST_STATUS[i].value === post.post_status) {
            postStatusRef.current = post.post_status
            return i
          }
        }
        return 0
      })
    }
  }, [post, setValue])

  useEffect(() => {
    if (attachmentSectionResponse?.length) {
      const data: IAttachmentSection[] = attachmentSectionResponse.map((section) => {
        return {
          title: section.title,
          description: section.description,
          existingPdfs: section.pdf,
          existingImages: section.img,
          pdfs: [],
          images: [],
          sectionId: section.section,
        }
      })
      console.log('data', data)

      setAttachmentSections(data)
    }
  }, [attachmentSectionResponse])

  useEffect(() => {
    if (isLoading) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isLoading])
  return (
    <Box className='cardWrapper'>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Grid container justifyContent='space-between'>
          <Typography variant='h1' color='black' style={{marginBottom:'5px'}}>
            Detail Post
          </Typography>
          <Box className={styles.buttonFlex}>
            <Button
              className={styles.cancelBtn}
              type='button'
              variant='outlined'
              color='primary'
              onClick={navigateToBack}
            >
              Cancel
            </Button>
            <Button
              className={styles.publishBtn}
              type='button'
              variant='outlined'
              color='primary'
              fullWidth
              id='publish'
              onClick={handlePublish}
            >
              Save & Publish
            </Button>
            <Button
            onClick={handlePreview}
            className={styles.publishBtn}
            type='button'
            variant='outlined'
            color='primary'
            fullWidth
            id='emailPreview'
        >
            Email Preview
          </Button>

            <GroupButton
              onClick={(selected) => handleSave(selected.value)}
              options={POST_STATUS}
              label='Select Draft Status'
              activeIndex={activePostStatusIndex}
            />
          </Box>
        </Grid>

        <Box>
          <Box mt={2}>
            <Controller
              name='title'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    label='Title'
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    type='text'
                    error={!!errors.title}
                  />

                  {!!errors.title && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.title.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

          <Box mt={2}>
            <Controller
              name='category'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <MultiSelectDropdown
                    label={'Category'}
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    options={METAL_CATEGORIES.map(({ id, category }) => ({
                      label: category,
                      value: id,
                    }))}
                    // placeholder='Select category'
                    error={!!errors.category}
                  />

                  {!!errors.category && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.category.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

          <Box mt={2}>
            <Controller
              name='welcome_message'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    label={ADDRESSING_CONTENT}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    type='text'
                    error={!!errors.title}
                  />
                  {!!errors.welcome_message && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.welcome_message.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>

          <Box mt={2}>
            <Controller
              name='description'
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <InputField
                    label='Description'
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    type='text'
                    error={!!errors.description}
                    multiline
                    rows={4}
                  />
                  {!!errors.description && (
                    <Typography variant='subtitle2' color='red'>
                      {errors.description.message}
                    </Typography>
                  )}
                </>
              )}
            />
          </Box>
        </Box>

        <Box mt={2}>
          <Controller
            name='content'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <RichTextEditor
                  data={field.value}
                  onChange={(data: string) => {
                    setValue('content', data)
                  }}
                  hideTitle={true}
                />

                {!!errors.content && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.content.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Typography variant='h3'>Attachments</Typography>
          <Box mt={2}>
            {attachmentSections.map((section, sectionIndex) => (
              <Box
                key={'attachmentSections' + sectionIndex}
                mt={2}
                className={styles.attachmentSectionWrapper}
              >
                {sectionIndex > 0 && (
                  <Box className={styles.closeIconWrapper}>
                    <IconButton color='error' onClick={() => handleRemoveSection(sectionIndex)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
                <Box>
                  <InputField
                    label='Title'
                    name={`attachmentSection-${sectionIndex}-title`}
                    value={section.title}
                    onChange={(e: any) => handleAttachmentSectionInput(e, sectionIndex, 'title')}
                    type='text'
                    placeholder={`Section ${sectionIndex + 1} Title`}
                  />
                </Box>
                <Box mt={2}>
                  <InputField
                    label='Description'
                    name={`attachmentSection-${sectionIndex}-description`}
                    value={section.description}
                    onChange={(e: any) =>
                      handleAttachmentSectionInput(e, sectionIndex, 'description')
                    }
                    type='text'
                    placeholder={`Section ${sectionIndex + 1} Description`}
                    multiline
                    rows={3}
                  />
                </Box>
                <Box>
                  <Box mt={2}>
                    <Box>
                      <>
                        <PdfUpload
                          value={section.pdfs}
                          label='Add PDFs'
                          name={'files'}
                          onChange={(e) => handleAddFile(e, sectionIndex, 'pdfs')}
                        />
                      </>
                    </Box>
                    <Grid container justifyContent='center'>
                      {section.pdfs &&
                        section.pdfs.map((file, index) => {
                          return (
                            <PdfPreview
                              key={`PDF_new_${sectionIndex}_${index}`}
                              file={file}
                              canDelete={true}
                              onDelete={() => handleRemoveFile(sectionIndex, index, 'pdfs')}
                            />
                          )
                        })}

                      {section.existingPdfs &&
                        section.existingPdfs.map((file, index) => {
                          if (!file.file_url) return null
                          return (
                            <PdfPreview
                              key={`PDF_existing_${sectionIndex}_${index}`}
                              url={file.file_url}
                              canDelete={true}
                              onDelete={() =>
                                handleFileDeleteFromBackend(sectionIndex, file.id, 'existingPdfs')
                              }
                            />
                          )
                        })}
                    </Grid>
                  </Box>

                  <Box mt={2}>
                    <Box>
                      <>
                        <ImageUpload
                          value={section.images}
                          label='Add Images'
                          name={'images'}
                          onChange={(e) => handleAddFile(e, sectionIndex, 'images')}
                        />
                      </>
                    </Box>
                    <Grid container justifyContent='center'>
                      {section.images &&
                        section.images.map((file, index) => {
                          return (
                            <ImagePreview
                              key={`IMAGE_new_${sectionIndex}_${index}`}
                              file={file}
                              canDelete={true}
                              onDelete={() => handleRemoveFile(sectionIndex, index, 'images')}
                            />
                          )
                        })}

                      {section.existingImages &&
                        section.existingImages.map((file, index) => {
                          if (!file.file_url) return null

                          return (
                            <ImagePreview
                              key={`IMAGE_existing_${sectionIndex}_${index}`}
                              url={file.file_url}
                              canDelete={true}
                              onDelete={() =>
                                handleFileDeleteFromBackend(sectionIndex, file.id, 'existingImages')
                              }
                            />
                          )
                        })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          <Box mt={2}>
            <Grid container justifyContent='end'>
              <Button variant='outlined' onClick={handleAddSection}>
                <AddIcon /> Add Section
              </Button>
            </Grid>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default EditPost
