/* eslint-disable camelcase */
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH, localStorageKeys } from 'src/utils/constants'
import {
  StartSubscriptionPayload,
  SubscriptionI,
  UserSubscriptionI,
  PaymentDetails,
  DownloadFullInvoicResponseObject,
  DownloadSingleInvoiceResponse,
  DownloadSingleInvoicePayload,
  FilterPayload,
} from './types'

const userSubscriptionApi = createApi({
  reducerPath: 'userSubscriptionApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['userSubscriptionApi'],
  endpoints: (builder) => ({
    fetchSubscriptionPlan: builder.query<SubscriptionI[], void>({
      query: () => ({
        url: '/api/v1/subscription-plans/',
        method: 'GET',
      }),
    }),

    fetchUserSubscription: builder.query<UserSubscriptionI, void>({
      query: () => ({
        url: '/api/v1/subscription-status/',
        method: 'GET',
      }),
      transformResponse: (res: UserSubscriptionI) => {
        let user: any = localStorage.getItem(localStorageKeys.user)
        if (user) {
          user = JSON.parse(user)
          user.subscription = res
          localStorage.setItem(localStorageKeys.user, JSON.stringify(user))
        }
        return res
      },
    }),

    startSubscription: builder.mutation<void, StartSubscriptionPayload>({
      query: ({ subscriptionId, paymentMethodId }) => ({
        url: '/api/v1/subscription-checkout/',
        method: 'POST',
        data: {
          subscription_id: subscriptionId,
          payment_method_id: paymentMethodId,
        },
      }),
    }),

    checkPaymentStatus: builder.query<void, void>({
      query: () => ({
        url: '/api/v1/validateSubscription/',
        method: 'GET',
      }),
    }),

    fetchPaymentHistory: builder.query<PaymentDetails[], FilterPayload>({
      query: ({ fromDate, toDate }) => ({
        url: `/api/v1/payment-history/?${fromDate ? 'fromDate=' + fromDate : ''}${
          toDate ? '&toDate=' + toDate : ''
        }`,
        method: 'GET',
      }),
    }),
    downloadFullInvoicReport: builder.mutation<DownloadFullInvoicResponseObject, void>({
      query: () => ({
        url: '/api/v1/downloadFullInvoiceReport/',
        method: 'GET',
      }),
    }),
    downloadSingleInvoice: builder.mutation<
      DownloadSingleInvoiceResponse,
      DownloadSingleInvoicePayload
    >({
      query: ({ invoice_id }) => ({
        url: '/api/v1/downloadInvoice/',
        method: 'POST',
        data: {
          invoice_id: invoice_id,
        },
      }),
    }),

    stopSubscription: builder.mutation<void, void>({
      query: () => ({
        url: '/api/v1/cancelSubscription/',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useFetchSubscriptionPlanQuery,
  useStartSubscriptionMutation,
  useLazyCheckPaymentStatusQuery,
  useFetchUserSubscriptionQuery,
  useFetchPaymentHistoryQuery,
  useDownloadFullInvoicReportMutation,
  useDownloadSingleInvoiceMutation,
  useStopSubscriptionMutation,
} = userSubscriptionApi
export default userSubscriptionApi
