import React from 'react'

import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor' // Import the ClassicEditor type

import './index.scss'
import { API_BASE_PATH } from 'src/utils/constants'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

type RichTextEditorProps = {
  data: string
  onChange: (data: string) => void
  hideTitle?: boolean
}

const RichTextEditor = ({ data, onChange, hideTitle }: RichTextEditorProps) => {
  const { token } = useSelector((state: RootState) => state.auth)
  return (
    <CKEditor
      editor={Editor}
      config={{
        simpleUpload: {
          uploadUrl: `${API_BASE_PATH}/api/v1/generateImageUrl/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        removePlugins: ['Title'],
        link: {
          addTargetToExternalLinks: true,
        },
      }}
      data={data}
      onReady={(editor: any) => {
        // You can store the "editor" and use when it is needed.
        if (editor?.ui?.view?.editable?.element) {
          editor.ui.view.editable.element.style.minHeight = '500px'
        }
      }}
      onChange={(event: any, editor: ClassicEditor) => {
        onChange(editor.getData())
      }}
      // onBlur={(event: any, editor: any) => {
      //   console.log('Blur.', editor)
      // }}
      // onFocus={(event: any, editor: any) => {
      //   console.log('Focus.', editor)
      // }}
    />
  )
}

export default RichTextEditor
