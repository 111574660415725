import React, { useEffect } from 'react'
import { Box, Grid, Typography, Chip, Button, Divider } from '@mui/material'
import PdfPreview from 'src/Component/PdfPreview'
import { AppDispatch, RootState } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import calendarIcon from 'src/assets/icons/calendar.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetPostByIdQuery, useSetBookmarkMutation } from 'src/api/user/postApi'
import RenderContent from 'src/Component/RichTextEditor/RenderContent'
import styles from './index.module.scss'
import dailyIcon from 'src/assets/icons/daily.png'
import manualIcon from 'src/assets/icons/manual.png'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import BookmarkIcon from 'src/assets/icons/svg/BookmarkIcon'
import { routeUrls } from 'src/utils/constants'
import ImagePreview from 'src/Component/ImagePreview'
import { useFetchPostAttachmentSectionsQuery } from 'src/api/admin/postApi'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'

const ViewPostUserSide = () => {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { hasFullAccess } = useSelector((state: RootState) => state.auth)

  const { postId } = useParams<{ postId: string }>()
  const postIdNumber = postId ? parseInt(postId, 10) : 0

  const { data: post, isFetching, refetch } = useGetPostByIdQuery(postIdNumber)

  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  console.log('cat', post?.category)

  const { data: attachmentSectionResponse } = useFetchPostAttachmentSectionsQuery(postIdNumber, {
    refetchOnMountOrArgChange: true,
  })

  const [setBookmark, { isLoading: isUpdatingBookmark }] = useSetBookmarkMutation()

  const addToBookmark = () => {
    setBookmark({
      postId: postIdNumber,
      bookmarked: true,
    })
      .unwrap()
      .then((res) => {
        refetch()
      })
  }

  const removeFromBookmark = () => {
    setBookmark({
      postId: postIdNumber,
      bookmarked: false,
    })
      .unwrap()
      .then((res) => {
        refetch()
      })
  }

  const handleFileOpen = (url: string) => {
    window.open(url, '_blank')
  }

  const getBookmarkButton = () => {
    if (post?.bookmarked) {
      return (
        <Button variant='contained' onClick={removeFromBookmark}>
          <BookmarkIcon color='white' /> Bookmarked
        </Button>
      )
    }

    return (
      <Button variant='outlined' onClick={addToBookmark}>
        <BookmarkIcon /> Bookmark
      </Button>
    )
  }

  useEffect(() => {
    if (isFetching || isUpdatingBookmark) dispatch(startPageLoader())
    else dispatch(stopPageLoader())
  }, [isFetching, isUpdatingBookmark])

  useEffect(() => {
    if (hasFullAccess === false) {
      navigate(routeUrls.user.home)
    }
  }, [hasFullAccess])

  if (!post) return null

  return (
    <Box className={styles.postWrapper} mt={3}>
      <Box display='flex' justifyContent='end' mb={2}>
        {getBookmarkButton()}
      </Box>
      <Grid
        container
        justifyContent='space-between'
        alignItems='baseline'
        className={styles.titleContainer}
      >
        <Grid item xs={9} className={styles.titlePadding}>
          <Typography variant='h2' className={styles.titleFont}>{post?.title}</Typography>
        </Grid>

        <Grid
          item
          xs={3}
          display='flex'
          alignItems='center'
          justifyContent='end'
          className={styles.dateContainer}
        >
          <img src={calendarIcon} /> &nbsp;
          <span> {dayjs(post?.created_at).format('MMM DD, YYYY')} </span>
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={1}>
        {post && post?.is_manual && (
          <Grid item>
            <Chip
              label={
                <Grid display='flex' alignItems='center' justifyContent='center'>
                  <img src={manualIcon} /> <span> &nbsp; Thematic</span>
                </Grid>
              }
              color='warning'
            />
          </Grid>
        )}

        {post && !post?.is_manual && (
          <Grid item>
            <Chip
              label={
                <Grid display='flex' alignItems='center' justifyContent='center'>
                  <img src={dailyIcon} /> <span> &nbsp; Daily</span>
                </Grid>
              }
              color='success'
            />
          </Grid>
        )}

        {METAL_CATEGORIES.filter((item) => post?.category?.includes(item.id))?.map((item) => (
          <Grid p={1} key={item.id}>
            <Chip label={item.category} />
          </Grid>
        ))}
      </Grid>

      <Box pt={5} pb={3}>
        <Typography variant='h5' color='rgba(62, 62, 62, 1)'>
          {post?.short_description}
        </Typography>
      </Box>
      <Box>{post?.description && <RenderContent content={post.description} />}</Box>
      <Box>
        {!!attachmentSectionResponse?.length && (
          <>
            <Divider />
            <Typography variant='h3' mt={2}>
              Attachments
            </Typography>
          </>
        )}
        {attachmentSectionResponse?.map((section, i) => {
          return (
            <Box key={section.section} mt={2}>
              <Typography variant='h4'>
                {' '}
                {i + 1}
                {')'} {section.title}{' '}
              </Typography>
              <Typography variant='h6' color='rgba(62, 62, 62, 1)'>
                {section.description}
              </Typography>
              <Grid container justifyContent='center'>
                {section.pdf.map((file) => {
                  return (
                    <PdfPreview
                      key={file.id}
                      url={file.file_url}
                      onOpenPdf={() => handleFileOpen(file.file_url)}
                    />
                  )
                })}
              </Grid>

              <Grid container justifyContent='center'>
                {section.img.map((file) => {
                  return (
                    <ImagePreview
                      key={file.id}
                      url={file.file_url}
                      onOpenImage={() => handleFileOpen(file.file_url)}
                    />
                  )
                })}
              </Grid>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default ViewPostUserSide
