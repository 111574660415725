import React, { useEffect } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { getTermAndConditionContent } from 'src/store/features/staticPageContent/staicPageContentAction'

const TermAndCondition = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { termAndConditionData } = useSelector((state: RootState) => state.staticPageContent)

  useEffect(() => {
    dispatch(getTermAndConditionContent())
  }, [])
  return (
    <Box>
      <Typography variant='h2' fontWeight={400}>
        TERMS AND CONDITIONS
      </Typography>

      <Typography mt={2} variant='subtitle1'>
        {termAndConditionData}
      </Typography>
    </Box>
  )
}

export default TermAndCondition
