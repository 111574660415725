import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'

import PostPreview from 'src/Component/PostPreview'
import SearchInput from 'src/Component/SearchInput'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import SortDropdown from 'src/Component/SortDropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import { POST_STATUS, routeUrls } from 'src/utils/constants'
import { useLazyFetchPostQuery } from 'src/api/admin/postApi'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { PostI, PostStatusI } from 'src/api/admin/postApi/types'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import DeletePost from '../Post/DeletePost'
import { Dayjs } from 'dayjs'
import BasicDatePicker from 'src/Component/BasicDatePicker'
import { errorToast } from 'src/utils/toastService'
import styles from './index.module.scss'

const sortOptions = [
  {
    value: 'created_at-desc',
    label: 'Latest',
  },
  {
    value: 'created_at-asc',
    label: 'Oldest',
  },
  {
    value: 'title-asc',
    label: 'Title - Asc',
  },
  {
    value: 'title-desc',
    label: 'Title - Desc',
  },
  {
    value: 'popularity-desc',
    label: 'Popularity',
  },
]

const AdminHome = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const pageRef = useRef<number>(1)
  const [paginatedData, setPaginatedData] = useState<PostI[]>([])
  const [search, setSearch] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [categorySelected, setCategorySelected] = useState<string>('')
  const [postStatus, setPostStatus] = useState<string>('')
  const [fromDate, setFromDate] = useState<Dayjs | null>(null)
  const [toDate, setToDate] = useState<Dayjs | null>(null)

  const [sort, setSort] = useState<any>(sortOptions[0].value)
  const [fetchPost, { data: POST_RESPONSE }] = useLazyFetchPostQuery()
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deletePostId, setDeletePostId] = useState<number>(0)
  const { isPageLoading } = useSelector((state: RootState) => state.common)
  console.log('catId#', location?.state?.categorySelected)

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const onRemoveClicked = (postId: number) => {
    setOpenDeleteModal(true)
    setDeletePostId(postId)
  }
  useEffect(() => {
    setCategorySelected(location?.state?.categorySelected)
  }, [])

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value)
  }
  const handlePostStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostStatus(e.target.value)
  }

  useEffect(() => {
    setCategorySelected(location?.state?.categoryId)
    setCategory(location?.state?.categoryId)
  }, [])

  const handleFilter = () => {
    if (fromDate && toDate) {
      if (fromDate.isAfter(toDate)) {
        errorToast('From date and To date are incorrect.')
        return
      }
    }
    setCategorySelected('')
    fetchData(true)
  }

  const handleSortDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    setSort(e.target.value)
  }
  const navigateToCreatePost = () => {
    navigate(routeUrls.admin.createPost)
  }

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }
  const refetchPostList = () => {
    fetchData(true)
  }

  const fetchData = (isFilterResult = false): void => {
    if (isFilterResult) {
      pageRef.current = 1
    }

    const sortArr = sort.split('-')
    dispatch(startPageLoader())
    fetchPost({
      pageNumber: pageRef.current,
      searchBy: search,
      category: category || categorySelected,
      postStatus: postStatus,
      sortBy: sortArr[0] || '',
      sortDir: sortArr[1] || '',
      fromDate: fromDate ? fromDate.format('YYYY-MM-DD') : '',
      toDate: toDate ? toDate.format('YYYY-MM-DD') : '',
    })
      .unwrap()
      .then((res) => {
        if (isFilterResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.data))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.data)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  useEffect(() => {
    if (sort) {
      fetchData(true)
    }
  }, [sort])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (categorySelected) {
      setCategory('')
      fetchData(true)
    }
  }, [categorySelected])

  return (
    <Box>
      <Box className={`cardWrapper ${styles.wrapper}`}>
        <Typography variant='h1'>Content Management</Typography>
        <Grid container mt={1}>
          <Grid xs={12} sm={12} md={4} p={1}>
            <SearchInput onChange={setSearch} />
          </Grid>

          <Grid xs={12} sm={12} md={4} p={1}>
            <SimpleDropdown
              id={'category'}
              name={''}
              value={category || categorySelected || ''}
              onChange={handleCategoryChange}
              options={METAL_CATEGORIES.map(({ id, category }) => ({
                label: category,
                value: id,
              }))}
              placeholder='Select Metal Category'
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} p={1}>
            <SimpleDropdown
              id={'postStatus'}
              name={''}
              value={postStatus}
              onChange={handlePostStatusChange}
              options={POST_STATUS}
              placeholder='Select Post Status'
            />
          </Grid>
        </Grid>

        <Grid container mt={1}>
          <Grid xs={12} sm={12} md={4} p={1}>
            <BasicDatePicker
              value={fromDate}
              onChange={setFromDate}
              placeholder='From Date'
              disableFuture
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} p={1}>
            <BasicDatePicker
              value={toDate}
              onChange={setToDate}
              placeholder='To Date'
              disableFuture
            />
          </Grid>

          <Grid xs={12} sm={12} md={4} p={1}>
            <Button
              fullWidth
              type='button'
              variant='outlined'
              color='primary'
              onClick={handleFilter}
            >
              Apply filter
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container p={'24px'} className={styles.createPostWrapper}>
        <Grid xs={12} sm={12} md={4} p={1}>
          <SortDropdown options={sortOptions} value={sort} onChange={handleSortDropdownChange} />
        </Grid>

        <Grid xs={12} sm={12} md={4} p={1}></Grid>

        <Grid xs={12} sm={12} md={4} p={1}>
          <Button
            fullWidth
            type='button'
            variant='contained'
            color='primary'
            onClick={navigateToCreatePost}
          >
            Create Post
          </Button>
        </Grid>
      </Grid>

      <Box className={styles.wrapper}>
        {isPageLoading === false && paginatedData.length === 0 && (
          <Typography variant='subtitle1' textAlign='center'>
            No Result Found
          </Typography>
        )}
        {!!POST_RESPONSE && !!paginatedData.length && (
          <InfiniteScroll
            dataLength={paginatedData.length}
            next={loadNextPage}
            hasMore={POST_RESPONSE.page.has_next}
            loader={
              <Typography variant='subtitle1' textAlign='center'>
                {' '}
                loading more feeds..{' '}
              </Typography>
            }
          >
            {paginatedData.map((post) => {
              return (
                <Box mb={2} key={post.id}>
                  <PostPreview
                    postId={post.id}
                    title={post.title}
                    originalNews={post.original_news}
                    date={post.edited_at}
                    isManual={post.is_manual}
                    isAuto={!post.is_manual}
                    description={post.short_description}
                    category={METAL_CATEGORIES.filter((item) => post.category.includes(item.id))}
                    categorySelected={categorySelected || category}
                    onClickCategoryChip={(value) => setCategorySelected(value)}
                    showEditButton
                    showRemoveButton
                    onDelete={onRemoveClicked}
                    isPublished={!!post.post_to_landing_page}
                    isDraft={!post.post_to_landing_page}
                    postStatus={post.post_status}
                  />
                </Box>
              )
            })}
          </InfiniteScroll>
        )}
      </Box>
      <Box>
        <DeletePost
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          postId={deletePostId}
          refetchPostList={refetchPostList}
        />
      </Box>
    </Box>
  )
}

export default AdminHome
