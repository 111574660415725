import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styles from './index.module.scss'
import logo from 'src/assets/images/logo.png'
import BellIcon from 'src/assets/icons/svg/BellIcon'
import SettingIcon from 'src/assets/icons/svg/SettingIcon'
import hamburger from 'src/assets/icons/hamburger-menu.png'
import close from 'src/assets/icons/menu-close.png'
import { routeUrls } from 'src/utils/constants'
import { NavLink } from 'react-router-dom'
import { isActiveLink } from 'src/utils/helpers'

type navLink = {
  label: string
  url: string
  icon?: JSX.Element
}

type HeaderProps = {
  onDrawerToggle: () => void,
  open: boolean
}
const navLinks: navLink[] = [
  {
    label: 'Settings',
    url: routeUrls.admin.settings,
    icon: <SettingIcon />,
  },
]

const Header = ({onDrawerToggle,open}: HeaderProps) => {
  return (
    <Grid container justifyContent='space-between' className={styles.header} alignItems='center'>
      <Grid item>
        <NavLink to={routeUrls.admin.home}><img src={logo} alt='Logo' height={63} width={63}  /></NavLink>
      </Grid>

      <Grid item>
        <Grid container justifyContent='center' alignItems='center'>
          {navLinks.map((item, i) => (
            <Grid
              key={i}
              item
              className={isActiveLink(item.url) ? styles.header_nav_link_active : styles.menuItem}
              onClick={() => {
                if (open) {
                  onDrawerToggle();
                }
              }}
            >
              <Typography variant='h3' color='inherit' mr={item.icon ? 1 : 0}>
                <NavLink to={item.url}>{item.label}</NavLink>
              </Typography>
              {item.icon}
            </Grid>
          ))}
          <Grid item onClick={onDrawerToggle} className={styles.drawer}>
            {open ? 
            <img src={close}></img>
            :
            <img src={hamburger}></img>
            }
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Header
