/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from 'src/utils/constants'
import styles from '../index.module.scss'
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import {
  useLazyCheckPaymentStatusQuery,
  useStartSubscriptionMutation,
} from 'src/api/user/subscriptionApi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'

import { Box, Typography, Grid, Button, CircularProgress } from '@mui/material'
import { myColors } from 'src/utils/setup/theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

import * as Yup from 'yup'
import InputField from 'src/Component/InputField'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import COUNTRY_LIST from 'src/utils/countryList'
import NewTabLoader from 'src/Component/NewTabLoader'

const pollingTimeout = 120000 // 2 min
const pollingInterval = 5000 // 5 sec
const pollingCounterLimit = pollingTimeout / pollingInterval

const stripePromise = loadStripe(STRIPE_KEY)

type PaymentFormProps = {
  subscriptionId: number
  price: number
  type: string
  onCancel: () => void
}

const PaymentForm = (props: PaymentFormProps) => {
  return (
    <Elements stripe={stripePromise}>
      <InnerPaymentForm {...props} />
    </Elements>
  )
}

type IFormInput = {
  firstName: string
  lastName: string
  address1: string
  address2?: string
  country: string
  state: string
  city: string
  zip: string
}

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  address1: Yup.string().required('Address Line 1 is required'),
  address2: Yup.string(),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  zip: Yup.string().required('Zip code is required'),
})

const InnerPaymentForm = ({ subscriptionId, price, type, onCancel }: PaymentFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<string | null>(null)
  const [paymentStatus, setPaymentStatus] = useState<'completed' | 'failed' | 'pending'>()
  const checkPaymentStatusInterval = useRef<NodeJS.Timer>()
  const paymentWindowRef = useRef<Window | null>(null)
  const pollingCounter = useRef<number>(0)
  const [startSubscriptionApi] = useStartSubscriptionMutation()
  const [checkPaymentStatus] = useLazyCheckPaymentStatusQuery()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zip: '',
    },
  })

  const handlePaymentRetry = () => {
    setPaymentStatus(undefined)
  }

  const handleCheckPaymentStatus = () => {
    checkPaymentStatus()
      .unwrap()
      .then((res) => {
        console.log(res)
        setPaymentStatus('completed')
        clearPollingInterval()
        setTimeout(() => {
          if (paymentWindowRef.current) {
            paymentWindowRef.current.close()
          }
        }, pollingInterval)
      })
      .catch((err) => {
        console.log(err)
        pollingCounter.current += 1
      })
      .finally(() => {
        if (pollingCounter.current === pollingCounterLimit) {
          setPaymentStatus('failed')
          clearPollingInterval()
        }
      })
  }

  const clearPollingInterval = () => {
    if (checkPaymentStatusInterval.current) {
      clearInterval(checkPaymentStatusInterval.current)
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setError('')

    if (!stripe || !elements) {
      return
    }

    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return
    }

    if (!(subscriptionId && price && type)) {
      return
    }

    try {
      dispatch(startPageLoader())
      paymentWindowRef.current = window.open('', '_blank')
      if (!paymentWindowRef.current) {
        setError('Oops, Something went wrong!')
        dispatch(stopPageLoader())
        return
      }
      paymentWindowRef.current?.document.write(NewTabLoader)
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: `${data.firstName} ${data.lastName}`,
          email: userInfo?.email || '',
          address: {
            line1: data.address1,
            line2: data.address2,
            country: data.country,
            state: data.state,
            city: data.city,
            postal_code: data.zip,
          },
        },
      })

      if (paymentMethodResult.error) {
        setError(paymentMethodResult.error.message || null)
        paymentWindowRef.current?.close()
        dispatch(stopPageLoader())
      } else {
        // Send the paymentMethodResult.paymentMethod.id to server for further processing
        startSubscriptionApi({
          subscriptionId: subscriptionId,
          paymentMethodId: paymentMethodResult.paymentMethod.id,
        })
          .unwrap()
          .then(async (res: any) => {
            if (res.invoice_url && paymentWindowRef.current) {
              paymentWindowRef.current.location.href = res.invoice_url
              paymentWindowRef.current?.focus()
            } else {
              paymentWindowRef.current?.close()
            }

            setPaymentStatus('pending')
            checkPaymentStatusInterval.current = setInterval(
              handleCheckPaymentStatus,
              pollingInterval,
            )
            
          })
          .catch((err) => {
            console.log(err)
            paymentWindowRef.current?.close()
            setError('Oops, Something went wrong!')
          })
          .finally(() => {
            setTimeout(() => {
              dispatch(stopPageLoader())
            }, 1500)
          })
      }
    } catch (error: any) {
      console.log(error)
      setError(error.message || 'Oops, Something went wrong!')
      paymentWindowRef.current?.close()
      dispatch(stopPageLoader())
    }
  }
  useEffect(() => {
    return clearPollingInterval
  }, [])

  if (!(subscriptionId && price && type)) {
    return null
  }

  const PaymentStatus = () => {
    switch (paymentStatus) {
      case 'completed':
        return (
          <Box>
            <Typography variant='h3' textAlign='center' color='green'>
              Payment Successfully
            </Typography>
            <Grid container mt={1}>
              <Grid item p={2} xs={12} sm={12} md={12}>
                <Button
                  type='button'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={onCancel}
                >
                  Finish
                </Button>
              </Grid>
            </Grid>
          </Box>
        )

      case 'failed':
        return (
          <Box>
            <Typography variant='h3' textAlign='center' color='red'>
              Payment Failed
            </Typography>
            <Grid container mt={1}>
              <Grid item p={2} xs={12} sm={12} md={6}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item p={2} xs={12} sm={12} md={6}>
                <Button
                  type='button'
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={handlePaymentRetry}
                >
                  Retry
                </Button>
              </Grid>
            </Grid>
          </Box>
        )

      case 'pending':
        return (
          <Box>
            <Typography
              variant='h3'
              textAlign='center'
              color='blue'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <span> Payment pending </span> &nbsp;&nbsp; <CircularProgress />
            </Typography>
            <Grid container mt={1}>
              <Grid item p={2} xs={12} sm={12} md={12}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        )

      default: {
        const stripeInputStyle = {
          base: {
            fontSize: '12px',
            color: 'black',
            fontWeight: 500,
            fontFamily: 'FreightSans-Medium',
            backgroundColor: 'rgba(255, 255, 255, 1)',

            '::placeholder': {
              color: '#B2B2B2',
              fontFamily: 'FreightSans-Medium',
            },
          },
          invalid: {
            color: '#9e2146',
          },
          complete: {
            color: myColors.green,
          },
        }
        return (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.paymentForm}>
            <Typography variant='h3'>Enter New Card Details</Typography>
            <Typography variant='subtitle1'>
              Please ensure your card is enabled for online transactions.
            </Typography>
            <Box mt={1}>
              <Grid container>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='firstName'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputField
                            label='First Name'
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='text'
                            placeholder='Enter your first name'
                            error={!!errors.firstName}
                            required
                            regex={/[^a-zA-Z'\s]+/}
                          />
                          {!!errors.firstName && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.firstName.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='lastName'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputField
                            label='Last Name'
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='text'
                            placeholder='Enter your last name'
                            error={!!errors.lastName}
                            required
                            regex={/[^a-zA-Z'\s]+/}
                          />
                          {!!errors.lastName && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.lastName.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box p={1}>
                <Controller
                  name='address1'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <InputField
                        label='Address Line 1'
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        type='text'
                        placeholder='Enter Address Line 1'
                        error={!!errors.address1}
                        required
                      />
                      {!!errors.address1 && (
                        <Typography variant='subtitle2' color='red'>
                          {errors.address1.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Box>

              <Box p={1}>
                <Controller
                  name='address2'
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <>
                      <InputField
                        label='Address Line 2'
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        type='text'
                        placeholder='Enter Address Line 2'
                        error={!!errors.address2}
                      />
                      {!!errors.address2 && (
                        <Typography variant='subtitle2' color='red'>
                          {errors.address2.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Box>

              <Grid container>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='country'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <SimpleDropdown
                            label={'Country'}
                            id={field.name}
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            options={COUNTRY_LIST.map((country) => ({
                              label: country.name,
                              value: country.code,
                            }))}
                            placeholder='Select Country'
                            error={!!errors.country}
                            required
                          />

                          {!!errors.country && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.country.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='state'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputField
                            label='State'
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='text'
                            placeholder='Enter State'
                            error={!!errors.state}
                            required
                            regex={/[^a-zA-Z'\s]+/}
                          />
                          {!!errors.state && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.state.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='city'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputField
                            label='City'
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='text'
                            placeholder='Enter City'
                            error={!!errors.city}
                            required
                            regex={/[^a-zA-Z'\s]+/}
                          />
                          {!!errors.city && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.city.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6} p={1}>
                  <Box>
                    <Controller
                      name='zip'
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputField
                            label='Zip Code'
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                            type='text'
                            placeholder='Enter Zip Code'
                            error={!!errors.zip}
                            required
                            regex={/[^0-9]*$/}
                          />
                          {!!errors.zip && (
                            <Typography variant='subtitle2' color='red'>
                              {errors.zip.message}
                            </Typography>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Box p={1}>
                <Typography variant='h4' component='p' mb={1}>
                  Card Number
                  <Typography variant='subtitle1' color='red' component='span'>
                    &nbsp;*
                  </Typography>
                </Typography>

                <Box className={styles.cardInput}>
                  <CardNumberElement
                    options={{
                      style: stripeInputStyle,
                      placeholder: 'Enter Card Number',
                    }}
                  />
                </Box>
              </Box>

              <Grid container>
                <Grid p={1} xs={12} sm={12} md={6}>
                  <Box>
                    <Typography variant='h4' component='p' mb={1}>
                      Card Expiration date
                      <Typography variant='subtitle1' color='red' component='span'>
                        &nbsp;*
                      </Typography>
                    </Typography>

                    <Box className={styles.cardInput}>
                      <CardExpiryElement
                        options={{
                          style: stripeInputStyle,
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid p={1} xs={12} sm={12} md={6}>
                  {' '}
                  <Box>
                    <Typography variant='h4' component='p' mb={1}>
                      CVV
                      <Typography variant='subtitle1' color='red' component='span'>
                        &nbsp;*
                      </Typography>
                    </Typography>

                    <Box className={styles.cardInput}>
                      <CardCvcElement
                        options={{
                          style: stripeInputStyle,
                          placeholder: 'CVV number',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Typography variant='subtitle1' color='red' p={1}>
              {error}
            </Typography>
            <Grid container mt={4}>
              <Grid item p={2} xs={12} sm={12} md={6}>
                <Button
                  fullWidth
                  type='button'
                  variant='outlined'
                  color='primary'
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item p={2} xs={12} sm={12} md={6}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  disabled={!stripe}
                >
                  Pay
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }
    }
  }

  return (
    <Box>
      <Typography variant='h3'>Payment</Typography>

      <Typography variant='h3' mt={1}>
        {' '}
        You Chose <span className='capitalize'> {type} </span> Plan ${price}{' '}
      </Typography>

      <Box mt={1} p={2}>
        <Box>{PaymentStatus()}</Box>
      </Box>
    </Box>
  )
}

export default PaymentForm
