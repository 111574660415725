/* eslint-disable camelcase */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'
import queryString from 'query-string';
import { TRACKING_ID, localStorageKeys } from './utils/constants'

// const userId: any = localStorage.getItem(localStorageKeys.user)
// const userEmail: string = userId['email']
// console.log('user Email', userId, userEmail)

ReactGA.initialize(TRACKING_ID)
// ReactGA.initialize('G-VHJEMZ6WZY');
// ReactGA.send({
//   hitType: 'pageview',
//   page: window.location.pathname,
// })
// ReactGA.set({
//   userId: 'New user',
// })


ReactGA.send({ hitType: 'pageview', page: window?.location?.pathname + window?.location?.search });
const pageName= window?.location?.pathname + window?.location?.search;

 // Additional logic to track time spent (optional)
 const startTime = new Date()?.getTime();

 // Calculate time spent on the page
 const endTime = new Date()?.getTime();;
 const timeSpent = (endTime - startTime) / 1000; // in seconds

 // Track time spent on the page with page name
 ReactGA.send({
   hitType: 'timing',
   timingCategory: 'Time Spent',
   timingVar: pageName,
   timingValue: timeSpent,
 });


    const { utm_source } = queryString.parse(window.location.search);

    // Check if the UTM parameters indicate an email campaign
    if (utm_source === 'email') {
      ReactGA.send({
        hitType: 'event',
        eventCategory: 'Email campaign',
        eventAction: 'User Came from Email campaign',
      });
    }
else{
  ReactGA.send({
    hitType: 'event',
    eventCategory: 'Organic Search',
    eventAction: 'User Came from Google Organic Search',
  });
}

  // Parse UTM parameters from the URL
  // const { utm_source } = queryString.parse(window.location.search);

  // if (utm_source === 'email') {
  //   // Send event for Email campaign
  //   ReactGA.event({
  //     category: 'Traffic Source',
  //     action: 'User Came from Email Campaign',
  //   });
  // } else {
  //   // Send event for Organic Search
  //   ReactGA.event({
  //     category: 'Traffic Source',
  //     action: 'User Came from Organic Search',
  //   });
  // }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals
