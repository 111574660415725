import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(248, 249, 252, 1)',
  },
}))
type DataRowProps = {
  align?: 'right'
  data: {
    id: number
    user__name: string
    user__email: string
    user__ph_number: string | null
    start_date: string
    end_date: string
    subscription__price: number
    subscription__status: string
    subscription__subscription_body: string
    subscription__subscription_type: string
    subscription_id: number
  }
}
const DataRow = ({ align, data }: DataRowProps) => {
  return (
    <StyledTableRow hover role='checkbox' tabIndex={-1}>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.user__name}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.user__email}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Typography variant='h6' fontWeight={400} className='capitalize'>
          {data.subscription__subscription_type}
        </Typography>
      </TableCell>
    </StyledTableRow>
  )
}

export default DataRow
