/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Box, Typography, Grid, Button } from '@mui/material'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'

import { errorToast, successToast } from 'src/utils/toastService'
import { useCreateUserMutation } from 'src/api/admin/userManagement'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'

type AddUserProps = {
  open: boolean
  onClose: () => void
  refetchEditorList: () => void
}

type IFormInput = {
  firstName: string
  lastName: string
  email: string
  username: string
  // metalCategory: string
}

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  username: Yup.string().required('Username is required'),
  // metalCategory: Yup.string().nullable().required('Metal category is required'),
})

const AddUser = ({ open, onClose, refetchEditorList }: AddUserProps) => {
  const [createUserApi, { isLoading }] = useCreateUserMutation()
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',

    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      // metalCategory: '',
    },
  })
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    createUserApi({
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      username: data.username,
      // metal_category: data.metalCategory,
      user_type: 'editor',
    })
      .unwrap()
      .then(() => {
        successToast('Editor created successfully.')
        refetchEditorList()
        handleClose()
      })
      .catch((err) => {
        errorToast(err?.data?.error_message || err?.data?.error || 'Oops, something went wrong.')
      })

    console.log({ data })
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <SimpleModal
      open={open}
      onClose={handleClose}
      title={'Add Editor'}
      leftButton={{
        label: 'Cancel',
        onClick: handleClose,
      }}
      rightButton={{
        label: 'Add',
        onClick: handleSubmit(onSubmit),
        loading: isLoading,
      }}
    >
      <Box>
        <Box mt={2}>
          <Controller
            name='firstName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.firstName}
                  label='First Name'
                  type='text'
                  placeholder='Enter your first name'
                  required
                />
                {!!errors.firstName && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.firstName.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name='lastName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.lastName}
                  label='Last Name'
                  type='text'
                  placeholder='Enter your last name'
                  required
                />
                {!!errors.lastName && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.lastName.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  label='Email address'
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type='text'
                  placeholder='Enter your email address'
                  error={!!errors.email}
                  required
                />
                {!!errors.email && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.email.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name='username'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.username}
                  label='Preferred Username'
                  type='text'
                  placeholder='Enter username'
                  required
                  regex={/\s/g}
                />
                {!!errors.username && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.username.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
        {/* <Box mt={2}>
          <Controller
            name='metalCategory'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <SimpleDropdown
                  id={'category'}
                  name={field.name}
                  value={field.value}
                  label='Metal Category'
                  required
                  onChange={field.onChange}
                  options={METAL_CATEGORIES.map(({ id, category }) => ({
                    label: category,
                    value: id,
                  }))}
                  placeholder='Select Metal Category'
                />
                {!!errors.metalCategory && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.metalCategory.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box> */}
      </Box>
    </SimpleModal>
  )
}

export default AddUser
