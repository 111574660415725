import React from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import { Box, CssBaseline, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { routeUrls } from 'src/utils/constants'
import PageLoader from '../PageLoader'
import Header from './Header'
import styles from './index.module.scss'
import Sidebar from './Sidebar'
import { handleDrawerToggle } from 'src/store/features/common/commonSlice'

const AdminHomeLayout = () => {
  const { isAuthenticated, userInfo, token, role } = useSelector((state: RootState) => state.auth)
  const drawerOpen = useSelector((state: RootState) => state.common.mobileOpen)
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()

  const onDrawerToggle = () =>{
    dispatch(handleDrawerToggle())
  }

  if (isAuthenticated === false || !userInfo || !token) {
    return <Navigate to={routeUrls.login} state={{ from: location }} />
  }

  if (role === 'regular_user') {
    return <Navigate to={routeUrls.user.home} state={{ from: location }} />
  }

  if (role === 'editor') {
    return <Navigate to={routeUrls.user.home} state={{ from: location }} />
  }
  return (
    <Box>
      <Header open={drawerOpen} onDrawerToggle={onDrawerToggle}/>
      <CssBaseline />

      <Grid container className={styles.main}>
        <Sidebar open={drawerOpen} onDrawerToggle={onDrawerToggle}/>
        <Grid className={styles.contentWrapper} hidden={drawerOpen}>
          <Outlet />
        </Grid>
      </Grid>

      <PageLoader />
    </Box>
  )
}

export default AdminHomeLayout
