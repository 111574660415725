import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { DateValidationError } from '@mui/x-date-pickers'
import calendarIcon from 'src/assets/icons/calendar.png'

import { Box } from '@mui/material'
import styles from './index.module.scss'
import { myColors } from 'src/utils/setup/theme'

export interface BasicDatePickerProps {
  value: null | Dayjs
  onChange: (value: Dayjs | null, context?: any) => void
  placeholder: string
  disableFuture?: boolean
  disablePast?: boolean
}

export default function BasicDatePicker(props: BasicDatePickerProps) {
  const { value, onChange, placeholder, disableFuture = false, disablePast = false } = props

  return (
    <Box className={styles.businessHour_timepicker}>
      <img src={calendarIcon} className={styles.businessHour_timepicker_icon} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          components={['DatePicker']}
          sx={{
            padding: 0,
          }}
        >
          <DatePicker
            sx={{
              background: 'red',
              width: '100%',
              margin: 0,
              padding: 0,
              borderRadius: 25,
              backgroundColor: myColors.white,
              border: '1px solid ' + myColors.inputBorder,
            }}
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                placeholder: placeholder,
              },
            }}
            disableFuture={disableFuture}
            disablePast={disablePast}
            format='MM/DD/YYYY'
          />
        </DemoContainer>
      </LocalizationProvider>
    </Box>
  )
}
