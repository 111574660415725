import React, { useEffect, useRef, useState } from 'react'
import {
  useDownloadFullInvoicReportMutation,
  useFetchPaymentHistoryQuery,
} from 'src/api/user/subscriptionApi'
import { Grid, Typography, Box, IconButton } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import BasicDatePicker from 'src/Component/BasicDatePicker'

import { Dayjs } from 'dayjs'

import HistoryTableRow from './HistoryTableRow'
import downloadIcon from 'src/assets/icons/download.png'
import { successToast } from 'src/utils/toastService'
import { AppDispatch } from 'src/store'
import { useDispatch } from 'react-redux'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import { downloadFileFromBlob, downloadFileFromUrl } from 'src/utils/helpers'
import EmptyTableRow from 'src/Component/EmptyTableRow'
import { localStorageKeys } from 'src/utils/constants'

const PaymentHistory = () => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null)
  const [toDate, setToDate] = useState<Dayjs | null>(null)
  const dispatch = useDispatch<AppDispatch>()

  // dispatch(startPageLoader())

  const {
    data: PAYMENT_DETAIL_RESPONSE,
    isFetching,
    refetch,
  } = useFetchPaymentHistoryQuery(
    fromDate && toDate
      ? {
          fromDate: fromDate.format('YYYY-MM-DD'),
          toDate: toDate.format('YYYY-MM-DD'),
        }
      : { fromDate: '', toDate: '' },
  )

  const [downloadFullInvoiceApi, { isLoading }] = useDownloadFullInvoicReportMutation()

  const handleDownloadFullInvoiceReport = () => {
    dispatch(startPageLoader())

    // downloadFullInvoiceApi()
    //   .unwrap()
    //   .then((res) => {
    //     successToast('Invoice downloaded successfully.')
    //     console.log(res)
    //     // downloadFileFromUrl(res.pdf, 'all_payment_invoice.pdf')
    //     downloadFileFromBlob(res.data)
    //   })
    fetch('/api/v1/downloadFullInvoiceReport/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem(localStorageKeys.accessToken)}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        successToast('Invoice downloaded successfully.');
        downloadFileFromBlob(blob);
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }
  useEffect(() => {
    if (isFetching) dispatch(startPageLoader())
    else dispatch(stopPageLoader())
  }, [isFetching])
  return (
    <Grid>
     <Typography variant='h3'>Payment History</Typography>

      <Grid container mb={2} mt={1}>
            <Grid item md={4} sm={12} xs={12} p={1} >
              <BasicDatePicker value={fromDate} onChange={setFromDate} placeholder='From Date' disableFuture />
            </Grid>
            <Grid item md={4} sm={12} xs={12} p={1} >
              <BasicDatePicker value={toDate} onChange={setToDate} placeholder='To Date' disableFuture />
            </Grid>
            <Grid item md={4} sm={12} xs={12} p={1} display='flex' alignItems='center' justifyContent='center'>
              <Typography variant='body1'>
                Download full reports
              </Typography>
              <IconButton onClick={handleDownloadFullInvoiceReport}>
                <img src={downloadIcon} height='24px' width='24px' />
              </IconButton>
            </Grid>
          </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant='body1'>Invoice</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1'>Payer Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1'>Date</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body1'>Amount</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {PAYMENT_DETAIL_RESPONSE?.map((row, i) => (
              <HistoryTableRow
                key={i}
                Invoice={row.invoice_id}
                PayerName={row.user__username}
                Date={row.start_date}
                Amount={row.subscription__price}
              />
            ))}
            {
              PAYMENT_DETAIL_RESPONSE?.length === 0 && <EmptyTableRow cellCount={5} />
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default PaymentHistory
