import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

import styles from './index.module.scss'

type PlanRowProps = {
  id: number
  type: string
  isActive: boolean
  price: number
  description: string
  onClick: (id: number, price: number, type: string) => void
}

const PlanRow = ({ id, type, price, description, isActive, onClick }: PlanRowProps) => {
  return (
    <Box className={styles.wrapper} onClick={() => onClick(id, price, type)}>
      <Grid container alignItems='center' justifyContent='space-between'>
        <Typography variant='h3' className='capitalize' display='flex' alignItems='center'>
          {isActive ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
          &nbsp;{type}
        </Typography>
        <Typography variant='h1'> ${price} </Typography>
      </Grid>

      <Typography variant='body2' mb={2} mt={2} className={styles.description}>
        {description}
      </Typography>
    </Box>
  )
}

export default PlanRow
