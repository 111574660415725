import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {useSubmitContactMutation} from '../../api/user/contactUsApi'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { errorToast, successToast } from 'src/utils/toastService'
import 'react-toastify/dist/ReactToastify.css';
import './contact.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form'

const ContactPopup = (props: any) => {  const {
    handleSubmit,
    control,
    formState: { errors },

    reset,
  } = useForm({
    // resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      email: '',
      subject: '',
      msg: '',
    },
  })
    const [replyTxt, setreplyTxt] = useState('');
    const [contactUsApi, { isLoading }] = useSubmitContactMutation()

    const handleClose = () => {
        props.handleCloseContactUsPopup();
    }

    const handleContactUsPopup = async () => {
        console.log('inside popup')
    }

    const schema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        msg: Yup.string().required('Message is required'),
    });
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='contact-us-popup-wrapper'
            show={props.show}
            onHide={handleClose} // Apply the custom CSS class
            animation={false} // Disable built-in animations
            centered={true}>
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle"><div className="contact-head">
                    Contact
                </div>
                <div className='grey-border' /></Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">               
                <div className="contact-body">
                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        email: '', subject:'', msg: ''
                                    }}
                                    onSubmit={async (values: any) => {
                                      console.log('inside submit', values);
                                      contactUsApi({
                                        email: values.email,
                                        name: values.subject,
                                        message: values.msg,
                                      })
                                        .unwrap()
                                        .then((res) => {
                                          console.log(res)
                                          reset()
                                          successToast('Request send successfully.')
                                          handleClose()
                                        })
                                        .catch((err) => {
                                          console.log(err)
                                          errorToast(err?.data?.error || 'Opps, something went wrong.')
                                        })
                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        /* and other goodies */
                                    }) => (
                                        <>
                                        <form
                                            className="login-form"
                                            onSubmit={handleSubmit}
                                            noValidate>
                                            <div className="Auth-form-content w-100">
                                                <Form.Group controlId="email">
                                                    <div
                                                        className={`form-floating  mb-3 ${touched.email && errors.email
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            placeholder="Your Email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            // isInvalid={touched.email && errors.email}
                                                        />
                                                        {!values.email && (
                                                            <label htmlFor="email" className="greyLabel account-label">
                                                                Your Email
                                                            </label>
                                                        )}
                                                          {touched.email && errors.email && typeof errors.email === 'string' && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Form.Group>

                                                
                                                <Form.Group controlId="subject">
                                                    <div
                                                        className={`form-floating  mb-3 ${touched.subject && errors.subject
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="subject"
                                                            placeholder="Your Email"
                                                            value={values.subject}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            // isInvalid={touched.email && errors.email}
                                                        />
                                                        {!values.subject && (
                                                            <label htmlFor="subject" className="greyLabel account-label">
                                                                Subject
                                                            </label>
                                                        )}
                                                        {touched.subject && errors.subject && typeof errors.subject === 'string' && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.subject}
                                                        </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="msg">
                                                    <div
                                                        className={`form-floating msg-container mb-3 ${touched.msg && errors.msg
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="msg"
                                                            placeholder="Your Message"
                                                            value={values.msg}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                        />
                                                        {!values.msg && (
                                                            <label htmlFor="msg" className="greyLabel account-label">
                                                                Your Message
                                                            </label>
                                                        )}
                                                        {touched.msg && errors.msg && typeof errors.msg === 'string' && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.msg}
                                                        </Form.Control.Feedback>
                                                        )}
                                                    </div>
                                                </Form.Group>

                                                {/* <div className="d-grid gap-2 mt-3 update-btn-div"> */}
                                                <div className='contact-buttons-wrapper w-100'>
                    <Button
                        onClick={() => handleContactUsPopup()}
                        className="sendBtn w-100"
                        type="submit">
                        Send
                    </Button>
                </div>
                                                {/* </div> */}

                                            </div>
                                        </form>
                                        </>
                                    )}
                </Formik>
                </div>               
            </Modal.Body>
        </Modal>
    );
};

// Define propTypes for your component
ContactPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    handleCloseContactUsPopup: PropTypes.func.isRequired,
};


export default ContactPopup;
