/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Box, Typography, Grid, Button, FormControlLabel, Checkbox } from '@mui/material'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import InputField from 'src/Component/InputField'
import { useCreateUserMutation } from 'src/api/admin/userManagement'

import { errorToast, successToast } from 'src/utils/toastService'

type AddUserProps = {
  open: boolean
  onClose: () => void
  refetchRegularUserList: () => void
  //   editForm: EditFormInput
}

type IFormInput = {
  firstName: string
  lastName: string
  organization: string
  email: string
  username: string
  isFreeUser?: boolean
}

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  organization: Yup.string().required('Organization is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  username: Yup.string().required('Username is required'),
  isFreeUser: Yup.boolean(),
})

const AddUser = ({ open, onClose, refetchRegularUserList }: AddUserProps) => {
  const [createUserApi, { isLoading }] = useCreateUserMutation()

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      username: '',
      isFreeUser: false,
    },
  })
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    createUserApi({
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      username: data.username,
      organization: data.organization,

      user_type: 'regular_user',
      is_free: !!data.isFreeUser,
    })
      .unwrap()
      .then(() => {
        successToast('Regular user created successfully.')
        refetchRegularUserList()
        handleClose()
      })
      .catch((err) => {
        errorToast(err?.data?.error_message || err?.data?.error || 'Oops, something went wrong.')
      })
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <SimpleModal
      open={open}
      onClose={handleClose}
      title={'Add User'}
      leftButton={{
        label: 'Cancel',
        onClick: handleClose,
      }}
      rightButton={{
        label: 'Add',
        onClick: handleSubmit(onSubmit),
        loading: isLoading,
      }}
    >
      <Box>
        <Box mt={2}>
          <Controller
            name='firstName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.firstName}
                  label='First Name'
                  type='text'
                  placeholder='Enter your first name'
                  required
                />
                {!!errors.firstName && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.firstName.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name='lastName'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.lastName}
                  label='Last Name'
                  type='text'
                  placeholder='Enter your last name'
                  required
                />
                {!!errors.lastName && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.lastName.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name='organization'
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.organization}
                  label='Organization'
                  type='text'
                  placeholder='Enter your organization name'
                  required
                />
                {!!errors.organization && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.organization.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  label='Email address'
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type='text'
                  placeholder='Enter your email address'
                  error={!!errors.email}
                  required
                />
                {!!errors.email && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.email.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            name='username'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <InputField
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.username}
                  label='Preferred Username'
                  type='text'
                  placeholder='Enter username'
                  regex={/\s/g}
                  required
                />
                {!!errors.username && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.username.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>

        <Box mt={2}>
          <Controller
            name='isFreeUser'
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <>
                <Typography variant='h4' component='p'>
                  Free User
                  <Checkbox {...field} color='primary' />
                </Typography>
                {!!errors.isFreeUser && (
                  <Typography variant='subtitle2' color='red'>
                    {errors.isFreeUser.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Box>
      </Box>
    </SimpleModal>
  )
}

export default AddUser
