/* eslint-disable camelcase */
import React from 'react'
import ReactGA from 'react-ga4'
import { PostStatusI } from 'src/api/admin/postApi/types'
export const API_BASE_PATH = window.location.origin

export const routeUrls = {
  landingPage: '/',
  login: '/login',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  termAndCondition: '/term-and-condition',
  privacyPolicy: '/privacy-policy',
  // logout: '/logout',

  user: {
    home: '/user-home',
    bookmarkHistory: '/user-home/bookmark-history',
    settings: '/user-home/settings',

    settingTabs: {
      account: 'account',
      changePassword: 'changePassword',
      subscription: 'subscription',
      paymentHistory: 'paymentHistory',
      deleteAccount: 'deleteAccount',
      logout: 'logout',
    },
    viewpost: '/user-home/post/view/:postId',
  },
  admin: {
    home: '/admin',
    createPost: '/admin/post/create',
    editPost: '/admin/post/edit/:postId',
    manageSubscription: '/admin/subscription',
    subscribedUser: '/admin/subscribed-user',
    viewPost: '/admin/post/view/:postId',
    regularUser: '/admin/regular-user',
    editorUser: '/admin/editor-user',
    settings: '/admin/settings',
    settingTabs: {
      account: 'account',
      changePassword: 'changePassword',
      logout: 'logout',
    },
    newsPage:'admin/news-page/:postId',
  },
  editor: {
    home: '/editor-home',
    createPost: '/editor-home/post/create',
    myReports: '/editor-home/my-reports',
    settings: '/editor-home/settings',

    settingTabs: {
      account: 'account',
      changePassword: 'changePassword',
      deleteAccount: 'deleteAccount',
      logout: 'logout',
    },
    viewpost: '/editor-home/post/view/:postId',
    editPost: '/editor-home/post/edit/:postId',
  },
}

export const localStorageKeys = {
  accessToken: 'accessToken',
  user: 'user',
  role: 'role',
  showTrialStartPopPop: 'show_trial_start_pop_up',
  hasFullAccess: 'has_full_access',
  isFreeUser: 'is_free_user',
}

export type userRolesTypes = 'regular_user' | 'editor' | 'admin'
export const userRoles = {
  user: 'regular_user',
  editor: 'editor',
  admin: 'admin',
}

export const STRIPE_KEY =
  'pk_test_51NYBwGAhAeqKjMxrMkx0uaqS3kosBctFHNdDqp8Wjmx2u4CE4gJfo9kCSreGTguvyqfmibGjWc91qNEFLBP907NX00fHpJLJDx'

export const TRACKING_ID = 'G-S6HPZFW6ZR'

export const TRIAL_PERIOD_DAYS = 10

export const TRIAL_SUBSCRIPTION_PLAN = {
  id: 0,
  subscription_type: 'free',
  price: 0,
  subscription_body: 'Sign up and get 10 days free trial.',
}

export type OptionI = {
  label: string
  value: PostStatusI
}
export const POST_STATUS: OptionI[] = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
]

export const GoogleAnalyticsData = (category: any, action: any) => {
  ReactGA.event({
    category: category,
    action: action,
  })
}
