import React from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Typography } from '@mui/material'
import { errorToast, successToast } from 'src/utils/toastService'
import { useStopSubscriptionMutation } from 'src/api/user/subscriptionApi'

type CancelSubscriptionPopUpProps = {
  open: boolean
  onClose: () => void
  onSuccessCallback: () => void
}

const CancelSubscriptionPopUp = ({
  open,
  onClose,
  onSuccessCallback,
}: CancelSubscriptionPopUpProps) => {
  const [stopSubscriptionApi,{isLoading}] = useStopSubscriptionMutation()

  const handleStopSubscription = () => {
   
    stopSubscriptionApi()
      .unwrap()
      .then((res:any) => {
        console.log(res);
        
        successToast(res.msg || 'Subscription canceled successfully.')
        onSuccessCallback()
        onClose()
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Oops, something went wrong.')
      })
  }

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title='Cancel Subscription'
      leftButton={{
        label: 'Yes',
        onClick: handleStopSubscription,
        loading: isLoading,
      }}
      rightButton={{
        label: 'No',
        onClick: onClose,
      }}
    >
      <Typography variant='h5' textAlign='center'>
        Are you sure you want to cancel your subscription?
      </Typography>
    </SimpleModal>
  )
}

export default CancelSubscriptionPopUp
