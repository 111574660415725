import React, { useState } from 'react'
import { Box, Button, Grid, Typography, CircularProgress } from '@mui/material'

import styles from './index.module.scss'
import ManageSubscriptionRow from 'src/Component/ManageSubscriptionRow'
import { useFetchSubscriptionsQuery } from 'src/api/admin/manageSubscription'
import CreateSubscription, { EditFormInput } from './CreateSubscription'
import DeleteSubscription from './DeleteSubscription'

const ManageSubscription = () => {
  const { data = [], isFetching, refetch } = useFetchSubscriptionsQuery()
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteSubscriptionId, setDeleteSubscriptionId] = useState<number>(0)
  const [editForm, setEditForm] = useState<EditFormInput>(null)

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true)
  }

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false)
    setEditForm(null)
  }

  const handleOpenDeleteModal = (id: number) => {
    setDeleteSubscriptionId(id)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const handleOnEditButtonClick = (data: EditFormInput) => {
    setEditForm(data)

    handleOpenCreateModal()
  }
  return (
    <Box className="cardWrapperContainer">
      <Box className={`cardWrapper ${styles.subscriptionHeadCard}`}>
        <Grid container justifyContent='space-between' className={styles.subscriptionHeadContainer}>
          <Grid item>
            <Typography variant='h1'>Manage Subscription</Typography>
          </Grid>

          <Grid item className={styles.btnWrapper}>
            <Button
              onClick={handleOpenCreateModal}
              className={styles.createBtn}
              type='button'
              variant='contained'
              color='primary'
            >
              Create new
            </Button>
          </Grid>
        </Grid>
      </Box>

      {isFetching ? (
        <Grid mt={5} container justifyContent='center'>
          <CircularProgress />
        </Grid>
      ) : (
        <Box>
          {data?.map((subscription) => (
            <ManageSubscriptionRow
              key={subscription.id}
              subscription={subscription}
              onDelete={handleOpenDeleteModal}
              onEdit={handleOnEditButtonClick}
            />
          ))}

          {data.length === 0 && (
            <Grid mt={5} container justifyContent='center'>
              <Typography variant='subtitle1'>No subscription found</Typography>
            </Grid>
          )}
        </Box>
      )}

      <CreateSubscription
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        refetchSubscriptionList={refetch}
        editForm={editForm}
      />
      <DeleteSubscription
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        refetchSubscriptionList={refetch}
        subscriptionId={deleteSubscriptionId}
      />
    </Box>
  )
}

export default ManageSubscription
