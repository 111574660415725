import React from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Typography } from '@mui/material'
import { useDeleteSubscriptionMutation } from 'src/api/admin/manageSubscription'
import { errorToast, successToast } from 'src/utils/toastService'

type DeleteSubscriptionProps = {
  open: boolean
  onClose: () => void
  refetchSubscriptionList: () => void
  subscriptionId: number
}

const DeleteSubscription = ({
  open,
  onClose,
  refetchSubscriptionList,
  subscriptionId,
}: DeleteSubscriptionProps) => {
  const [deleteSubscriptionApi, { isLoading }] = useDeleteSubscriptionMutation()

  const handleDeleteSubscription = () => {
    if (subscriptionId === 0) return
    deleteSubscriptionApi(subscriptionId)
      .unwrap()
      .then(() => {
        successToast('Subscription deleted successfully.')
        refetchSubscriptionList()
        onClose()
      })
      .catch((err) => {
        errorToast(err?.data?.error || 'Oops, something went wrong.')
      })
  }

  return (
    <SimpleModal
      open={open && !!subscriptionId}
      onClose={onClose}
      title='Delete Subscription'
      leftButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      rightButton={{
        label: 'Delete',
        onClick: handleDeleteSubscription,
        loading: isLoading,
      }}
    >
      <Typography variant='h5' textAlign='center'>
        Are you sure you want to delete this subscription?
      </Typography>
    </SimpleModal>
  )
}

export default DeleteSubscription
