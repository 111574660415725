import React from 'react'
import SimpleModal from 'src/Component/SimpleModal'
import { Typography } from '@mui/material'
import { errorToast, successToast } from 'src/utils/toastService'
import { useDeleteUserMutation } from 'src/api/admin/userManagement'

type DeleteEditorUserProps = {
  open: boolean
  onClose: () => void
  refetchEditorList: () => void
  email: string
}

const DeleteEditorUser = ({ open, onClose, refetchEditorList, email }: DeleteEditorUserProps) => {
  const [deleteEditorUserApi, { isLoading }] = useDeleteUserMutation()

  const handleDelete = () => {
    deleteEditorUserApi({ email: email })
      .unwrap()
      .then(() => {
        successToast('Editor deleted successfully.')
        refetchEditorList()
        onClose()
      })
      .catch((err) => {
        console.log({ err })
        errorToast(err?.data?.error || 'Oops, something went wrong.')
      })
  }

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      title='Delete Editor'
      leftButton={{
        label: 'Cancel',
        onClick: onClose,
      }}
      rightButton={{
        label: 'Delete',
        onClick: handleDelete,
        loading: isLoading,
      }}
    >
      <Typography variant='h5' textAlign='center'>
        Are you sure you want to delete this Editor?
      </Typography>
    </SimpleModal>
  )
}

export default DeleteEditorUser
