import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Button, Typography } from '@mui/material'
import styles from './index.module.scss'
import PostPreview from 'src/Component/PostPreview'
import SearchInput from 'src/Component/SearchInput'
import SimpleDropdown from 'src/Component/SimpleDropdown'
import SortDropdown from 'src/Component/SortDropdown'
import BasicDatePicker from 'src/Component/BasicDatePicker'
import { useLazyFetchUserPostQuery } from 'src/api/user/postApi'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { PostI } from 'src/api/user/postApi/types'
import { useGetMetalCategoriesQuery } from 'src/api/masterDataApi'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Dayjs } from 'dayjs'

const sortOptions = [
  {
    value: 'created_at-desc',
    label: 'Latest',
  },
  {
    value: 'created_at-asc',
    label: 'Oldest',
  },
  {
    value: 'title-asc',
    label: 'Title - Asc',
  },
  {
    value: 'title-desc',
    label: 'Title - Desc',
  },
  {
    value: 'popularity-desc',
    label: 'Popularity',
  },
]

const UserHome = () => {
  const dispatch = useDispatch<AppDispatch>()
  const pageRef = useRef<number>(1)
  const [paginatedData, setPaginatedData] = useState<PostI[]>([])
  const [search, setSearch] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [categorySelected, setCategorySelected] = useState<string>('')
  const [date, setDate] = useState<Dayjs | null>(null)
  const [sort, setSort] = useState<any>(sortOptions[0].value)
  const [fetchPost, { data: POST_RESPONSE, isFetching }] = useLazyFetchUserPostQuery()
  const { data: METAL_CATEGORIES = [] } = useGetMetalCategoriesQuery()
  const { isPageLoading } = useSelector((state: RootState) => state.common)

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value)
  }

  const handleFilter = () => {
    setCategorySelected('')
    fetchData(true)
  }

  const handleSortDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    setSort(e.target.value)
  }

  const loadNextPage = () => {
    pageRef.current += 1
    fetchData()
  }

  const fetchData = (isFilterResult = false): void => {
    if (isFilterResult) {
      pageRef.current = 1
    }

    const sortArr = sort.split('-')
    dispatch(startPageLoader())
    fetchPost({
      pageNumber: pageRef.current,
      searchBy: search,
      category: category || categorySelected,
      sortDir: sortArr[1] || '',
      date: (() => {
        if (date) {
          return date.format('YYYY-MM-DD')
        }
        return ''
      })(),
    })
      .unwrap()
      .then((res) => {
        if (isFilterResult) {
          setPaginatedData([...JSON.parse(JSON.stringify(res.data))])
        } else {
          setPaginatedData([
            ...JSON.parse(JSON.stringify(paginatedData)),
            ...JSON.parse(JSON.stringify(res.data)),
          ])
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }

  useEffect(() => {
    if (categorySelected) {
      setCategory('')
      fetchData(true)
    }
  }, [categorySelected])

  useEffect(() => {
    if (sort) {
      fetchData(true)
    }
  }, [sort])

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Box>
      <Box
        className={styles.filterWrapper}
        alignItems='center'
        display='flex'
        flexDirection='column'
        p={1}
      >
        <Grid container alignItems='center'>
          <Grid item flexGrow={1} p={2}>
            <SearchInput onChange={setSearch} />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <SimpleDropdown
              id={'category'}
              name={''}
              value={category}
              onChange={handleCategoryChange}
              options={METAL_CATEGORIES.map(({ id, category }) => ({
                label: category,
                value: id,
              }))}
              placeholder='Select Metal Category'
            />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <BasicDatePicker value={date} onChange={setDate} placeholder='Date' disableFuture />
          </Grid>

          <Grid item flexGrow={1} p={2}>
            <Button
              fullWidth
              type='button'
              variant='outlined'
              color='primary'
              onClick={handleFilter}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container justifyContent='end'>
        <Grid xs={12} sm={12} md={4} p={2}>
          <SortDropdown options={sortOptions} value={sort} onChange={handleSortDropdownChange} />
        </Grid>
      </Grid>

      <Box>
        {isPageLoading === false && paginatedData.length === 0 && (
          <Typography variant='subtitle1' textAlign='center'>
            No Result Found
          </Typography>
        )}
        {POST_RESPONSE && (
          <InfiniteScroll
            dataLength={paginatedData.length}
            next={loadNextPage}
            hasMore={POST_RESPONSE.page.has_next}
            loader={
              <Typography variant='subtitle1' textAlign='center'>
                {' '}
                loading more feeds..{' '}
              </Typography>
            }
          >
            {paginatedData.map((post) => {
              return (
                <Box mb={2} key={post.id}>
                  <PostPreview
                    postId={post.id}
                    title={post.title}
                    originalNews={post.original_news}
                    date={post.edited_at}
                    isManual={post.is_manual}
                    isAuto={!post.is_manual}
                    description={post.short_description}
                    categorySelected={categorySelected || category}
                    onClickCategoryChip={(value) => setCategorySelected(value)}
                    category={METAL_CATEGORIES.filter((item) => post.category.includes(item.id))}
                  />
                </Box>
              )
            })}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  )
}

export default UserHome
