import React, { useEffect, useState } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Typography, Grid, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'
import CustomizedSwitches from 'src/Component/SwitchButton'
import { useDeleteUserMutation } from 'src/api/admin/userManagement'
import { errorToast, successToast } from 'src/utils/toastService'
import { UsersData } from 'src/api/admin/userManagement/types'
import { check } from 'prettier'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(248, 249, 252, 1)',
  },
}))
type DataRowProps = {
  align?: 'right'
  data: UsersData
  onDelete: (email: string) => void
  handleSwitchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const DataRow = ({ align, data, onDelete, handleSwitchChange }: DataRowProps) => {
  const renderUserStatusChip = () => {
    if (data.is_free) return <Chip  label='Free' />
    if (data.trial_completed === false) return <Chip color='secondary' label='Trial' />
    if (data.subscription_expire) return <Chip color='error'  label='Subscription Expired' />
    if (data.subscription_cancel) return <Chip color='warning' label='Subscription Cancelled' />
    if (data.subscription_active) return <Chip color='primary' label='Subscribed' />
    return null
  }

  return (
    <StyledTableRow hover role='checkbox' tabIndex={-1}>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.firstname}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.lastname}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.email}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.username}
        </Typography>
      </TableCell>
      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {data.organization}
        </Typography>
      </TableCell>

      <TableCell
        align={align}
        sx={{
          border: 'none',
        }}
      >
        <Typography variant='h6' fontWeight={400}>
          {renderUserStatusChip()}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <CustomizedSwitches
          name={data.email}
          defaultChecked={data.account_status !== 'active'}
          onChange={handleSwitchChange}
        />
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
        }}
        align={align}
      >
        <Grid onClick={() => onDelete(data.email)}>
          <Typography variant='h6' fontWeight={400} color='#00539D' className='cursor'>
            Delete
          </Typography>
        </Grid>
      </TableCell>
    </StyledTableRow>
  )
}

export default DataRow
