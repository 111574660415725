import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styles from './index.module.scss'
import logo from 'src/assets/images/logo.png'
import BellIcon from 'src/assets/icons/svg/BellIcon'
import SettingIcon from 'src/assets/icons/svg/SettingIcon'
import { NavLink } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'
import { isActiveLink } from 'src/utils/helpers'

type navLink = {
  label: string
  url: string
  icon?: JSX.Element
}
const navLinks: navLink[] = [
  {
    label: 'Home',
    url: routeUrls.user.home,
  },

  {
    label: 'Bookmarked',
    url: routeUrls.user.bookmarkHistory,
  },

  // {
  //   label: 'Notification',
  //   url: '',
  //   icon: <BellIcon />,
  // },

  {
    label: 'Settings',
    url: routeUrls.user.settings,
    icon: <SettingIcon />,
  },
]

const Header = () => {
  return (
    <Box className={`${styles.header} ${styles.wrapperPadding}`}>
      <Grid container justifyContent='space-between' className={styles.container} >
        <Grid item className={styles.logoContainer}>
          <NavLink to={routeUrls.user.home}>
            {' '}
            <img src={logo} alt='Logo' height={120} width={120} />
          </NavLink>
        </Grid>

        <Grid item>
          <Grid container justifyContent='center' alignItems='center' className={styles.menuContainer}>
            {navLinks.map((item, i) => (
              <Grid
                key={i}
                item
                className={isActiveLink(item.url) ? styles.header_nav_link_active : styles.menuItem}
              >
                <Typography variant='h3' color='inherit' mr={item.icon ? 1 : 0}>
                  <NavLink to={item.url} className={styles.fontSize}>{item.label}</NavLink>
                </Typography>
                {item.icon}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
