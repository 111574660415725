import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { Typography } from '@mui/material'

export interface TableHeadType {
  key: string
  label: string
  minWidth?: number
  align?: 'right'
}

type PaginatedTableProps = {
  tableHead: TableHeadType[]
  currentPage: number
  totalPages: number
  onPageChange: (newPage: number) => void
  rowsPerPage: number
  onRowsPerPageChange: (newRowsPerPage: number) => void
  children?: React.ReactElement[] | React.ReactElement,
  totalDataCount: number
}

export default function PaginatedTable({
  tableHead,
  totalDataCount,
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  children,
}: PaginatedTableProps) {
  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(+event.target.value)
    onPageChange(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', borderRadius: '0px', boxShadow: 'none' }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {tableHead.map((head) => (
                <TableCell
                  key={head.key}
                  align={head.align}
                  style={{ minWidth: head.minWidth, background: 'rgba(231, 231, 231, 1)' }}
                >
                  <Typography variant='h6' fontWeight={800}>
                    {head.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        sx={{
          '& .MuiTablePagination-toolbar':{
            alignItems:'baseline'
          }
        }}
        count={totalDataCount}
        rowsPerPage={rowsPerPage}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
