import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import SimpleModal from '../SimpleModal'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { useDeleteAccountMutation } from 'src/api/user/settingsApi'
import { successToast } from 'src/utils/toastService'
import { routeUrls } from 'src/utils/constants'
import { useNavigate } from 'react-router-dom'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'

type DeletePopUpProps = {
  onClose?: () => void
}

const DeletePopUp = ({ onClose }: DeletePopUpProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [open, setOpne] = useState(true)
  const [deleteAccountApi] = useDeleteAccountMutation()

  const handleClose = () => {
    setOpne(false)
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  const handleDelete = () => {
    dispatch(startPageLoader())
    deleteAccountApi()
      .unwrap()
      .then((res) => {
        successToast('Account deleted successfully.')
        navigate(routeUrls.editor.home)
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {
        dispatch(stopPageLoader())
      })
  }
  return (
    <Box>
      <SimpleModal
        open={open}
        onClose={handleClose}
        title={'Are you sure you want to delete the Account?'}
        leftButton={{
          label: 'Cancel',
          onClick: handleClose,
        }}
        rightButton={{
          label: 'Delete',
          onClick: handleDelete,
        }}
      >
        <Typography variant='h5' textAlign='justify'>
          Are you sure you want to delete the account? This action is irreversible and will
          permanently remove all your data and access to the application. If you are certain about account
          deletion, click &apos;Delete&apos; below. Otherwise, you can choose &apos;Cancel&apos; to retain your account
          and data.
        </Typography>
      </SimpleModal>
    </Box>
  )
}

export default DeletePopUp
