import React from 'react'
import { styled } from '@mui/material/styles'
import Input from '@mui/material/Input'
import attachmentIcon from 'src/assets/icons/attachment.png'
import { Typography, Box } from '@mui/material'
import { myColors } from 'src/utils/setup/theme'
import { errorToast } from 'src/utils/toastService'

// Define a custom styled file input component
const StyledFileInput = styled('label')({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'center',
  flexDirection: 'row',
  // height: '100%',
  width: '100%',
  cursor: 'pointer',
  // background: '#142036',
  // borderRadius: '4px',
  padding: '5px',
})

// Define the props interface for the styled file input component
interface PdfUploadProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
  label: string
  name: string
  value: File[]
  required?: boolean
  maxSizeInBytes?: number
}

const PdfUpload = ({
  onChange,
  multiple = false,
  label,
  name,
  value,
  required = false,
  maxSizeInBytes = 3000000, // 3mb
}: PdfUploadProps) => {
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      if (e.target.files[0].type !== 'application/pdf') return
      if (e.target.files[0].size > maxSizeInBytes) {
        errorToast('File size greater than 3MB not allowed.')
        return
      }
      onChange(e)
    }
  }
  return (
    <StyledFileInput>
      <img src={attachmentIcon} />
      <Typography ml={2} variant='subtitle2' color={myColors.primary}>
        {label}
      </Typography>
      <Input
        type='file'
        onChange={handleFileInput}
        inputProps={{ accept: 'application/pdf', multiple }}
        sx={{ display: 'none' }}
        name={name}
        required={required}
      />
    </StyledFileInput>
  )
}

export default PdfUpload
