import React, { useState } from 'react'
import LandingLogo from '../../assets/icons/svg/LandingLogo';
import Landingfirst from 'src/assets/icons/Landingfirst.png'
import LandingFooter from 'src/assets/icons/LandingFooter.png'
import LandingSec from 'src/assets/icons/LandingSec.png'
import { Dialog, DialogTitle, Button, Grid, Typography, IconButton, Box } from '@mui/material'
import { routeUrls } from 'src/utils/constants'
import ContactPopup from './ContactPopup';
import { useNavigate } from 'react-router-dom'
import './styles.scss';

const LandingPage= () =>{
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('ourResearch');
    const [showPopup, setShowPopup]= useState(false);

    const handleCloseContactUsPopup= () =>{
      setShowPopup(false)
    }
    const handleTakeTrialNavItemClick = () => {
        // setActiveSection(section);
        // if (section === 'takeTrial') {
          // If "Take a Trial" is clicked, scroll to the "take-trial-wrapper"
          document.getElementsByClassName('take-trial-wrapper')[0]?.scrollIntoView({ behavior: 'smooth' });
        // } else {
        //   // Otherwise, navigate to the corresponding route
        //   navigate(routeUrls[section]);
        // }
      };
    return(
      <>
        <div className="landing-page-container">
<div className="navbar-wrapper">
                <div className="navbar-logo cursor-pointer" onClick={() =>window?.location?.reload()}>
                     <LandingLogo />
                </div>

<div className='nav-items-wrapper'>
                <div className="nav-items">
                    <div className={'nav-item'}
                        onClick={() => handleTakeTrialNavItemClick()}
                        >Our Research Service</div>
                    <div
                        className={'nav-item'}
                        onClick={() => navigate('/register')}
                        >Take a Trial</div>
                        <div
                        className={'nav-item'}
                        onClick={() => setShowPopup(true)}
                        >Contact</div>
                </div>

                <div className="user-nav-item">
                <Button type='button'
                  variant='outlined'
                  color='primary'
                  className='sign-in-btn'
                  onClick={() => {
                    navigate(routeUrls?.login)
                  }}
                  >Sign in</Button>
            </div>
            </div>
        </div>

        <div className='landing-body'>
            <div className='landing-first-img-wrapper'>
            <img src={Landingfirst} className='landing-first-img' />
            <div className='landing-first-img-txt-wrapper'>
            <div className='landing-first-img-txt1'>Understanding the geopolitical, policy and market dynamics of the energy transition</div>
            <div className='landing-first-img-txt2'>Transitional Materials provides corporate and financial analysts with actionable insight into the geopolitics, industrial policies, and commodity markets behind the energy transition. Our research team looks for signals amid a noisy analytical landscape and monitors the moving parts that impact related sectors and markets.</div>
            </div>
        </div>

        <div className='take-trial-wrapper'>
            <div className='panes-wrapper d-flex'>
                <div className='pane-cell'>
                    <div className='pane-head'>Daily Coverage of Key Commodities</div>
                    <div className='pane-desc'>Our team identifies the key stories and data points affecting value chains for copper, cobalt, graphite, lithium, neodymium, and nickel. One concise daily report presents these developments in the context of the big picture themes covered in our thematic research reports.</div>
                </div>
                <div className='pane-cell'>
                    <div className='pane-head'>Weekly Thematic Research Pieces</div>
                    <div className='pane-desc'>Our weekly research follows the geopolitical and industrial policy drivers that impact the competitive landscape for renewable energy and EV supply chains. These pieces quantify the impacts of qualitative shifts to policies and demand-supply relationships embedded in the energy transition.</div>
                </div>
                <div className='pane-cell'>
                    <div className='pane-head'>Regular and Event-Driven Webinars</div>
                    <div className='pane-desc'>Our regular webinars guide subscribers through recent policy shifts and market developments. These outlook events always include time for live Q&A to delve deeper into the issues. Replays, slide decks and other supporting materials are made available to subscribers.</div>
                </div>
            </div>
        </div>

        <div className='landing-third-wrapper'>
        <img src={LandingSec} className='landing-sec-img' />
        <div className='landing-third-txt-wrapper'>
        <div className='landing-third-img-txt1'>Access insights that decode the energy transition</div>
            {/* <div className='landing-third-img-txt2'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using</div> */}
            <div className='third-btn-wrapper'>
            <Button type='button'
                  variant='outlined'
                  color='primary'
                  className='third-btn-first'
                  onClick={() => {
                    navigate(routeUrls?.register)
                  }}
                  >Register for a free trial</Button>
                  <Button type='button'
                  variant='outlined'
                  color='primary'
                  className='third-btn-sec'
                  onClick={() => {
                    navigate(routeUrls?.register)
                  }}
                  >Subscribe Now</Button>
            </div>
        </div>
        </div>
        </div>

        <div className='landing-footer'>
            <div className='icon-wrapper'>
                  <img src={LandingFooter} className='landing-icon' onClick={() =>window?.location?.reload()} />
                  <div className='footer-nav-items'>
                    <div className='footer-nav-item' onClick={() => setShowPopup(true)}>Contact</div>
                    <div className='footer-nav-item' 
                  onClick={() => {
                    navigate(routeUrls?.login)
                  }}>Sign in</div>
                  </div>
                  </div>
                  <div className='footer-copyright'>
                    <span className=''>Copyright © 2023 </span>
                    <span className='footer-highlighted'>TRANSITIONAL MATERIALS</span>
                    <span>. All Rights Reserved</span>
                  </div>
        </div>
        </div>
        {
          showPopup=== true &&
          <ContactPopup show={showPopup} handleCloseContactUsPopup={handleCloseContactUsPopup} />
        }
        </>
    )
}

export default LandingPage;