import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import logo from 'src/assets/images/logo.png'
import { routeUrls } from 'src/utils/constants'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import InputField from 'src/Component/InputField'
import { resendEmailOtp, verifyEmailOtp } from 'src/store/features/auth/authActions'
import { errorToast, successToast } from 'src/utils/toastService'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'
import { setShowTrialStartPopUp } from 'src/store/features/auth/authSlice'

const otpSchema = Yup.object().shape({
  otp: Yup.string().required('Verification code is required'),
})

type UserEmailVerificationProps = {
  email: string
  show: boolean
  sendVerificationCodeOnLoad: boolean
}

const UserEmailVerification = (props: UserEmailVerificationProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const { selectedPlanOnSignUp } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const {
    handleSubmit: handleOtpSubmit,
    control: otpControl,
    formState: { errors: otpErrors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(otpSchema),
    mode: 'all',
    defaultValues: {
      otp: '',
    },
  })

  type IOtpFormInput = {
    otp: string
  }
  const onOtpSubmit: SubmitHandler<IOtpFormInput> = (data) => {
    dispatch(
      verifyEmailOtp({
        email: props.email,
        otp: data.otp,
      }),
    ).then((res: any) => {
      if (res.error) {
        errorToast(res.payload)
      } else if (selectedPlanOnSignUp.id !== 0) {
        dispatch(setShowTrialStartPopUp(false))
        navigate(`${routeUrls.user.settings}?tab=${routeUrls.user.settingTabs.subscription}`)
      } else if (selectedPlanOnSignUp.id === 0) {
        dispatch(setShowTrialStartPopUp(true))
      }
    })
  }

  const handleResendOtp = () => {
    const email = props.email
    dispatch(resendEmailOtp({ email })).then((res: any) => {
      if (res.error) {
        errorToast(res.payload)
      } else {
        successToast('Verification code sent.')
      }
    })
  }

  useEffect(() => {
    if (props.show && props.email && props.sendVerificationCodeOnLoad) {
      handleResendOtp()
    }
  }, [props])

  return (
    <div>
      <form
        onSubmit={handleOtpSubmit(onOtpSubmit)}
        style={{
          display: props.show ? 'initial' : 'none',
        }}
      >
        <Box textAlign='center'>
          <img src={logo} alt='Logo' height={192} width={192} />
          <Typography variant='h2' pt={2}>
            Verify your account.
          </Typography>
        </Box>

        <Box mt={3} width='400px'>
          <Box mt={2}>
            <Controller
              name='otp'
              control={otpControl}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <>
                    <InputField
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!otpErrors.otp}
                      label='Verification code'
                      type='text'
                      placeholder='Enter Verification code'
                      required
                    />
                    {!!otpErrors.otp && (
                      <Typography variant='subtitle2' color='red'>
                        {otpErrors.otp.message}
                      </Typography>
                    )}
                  </>
                )
              }}
            />
          </Box>

          <Box mt={2}>
            <Button type='submit' variant='contained' color='primary' fullWidth>
              Verify
            </Button>
          </Box>

          <Box mt={2}>
            <Button
              type='button'
              variant='outlined'
              color='primary'
              fullWidth
              onClick={handleResendOtp}
            >
              Resend Verification code
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  )
}

export default UserEmailVerification
