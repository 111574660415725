import React from 'react'
import styles from './index.module.scss'

type ReportTypeTabProps = {
  label: string
  isActive?: boolean
  onClick?: () => void
}
const ReportTypeTab = ({ label, isActive = false, onClick }: ReportTypeTabProps) => {
  return (
    <div className={styles.ReportTypeTab_wrapper}>
      <span onClick={onClick} className={`${styles.tab} ${isActive && styles.active}`}>
        {label}
      </span>
    </div>
  )
}

export default ReportTypeTab
