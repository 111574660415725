import React, { useEffect } from 'react'
import { Grid, Box, Typography, Button } from '@mui/material'
import logo from 'src/assets/images/logo.png'
import InputField from 'src/Component/InputField'
import styles from '../index.module.scss'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'src/store'

import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorToast, successToast } from 'src/utils/toastService'
import { resetPasswordpApi } from 'src/store/features/forgotPassword/forgotPasswordAction'

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
})

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { error } = useSelector((state: RootState) => state.forgotPassword)

  const [queryParams] = useSearchParams()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  })

  type IFormInput = {
    password: string
    confirmPassword: string
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      resetPasswordpApi({
        password: data.password,
        token: queryParams.get('token') || '',
      }),
    ).then((res: any) => {
      if (res.error) {
        errorToast(res.payload)
      } else {
        successToast('Password reset successfully.')
        navigate(routeUrls.login)
      }
    })
  }

  return (
    <Grid container justifyContent='center' alignItems='center' height='100%'>
      <Grid item p={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={styles.forgotPasswordContainer}>
            <Box textAlign='center'>
              <Typography variant='h2' pt={2}>
                Update Password
              </Typography>
              <Typography variant='subtitle1' mt={1} textAlign='center'>
                Reset password in quick steps
              </Typography>
            </Box>

            <Box mt={3}>
              <Box mt={2}>
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <InputField
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.password}
                        label='Enter new Password'
                        type='password'
                        placeholder='*************'
                        required
                      />
                      {!!errors.password && (
                        <Typography variant='subtitle2' color='red'>
                          {errors.password.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Box>
              <Box mt={2}>
                <Controller
                  name='confirmPassword'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <InputField
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!errors.confirmPassword}
                        label='Verify new Password'
                        type='password'
                        placeholder='*************'
                        required
                      />
                      {!!errors.confirmPassword && (
                        <Typography variant='subtitle2' color='red'>
                          {errors.confirmPassword.message}
                        </Typography>
                      )}
                    </>
                  )}
                />
              </Box>

              <Box mt={2}>
                <Button type='submit' variant='contained' color='primary' fullWidth>
                  Reset Password
                </Button>
              </Box>
              <Box mt={2}>
                <Button
                  type='button'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => navigate(routeUrls.login)}
                >
                  Cancel
                </Button>
              </Box>

              {/* <Typography variant='subtitle1' mt={3} color='red'>
                Verification Link has been sent to your inbox. please click on verification link to
                reset password
              </Typography> */}
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
