import React, { useState } from 'react'
import { Grid, Box, Typography, Button, FormControlLabel, Checkbox } from '@mui/material'
import logo from 'src/assets/images/logo.png'
import InputField from 'src/Component/InputField'
import styles from '../index.module.scss'
import { Link } from 'react-router-dom'
import { routeUrls } from 'src/utils/constants'
import { myColors } from 'src/utils/setup/theme'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { registerUser } from 'src/store/features/auth/authActions'
import { errorToast } from 'src/utils/toastService'
import UserEmailVerification from 'src/Component/UserEmailVerification'
import SubscriptionPlan from './SubscriptionPlan'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  organization: Yup.string().required('Organization is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  preferredUN: Yup.string().required('Username is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  termAndPolicy: Yup.boolean()
    .required('You must accept the terms and conditions')
    .oneOf([true], 'You must accept the terms and conditions'),
})

const Register = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [emailForOtp, setEmailForOtp] = useState('')

  const [showOtpInput, setShowOtpInput] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  })

  type IFormInput = {
    firstName: string
    lastName: string
    organization: string
    email: string
    preferredUN: string
    password: string
    confirmPassword: string
    termAndPolicy: boolean
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    dispatch(
      registerUser({
        firstname: data.firstName,
        lastname: data.lastName,
        organization: data.organization,
        email: data.email,
        username: data.preferredUN,
        password: data.password,
      }),
    ).then((res: any) => {
      if (!res.error) {
        setShowOtpInput(true)
        setEmailForOtp(data.email)
      } else {
        errorToast(res.payload)
      }
    })
  }

  return (
    <Grid container direction='row' justifyContent='center' minHeight='100vh' className={styles.loginRegDirection}>
      <Grid item xs={12} sm={12} md={6}>
        <Grid container justifyContent='center' alignItems='center' height='100%'>
          <Grid item p={1} className={styles.formContainer}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: showOtpInput ? 'none' : 'initial',
              }}
            >
              <Box textAlign='center'>
                <img src={logo} alt='Logo' height={192} width={192} />
                <Typography variant='h2' pt={2}>
                  Create your account.
                </Typography>
                <Typography variant='subtitle1'>
                  Already have an account?{' '}
                  <Link to={routeUrls.login} className='styleLink'>
                    Sign In
                  </Link>
                </Typography>
              </Box>

              <Box mt={3} width='400px' className={styles.form}>
                <Box mt={2}>
                  <Controller
                    name='firstName'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.firstName}
                          label='First Name'
                          type='text'
                          placeholder='Enter your first name'
                          required
                        />
                        {!!errors.firstName && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.firstName.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <Controller
                    name='lastName'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.lastName}
                          label='Last Name'
                          type='text'
                          placeholder='Enter your last name'
                          required
                        />
                        {!!errors.lastName && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.lastName.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>

                <Box mt={2}>
                  <Controller
                    name='organization'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.organization}
                          label='Organization'
                          type='text'
                          placeholder='Enter your organization name'
                          required
                        />
                        {!!errors.organization && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.organization.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          label='Email address'
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          type='text'
                          placeholder='Enter your email address'
                          error={!!errors.email}
                          required
                          autoComplete='email'
                        />
                        {!!errors.email && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.email.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name='preferredUN'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.preferredUN}
                          label='Preferred Username'
                          type='text'
                          placeholder='Enter username'
                          required
                          regex={/\s/g}
                          autoComplete='off'
                        />
                        {!!errors.preferredUN && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.preferredUN.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.password}
                          label='Password'
                          type='password'
                          placeholder='*************'
                          required
                          disablePaste={true}
                          autoComplete='current-password'
                        />
                        {!!errors.password && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.password.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name='confirmPassword'
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <>
                        <InputField
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                          error={!!errors.confirmPassword}
                          label='Confirm Password'
                          type='password'
                          placeholder='*************'
                          required
                          disablePaste={true}
                        />
                        {!!errors.confirmPassword && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.confirmPassword.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>

                <Box mt={1}>
                  <Controller
                    name='termAndPolicy'
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={<Checkbox {...field} color='primary' />}
                          label={
                            <Typography variant='subtitle2' component='span'>
                              I agree to the&nbsp;
                              <Link
                                to={routeUrls.termAndCondition}
                                className={styles.termsAndPolicy}
                              >
                                Terms and condition
                              </Link>
                              &nbsp;and&nbsp;
                              <Link to={routeUrls.privacyPolicy} className={styles.termsAndPolicy}>
                                Privacy Policy
                              </Link>
                            </Typography>
                          }
                        />
                        {!!errors.termAndPolicy && (
                          <Typography variant='subtitle2' color='red'>
                            {errors.termAndPolicy.message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>

                <Typography mt={0} variant='h3' color={myColors.primary}>
                  Start your 10-day free trial.
                </Typography>
                <Box mt={2}>
                  <Button type='submit' variant='contained' color='primary' fullWidth>
                    Sign up
                  </Button>
                </Box>
              </Box>
            </form>

            <UserEmailVerification
              email={emailForOtp}
              show={showOtpInput}
              sendVerificationCodeOnLoad={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={styles.loginBackgroundImage}>
        <SubscriptionPlan />
      </Grid>
    </Grid>
  )
}

export default Register
