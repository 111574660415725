import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styles from './index.module.scss'
import { routeUrls } from 'src/utils/constants'
import { useSearchParams } from 'react-router-dom'
import LogoutPopUp from 'src/Component/LogoutPopUp'
import AccountInformation from './AccountInformation'
import ChangePassword from './ChangePassword'

type menuI = {
  label: string
  component: JSX.Element | null
  key: string
}

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [activeMenu, setActiveMenu] = useState<number>(-1)

  const menus: menuI[] = [
    {
      label: 'Account Information (Profile)',
      component: <AccountInformation />,
      key: routeUrls.user.settingTabs.account,
    },
    {
      label: 'Change Password',
      component: <ChangePassword />,
      key: routeUrls.user.settingTabs.changePassword,
    },

    {
      label: 'Sign out',
      component: <LogoutPopUp onClose={() => handleTabClick(0)} />,
      key: routeUrls.user.settingTabs.logout,
    },
  ]

  const handleTabClick = (index: number) => {
    setActiveMenu(index)
    setSearchParams({
      tab: menus[index].key,
    })
  }

  useEffect(() => {
    const tab = searchParams.get('tab')

    let foundTab = false
    if (tab) {
      menus.forEach((menu, i) => {
        if (menu.key === tab) {
          setActiveMenu(i)
          foundTab = true
        }
      })
    }
    if (foundTab === false) {
      setActiveMenu(0)
    }
  }, [location])

  return (
    <Box className={styles.wrapper}>
      <Typography variant='h1'> Account Settings </Typography>
      <Box p={2}>
        <Grid container mt={1}>
          <Grid xs={12} sm={12} md={12} lg={3}>
            {menus.map((menu, i) => (
              <Typography
                className={`${styles.menuItem}  ${i === activeMenu ? styles.activeMenuItem : ''}`}
                variant='body1'
                key={i}
                onClick={() => handleTabClick(i)}
              >
                {' '}
                {menu.label}{' '}
              </Typography>
            ))}
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={9}>
            <Box className={styles.activeComponentWrapper}>
              {activeMenu >= 0 ? menus[activeMenu].component : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Settings
