import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import styles from './index.module.scss'
interface MultiSelectDropdownProps {
  name: string
  label?: string
  id?: string
  value?: any
  options: {
    value: any
    label: string
  }[]
  placeholder?: string
  onChange?: any
  required?: boolean
  error?: boolean
  hidePlaceholder?: boolean
  disabled?: boolean
}

const MultiSelectDropdown = ({
  name,
  label,
  id,
  value,
  placeholder,
  onChange,
  required,
  error,
  options,
  hidePlaceholder = false,
  disabled = false,
}: MultiSelectDropdownProps) => {
  return (
    <FormControl variant='standard' fullWidth>
      {label && (
        <Typography variant='h4' component='p' mb={1}>
          <label htmlFor={id}>
            {label}
            {required && (
              <Typography variant='subtitle1' color='red' component='span'>
                &nbsp;*
              </Typography>
            )}
          </label>
        </Typography>
      )}

      <Select
        error={error}
        variant='outlined'
        fullWidth
        multiple
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty={hidePlaceholder === false}
        id={id}
        disabled={disabled}
        // required={required}
        className={!value ? styles.placeholderTextColor : ''}
      >
        {/* {hidePlaceholder === false && (
          <MenuItem
            value=''
            sx={{
              color: 'gray',
            }}
          >
            {placeholder}
          </MenuItem>
        )} */}

        {options.map((option, i) => {
          return (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default MultiSelectDropdown
