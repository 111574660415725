import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import SearchInput from 'src/Component/SearchInput'
import PaginatedTable, { TableHeadType } from 'src/Component/PaginatedTable'
import DataRow from './DataRow'
import { useFetchSubscribedUsersQuery } from 'src/api/admin/userManagement'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { startPageLoader, stopPageLoader } from 'src/store/features/common/commonSlice'
import EmptyTableRow from 'src/Component/EmptyTableRow'
import styles from './index.module.scss'

const tableHead: TableHeadType[] = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'email',
    label: 'Email Id',
  },
  {
    key: 'plan',
    label: 'Plan',
  },
]

const SubscribedUserList = () => {
  const [search, setSearch] = useState<string>('')
  const dispatch = useDispatch<AppDispatch>()

  const [page, setPage] = React.useState(1)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handlePageChange = (page: number) => {
    setPage(page + 1)
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    setRowsPerPage(perPage)
    setPage(1)
  }

  // const data = [
  //   {
  //     id: 1,
  //     name: 'suraj',
  //     age: 25,
  //   },
  //   {
  //     id: 2,
  //     name: 'shivam',
  //     age: 23,
  //   },
  //   {
  //     id: 1,
  //     name: 'suraj',
  //     age: 25,
  //   },
  //   {
  //     id: 2,
  //     name: 'shivam',
  //     age: 23,
  //   },
  // ]

  const {
    data: SUBSCRIBED_USERS_DATA_RESPONSE,
    isFetching,
    // isError,
  } = useFetchSubscribedUsersQuery(
    {
      pageNumber: page,
      pageSize: rowsPerPage,
      searchBy: search,
    },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (isFetching) {
      dispatch(startPageLoader())
    } else {
      dispatch(stopPageLoader())
    }
  }, [isFetching])

  return (
    <Box className="cardWrapperContainer">
      <Box className={`cardWrapper ${styles.subscriptionHeadCard}`}>
        <Typography variant='h1'> Subscribed Users </Typography>
        <Grid container>
          <Grid xs={12} sm={12} md={4} mt={1}>
            <SearchInput onChange={setSearch} hasDebounce />
          </Grid>
        </Grid>
      </Box>

      <Box mt={5}>
        {SUBSCRIBED_USERS_DATA_RESPONSE &&
        SUBSCRIBED_USERS_DATA_RESPONSE.data &&
        SUBSCRIBED_USERS_DATA_RESPONSE.page ? (
          <PaginatedTable
            currentPage={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            tableHead={tableHead}
            totalPages={SUBSCRIBED_USERS_DATA_RESPONSE.page.total_page}
            totalDataCount={SUBSCRIBED_USERS_DATA_RESPONSE.page.total_data_count}
          >
            <>
              {SUBSCRIBED_USERS_DATA_RESPONSE?.data?.map((row, i) => {
                return <DataRow key={i} data={row} />
              })}

              {SUBSCRIBED_USERS_DATA_RESPONSE.data.length === 0 && (
                <EmptyTableRow cellCount={tableHead.length} />
              )}
            </>
          </PaginatedTable>
        ) : null}
      </Box>
    </Box>
  )
}

export default SubscribedUserList
